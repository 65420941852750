/*
 * ScalarisLoader
 *
 * Loads scalaris files. It is trigger by loading a file with .scalaris extension or by specifying the default file type
 * in the options to loadModel() method.
 *
 * For detailed information on Scalaris Data Model (“neutral format for Simulation & Forge”), refer to the following links:
 * - https://wiki.autodesk.com/display/NFDC/Scalaris+for+Autodesk+Generative+Design
 * - https://wiki.autodesk.com/pages/viewpage.action?spaceKey=MPGART&title=Project+Scalaris
 * - https://pages.git.autodesk.com/dmg-nfdc/ScalarisDataModel/documentation.html
 * - https://git.autodesk.com/MPGART/Scalaris
 *
 * Authors: Parviz Rushenas (Parviz.Rushenas@autodesk.com) & Ania Lipka (Ania.Lipka@autodesk.com)
 */

  "use strict";

  import { logger } from "../../logger/Logger";
  import { FileLoaderManager } from "../../application/FileLoaderManager";
  import { InstanceTreeStorage, InstanceTreeAccess} from "../../wgs/scene/InstanceTreeStorage";
  import { InstanceTree } from "../../wgs/scene/InstanceTree";
  import { errorCodeString, ErrorCodes } from "../net/ErrorCodes";
  import { ProgressState } from "../../application/ProgressState";
  import * as THREE from "three";
  import { pathToURL } from "../net/Xhr";
  import { initWorkerScript, createWorkerWithIntercept } from "./WorkerCreator";
  import * as et from "../../application/EventTypes";
  import { initLoadContext } from "../net/endpoints";
  import { getResourceUrl } from "../../globals";
  import { Model } from "../../application/Model";

  var WORKER_LOAD_SCALARIS  = "LOAD_SCALARIS";
  var SCALARIS_PROTO_LOCATION =  "res/protobuf/scalaris.proto";
  var MODEL_UNITS = "meter";
  var ROOT_NODE_NAME = "RootNode";
  var GEOMETRY_NODE_NAME = "Geometry";

  var scalarisLoader = function (parent) {
      this.viewer3DImpl = parent;
      this.loading = false;
  };

  scalarisLoader.prototype.dtor = function () {
    this.viewer3DImpl = null;
    this.model  = null;
    this.svf    = null;
    this.logger = null;
    this.loading  = false;
  };

  scalarisLoader.prototype.isValid = function() {
    return this.viewer3DImpl != null;
  };

  scalarisLoader.prototype.loadFile = function(path, options, onDone, onWorkerStart) {
    if (!this.viewer3DImpl) {
      logger.log("Scalaris loader was already destructed. So no longer usable.");
      return false;
    }

    if (this.loading) {
      logger.log("Loading of Scalaris already in progress. Ignoring new request.");
      return false;
    }

    this.loading = true;
    this.viewer3DImpl._addLoadingFile(this);

    this.currentLoadPath = path;
    var basePath = "";
    var lastSlash = this.currentLoadPath.lastIndexOf("/");
    if (lastSlash != -1)
      basePath = this.currentLoadPath.substr(0, lastSlash+1);
    this.basePath = basePath;


    this.options = options;
    this.options.debug = {};
    this.options.preserveView = true; // to preserve the view set by setViewCube

    var scope = this;

    this.initWorkerScriptToken = initWorkerScript(function() {
      scope.loadScalarisCB(path, scope.options, onDone, onWorkerStart);
    });

    return true;
  };

  scalarisLoader.prototype.loadScalarisCB = function(path,  options, onDone, onWorkerStart) {
    var first = true;
    var scope = this;
    var w = this.svfWorker = createWorkerWithIntercept();
    var onScalarisLoad = function (ew) {
      var cleaner = function() {
        if (w) {
          w.clearAllEventListenerWithIntercept();
          w.terminate();
          scope.svfWorker = null;
          w = null;
        }
      };

      if (first && onWorkerStart) {
        first = false;
        onWorkerStart();
      }

      if (ew.data && ew.data.geometry) {
        // Decompression is done.
        var svf = scope.svf = ew.data.geometry;
        scope.onModelRootLoadDone(svf);

        if (onDone) {
          onDone(null, scope.model);
        }

        scope.viewer3DImpl.api.dispatchEvent({type:et.MODEL_ROOT_LOADED_EVENT, svf:svf, model:scope.model});
        scope.svf.loadDone = false;

      } else if (ew.data && ew.data.progress) {
        var impl = scope.viewer3DImpl;
        if (impl) {
          scope.viewer3DImpl.signalProgress(100 * ew.data.progress, ProgressState.LOADING);
        }

        // Delay onGeomLoadDone so that UI has time to build.
        if (ew.data.progress == 1) {
          setTimeout(function () {
            scope.onGeomLoadDone();
            scope.loading = false;
            cleaner();
          }, 0);
        }
      } else if (ew.data && ew.data.error) {
        scope.loading = false;
        console.error(ew.data);
        cleaner();
        if (onDone) {
          onDone(ew.data.error, null);
        }
      } else if (ew.data && ew.data.debug) {
        logger.debug(ew.data.message);
      } else {
        logger.error("Scalaris load failed.", errorCodeString(ErrorCodes.NETWORK_FAILURE));
        // Load failed.
        scope.loading = false;
        cleaner();
      }
    };

    w.addEventListenerWithIntercept(onScalarisLoad);

    var loadContext = {
      url: pathToURL(path),
      basePath: this.currentLoadPath,
      scalarisProtoPath: getResourceUrl(SCALARIS_PROTO_LOCATION)
    };

    loadContext.operation = WORKER_LOAD_SCALARIS;
    w.doOperation(initLoadContext(loadContext));

    return true;
  };

  scalarisLoader.prototype.onModelRootLoadDone = function(svf) {
    // Root model loading is done, and loader now is attached to model,
    // so can remove the direct reference to it from viewer impl.
    this.viewer3DImpl._removeLoadingFile(this);

    svf.basePath = this.basePath;
    svf.disableStreaming = true;
    svf.geomPolyCount = 0;
    svf.gpuNumMeshes = 0;
    svf.gpuMeshMemory = 0;

    svf.fragments = {
      length : svf.meshCount,
      numLoaded : 0,
      boxes : null,
      transforms : null,
      materials : null,

      fragId2dbId : null,
      entityIndexes : null,
      mesh2frag : null
    };

    svf.animations = null;
    svf.nodeToDbId = {};
    svf.loadOptions = this.options;

    svf.bbox = new THREE.Box3(svf.min, svf.max);

    // Create the API Model object and its render proxy
    var model = this.model = new Model(svf);
    if (svf.urn) {
      model.setUUID(svf.urn);
    }
    model.initialize();
    model.loader = this;
    model.isScalaris = true;

    this.viewer3DImpl.signalProgress(5, ProgressState.ROOT_LOADED);
    this.viewer3DImpl.invalidate(false, false);
  };

  scalarisLoader.prototype.onGeomLoadDone = function() {
    this.svf.loadDone = true;

    var fragLength = this.svf.fragments.length;
    this.svf.numGeoms = fragLength;

    this.svf.fragments.numLoaded = fragLength;
    this.svf.fragments.boxes = new Float32Array(fragLength*6);
    this.svf.fragments.transforms = new Float32Array(fragLength * 12);
    this.svf.fragments.materials = new Int32Array(fragLength);
    this.svf.fragments.fragId2dbId = new Int32Array(fragLength);
    this.svf.fragments.mesh2frag = new Int32Array(fragLength);

    var ensureChunk = function(geometry) {
      if (geometry.offsets.length === 0) {
        var chunkSize = 21845;
        var numTris = geometry.attributes.index.array.length / 3;
        var offsets = numTris / chunkSize;
        for (var i = 0; i < offsets; i++) {
          var offset = {
            start: i * chunkSize * 3,
            count: Math.min( numTris - ( i * chunkSize ), chunkSize ) * 3
          };
          geometry.addDrawCall(offset.start, offset.count);
        }
      }
    };

    var ensureNormals = function(geometry) {
      if (geometry.attributes.normal.array.length == 0) {
        geometry.attributes.normal.array = new Float32Array(geometry.attributes.position.array.length);
        geometry.computeVertexNormals();
      }
    };

    var commitGeometry = function(geometryData) {
      var geometry = new THREE.BufferGeometry();
      geometry.byteSize = 0;
      geometry.addAttribute('index', new THREE.BufferAttribute(new Uint32Array(geometryData.indices), 1));
      geometry.addAttribute('position', new THREE.BufferAttribute(new Float32Array(geometryData.vertices), 3));
      geometry.addAttribute('normal', new THREE.BufferAttribute(new Float32Array(geometryData.normals), 3));

      if (geometryData.hasOwnProperty('uvs') && geometryData.uvs.byteLength > 0) {
        geometry.byteSize += geometryData.uvs.byteLength;
        geometry.addAttribute('uv', new THREE.BufferAttribute(new Float32Array(geometryData.uvs), 2));
      }

      geometry.hasColors = false;
      if (geometryData.hasOwnProperty('colors') && geometryData.colors.byteLength > 0) {
        geometry.byteSize += geometryData.colors.byteLength;
        geometry.addAttribute('color', new THREE.BufferAttribute(new Float32Array(geometryData.colors), 3));
        geometry.hasColors = true;
        geometry.colorsNeedUpdate = true;
      }

      if (geometry.attributes.index.array.length > 0 && geometry.attributes.position.array.length > 0) {
        if (geometryData.hasOwnProperty('offsets') && geometryData.offsets.byteLength > 0) {
          geometry.byteSize += geometryData.offsets.byteLength;
          var length = geometryData.offsets.length;
          for (var i = 0; i < length; i++) {
            var offset = geometryData.offsets[i];
            geometry.addDrawCall(offset.start, offset.count);
          }
        }

        if (geometryData.min) {
          geometry.boundingBox = new THREE.Box3(new THREE.Vector3(geometryData.min.x, geometryData.min.y, geometryData.min.z),
            new THREE.Vector3(geometryData.max.x, geometryData.max.y, geometryData.max.z));
        } else {
          geometry.computeBoundingBox();
        }
        geometryData.bbox.min = geometryData.min = geometry.boundingBox.min;
        geometryData.bbox.max = geometryData.max = geometry.boundingBox.max;

        ensureChunk(geometry);
        ensureNormals(geometry);

        geometry.byteSize += geometryData.indices.byteLength + geometryData.vertices.byteLength + geometryData.normals.byteLength;
        geometry.polyCount = geometry.attributes.index.array.length / 3;

        return geometry;
      }

      return null;
    };

    var svf = this.svf;

    // Get the THREE.BufferGeometry.
    var geometry = commitGeometry(svf);

    svf.geomPolyCount += geometry.polyCount;

    var meshId = 0;
    var fragId = 0;
    var matId  = null; // no material info for now.

    this.createInstanceTree(fragId);

    var dbId = 2;     // The id of the geometry node in the Instance tree (for this fragment).

    this.model.getGeometryList().addGeometry(geometry, 1 /*numOfInstances*/, meshId);

    var matrix = new THREE.Matrix4();
    var mesh = this.viewer3DImpl.setupMesh(this.model, geometry, matId, matrix);
    mesh.material = this.viewer3DImpl.getMaterials().defaultMaterial;

    svf.fragments.materials[fragId] = matId;
    svf.fragments.fragId2dbId[fragId] = dbId;
    svf.fragments.mesh2frag[meshId] = fragId;

    var bbox = svf.fragments.boxes;
    bbox[0] = svf.min.x;
    bbox[1] = svf.min.y;
    bbox[2] = svf.min.z;
    bbox[3] = svf.max.x;
    bbox[4] = svf.max.y;
    bbox[5] = svf.max.z;

    var trans = svf.fragments.transforms;
    for (var i = 0; i < 12; i++)
      trans[i] = 0;
    trans[0] = trans[4] = trans[8] = 1;

    this.model.activateFragment(fragId, mesh, !!svf.placementTransform);

    this.currentLoadPath = null;

    this.viewer3DImpl.api.setModelUnits(MODEL_UNITS);

    if (geometry.hasColors && svf.colors.byteLength) {
      // Turn off the display of simulation data.
      var defMaterial = this.viewer3DImpl.getMaterials().defaultMaterial;
      defMaterial.vertexColors = THREE.NoColors;
      defMaterial.needsUpdate = true;
      this.viewer3DImpl.invalidate(true, true, false); // trigger re-render
    }

    if (!this.options.skipFitToView) {
      this.viewer3DImpl.api.fitToView(null, null, true);
    }

    this.viewer3DImpl.onLoadComplete(this.model);
  };

  scalarisLoader.prototype.createInstanceTree = function(fragId) {
    var storage = new InstanceTreeStorage(2, 1);

    var rootDbId = 1; // dbId are 1-based.
    var geomDbId = 2;
    var rootChildrenDbIds = [geomDbId];
    var geomChildrenDbIds = [];

    storage.setNode(rootDbId, 0, ROOT_NODE_NAME, 0, rootChildrenDbIds, []);
    storage.setNode(geomDbId, rootDbId, GEOMETRY_NODE_NAME, 0, geomChildrenDbIds, [fragId]);

    var nodeAccess = new InstanceTreeAccess(storage, 0);
    nodeAccess.computeBoxes(this.svf.fragments.boxes);

    this.svf.instanceTree = new InstanceTree(nodeAccess, 1, 1);
  };

  scalarisLoader.prototype.is3d = function() {
    return true;
  };

  FileLoaderManager.registerFileLoader("scalaris", ["scalaris"], scalarisLoader);

  export let ScalarisLoader = scalarisLoader;
