
import { isMobileDevice } from "../../compat";
import { PIXEL_CULLING_THRESHOLD, PAGEOUT_SUCCESS, GEOMETRY_OVERHEAD } from "../../wgs/globals";

var WORKER_PARSE_F2D_FRAME = "PARSE_F2D_FRAME";

var RENDER_BUFFER_REQUEST = 0x1;
var PROMISE_BUFFER_REQUEST = 0x2;
var ANY_BUFFER_REQUEST = 0x4;
var BUFFER_SENT_TO_PARSER = 0x8;

var MEGA = 1024 * 1024;
var VERT_SIZE = 12 * 4; // 12 floats per vertex
var INDEX_SIZE = 2;

// Paging proxy object to manage on demand loading and paging logic,
// that is specific to the model loaded by svf loader.
export var F2DPagingProxy = function(loader, options) {

    var _extendObject = function(target, source) {
        for (var prop in source) {
            if (source.hasOwnProperty(prop)) {
                target[prop] = source[prop];
            }
        }
    };

    var _loader = loader;

    // Options of control memory management.
    // Options of control memory management.
    this.options = {
        onDemandLoading: false,
        pageOutGeometryEnabled: false
    };
    _extendObject(this.options, options);
    this.options.debug = {

        pageOutStart: .75,
        pageOutEnd: .50,
        pixelCullingEnable: false,      // Not useful for 2D
        pixelCullingThreshold: PIXEL_CULLING_THRESHOLD
    };
    _extendObject(this.options.debug, options.debug);

    // Initialize members used by on demand loading
    var _pendingBuffers = [];
    var _pendingPromises = {};
    var _promiseQueue = [];
    var _pendingSize = 0;
    var _actualGeomSize = 0;
    var _lastBufferPending = -1;
    var _lastTraversed = -1;
    var _maxRequest = -1;
    var _canceled = 0;
    var _alternatePromise = true;
    var _bufferCount = 0;
    // Max size of a 2D buffer.
    var _maxBufferSize;
    if (_loader.useInstancing) {
        // If we are instancing, then everything is output as a quad, even triangles.
        // So we are limited by the max size the VertexBufferBuilder will allow.
        // When we are instancing the VertexBufferBuilder cuts the buffer size by 1/4.
        _maxBufferSize = ((isMobileDevice() ? 16383 : 32767) / 4) | 0;
        // Don't use indices with instancing
        _maxBufferSize = _maxBufferSize * VERT_SIZE / MEGA;
    } else {
        // We can have polytris with 65535 verts Can only guess at the max index size,
        // since we don't get a stat for that so we guess an average of 6 indices per vert.
        _maxBufferSize = 65535 * (VERT_SIZE + INDEX_SIZE * 6) / MEGA;
    }
    var _finalFrame = false;
    var _culledGeom = [];

    // Need at least 10 MB to work
    this.options.limit = Math.max(this.options.limit, 10);
    this.options.debug.pageOutEnd = Math.min(this.options.debug.pageOutEnd, this.options.debug.pageOutStart);

    this.totalGeomSize = loader.totalGeomSize;

    // Viewer API - these methods are used by the viewer to handler
    // on demand loading and paging out geometry

    // Return true of false, whether on demand loading enabled.
    // This controls how the geometry buffers are going to load.
    //
    // If false, then geometry buffers will load in sequence all at once.
    // if true, then only those geometry buffers that are request to render,
    //          can they start to load *on demand*
    this.onDemandLoadingEnabled = function() {
        return this.options.onDemandLoading;
    };

    this.pageOutGeometryEnabled = function() {
        return this.options.onDemandLoading;
    };

    this.pixelCullingEnable = function() {
        return this.options.debug.pixelCullingEnable;
    };

    this.pixelCullingThreshold = function() {
        return this.options.debug.pixelCullingThreshold;
    };

    /**
     * Get the memory stats when using on demand loading.
     * @returns {object|null} Object containing the limit and loaded memory usage for the model.
     *                        Return null if the model isn't being loaded on demand.
     */
    this.getMemoryInfo = function() {
        var geomSizeInMemory = 0;
        if (_loader.model) {
            var geoms = _loader.model.getGeometryList();
            if (geoms) {
                geomSizeInMemory = geoms.geomMemory / MEGA;
            }
        }

        return this.onDemandLoadingEnabled() ? {
            limit: this.options.limit,
            effectiveLimit: this.options.limit,
            loaded: geomSizeInMemory + _loader.fileMemorySize
        } : null;
    };

    function nextRenderRequest(nextReq) {
        while (++nextReq < _pendingBuffers.length) {
            // Continue until we find a buffer that is requested and not sent to the parser
            if ((_pendingBuffers[nextReq] & (ANY_BUFFER_REQUEST | BUFFER_SENT_TO_PARSER)) == ANY_BUFFER_REQUEST)
                break;
        }
        return nextReq;
    }

    function scheduleMoreBuffers(options) {
        // request more buffers to draw.
        var geomSizeInMemory = 0;
        if (_loader.model) {
            var geoms = _loader.model.getGeometryList();
            if (geoms) {
                geomSizeInMemory = geoms.geomMemory / MEGA;
            }
        }

        // Schedule the geometry download. Alternate between promised buffers
        // and render buffers
        var nextReq = nextRenderRequest(_lastBufferPending);
        var currentMemSize = geomSizeInMemory + _loader.fileMemorySize + _maxBufferSize;
        while (currentMemSize + _pendingSize < options.limit) {
            // Find the next buffer to request. Alternate between promised buffers
            // and render buffer based on the last one requested.
            var bufferId = -1;
            var promise = null;
            if (_promiseQueue.length > 0 && (_alternatePromise || nextReq > _pendingBuffers.length)) {
                // Get the promised buffer id and mark it as pending
                promise = _promiseQueue.shift();
                bufferId = promise.lmv_buffer_id;
                // Next time request a render buffer
                _alternatePromise == false;
            } else if (nextReq < _pendingBuffers.length) {
                // request a render buffer
                bufferId = nextReq;
                _lastBufferPending = nextReq;
                nextReq = nextRenderRequest(nextReq);
                _alternatePromise = true;
            } else
                break;      // no more buffers to request

            // Request the next buffer. If this buffer was already sent to the
            // parser, then we make this request conditional.
            _loader.parsingWorker.doOperation({operation:WORKER_PARSE_F2D_FRAME,
                                               bufferId: bufferId,
                                               rendered: !!(_pendingBuffers[bufferId] & RENDER_BUFFER_REQUEST),
                                               promised: !!(_pendingBuffers[bufferId] & PROMISE_BUFFER_REQUEST),
                                               conditional: !!(_pendingBuffers[bufferId] & BUFFER_SENT_TO_PARSER) });
            if (!(_pendingBuffers[bufferId] & BUFFER_SENT_TO_PARSER)) {
                _pendingSize += _maxBufferSize;
                _pendingBuffers[bufferId] |= BUFFER_SENT_TO_PARSER;
            }
        }
    }

    this.loadPackFile = function(bufferId) {
        if (!this.onDemandLoadingEnabled())
            return false;

        // Request out of range
        if (bufferId < 0 || (_finalFrame && bufferId >= _bufferCount))
            return false;

        if (bufferId > _maxRequest)
            _maxRequest = bufferId;

        // If this buffer hasn't been requested for rendering, then check for
        // out of sequence ordering and mark it as requested.
        if (!(_pendingBuffers[bufferId] & RENDER_BUFFER_REQUEST)) {
            // reset if we get a request out of order
            if (bufferId <= _lastBufferPending)
                this.reset();
            // Mark as pending
            _pendingBuffers[bufferId] |= ANY_BUFFER_REQUEST | RENDER_BUFFER_REQUEST;
        }

        scheduleMoreBuffers(this.options);
        return true;
    };

    this.promisePackFile = function(bufferId) {
        if (!this.onDemandLoadingEnabled())
            return Promise.reject( { reason: "Not supported" } );

        // Request out of range
        if (bufferId < 0 || bufferId >= _bufferCount)
            return Promise.reject( { reason: "Buffer id out of bounds" } );

        // If this buffer hasn't been requested, then check for
        // out of sequence ordering and mark it as requested.
        var promise = _pendingPromises[bufferId];
        if (promise) {
            // We already have this buffer queue. We only keep one promise for
            // each buffer, so just return the one we already have
            scheduleMoreBuffers(this.options);
            return promise;
        }

        // Add to the pending promises. We do this carefully without making
        // any assumptions about when the argument to the Promise constructor,
        // might get called or when the buffer might be loaded.
        var lmv_resolve;
        var lmv_reject;
        // Create the promise, we keep the promise and the resolve and
        // reject functions in a state object, so we can separate the
        // download scheduler from the promise
        promise = new Promise( function(resolve, reject) {
            lmv_resolve = resolve;
            lmv_reject = reject;
        } );

        // Add some properties to the promise, so we can download
        // the buffer, and resolve or reject the promise.
        promise.lmv_resolve = lmv_resolve;
        promise.lmv_reject = lmv_reject;
        promise.lmv_buffer_id = bufferId;

        // Put the promise in the pending promises list, and the
        // pending buffers. The pending promises list allows promised
        // geometry to run out of order.
        _promiseQueue.push( promise );
        _pendingPromises[bufferId] = promise;
        _pendingBuffers[bufferId] |= ANY_BUFFER_REQUEST | PROMISE_BUFFER_REQUEST;

        // schedule more buffers, if we can
        scheduleMoreBuffers(this.options);
        return promise;
    };

    this.cancelPromisedPackFile = function(promise) {
        // Not one of mine.
        if (!promise || !promise.hasOwnProperty("lmv_buffer_id")
            || _pendingPromises[promise.lmv_buffer_id] != promise)
            return false;

        // Remove the promise, and reject it.
        var index = _promiseQueue.indexOf(promise);
        if (index >= 0)
            _promiseQueue.splice(index, 1);
        // Cancel it in the parser, if it has been requested
        var flags = _pendingBuffers[promise.lmv_buffer_id];
        if (flags & BUFFER_SENT_TO_PARSER) {
            _loader.parsingWorker.doOperation({operation:WORKER_PARSE_F2D_FRAME,
                                               cancelPromise: promise.lmv_buffer_id });
            if (!(flags & RENDER_BUFFER_REQUEST))
                _pendingSize -= _maxBufferSize;
        }
        if (flags & RENDER_BUFFER_REQUEST)
            flags &= ~PROMISE_BUFFER_REQUEST;
        else
            flags = 0;
        _pendingBuffers[promise.lmv_buffer_id] = flags;
        delete _pendingPromises[promise.lmv_buffer_id];
        if (promise.lmv_reject)
            promise.lmv_reject( { canceled: true } );

        // Schedule more buffers if we can.
        scheduleMoreBuffers(this.options);
        return true;
    };

    this.resetIterator = function(/*camera, resetType*/) {
    };

    this.reset = function() {
        // we need to cancel the parsing worker.
        _loader.parsingWorker.doOperation({operation:WORKER_PARSE_F2D_FRAME,
                                           cancel: true });
        _pendingSize = 0;
        for (var i = 0; i < _pendingBuffers.length; ++i) {
            // Clear render request if buffer is promised.
            // Otherwise we can clear everything.
            if (_pendingBuffers[i] & PROMISE_BUFFER_REQUEST) {
                _pendingBuffers[i] &= ~RENDER_BUFFER_REQUEST;
                if (_pendingBuffers[i] & BUFFER_SENT_TO_PARSER)
                    _pendingSize += _maxBufferSize;
            } else
                _pendingBuffers[i] = 0;
        }
        this.lastPageOut = -1;
        _lastBufferPending = -1;
        _lastTraversed = -1;
        _culledGeom.length = 0;
        _maxRequest = _bufferCount - 1;
        ++_canceled;
    };

    this.addGeomPackMissingLastFrame = function(/*packId*/) {
        return true;// Shouldn't be called
    };

    this.needResumeNextFrame = function() {
        return false;
    };

    this.pageOut = function(iterationDone, forcePageOut) {
        var geomList = _loader.model.getGeometryList();
        // If over the limit, start page out
        var size = geomList.geomMemory / MEGA + _loader.fileMemorySize;
        // Make sure we page out enough to load a new buffer
        var pageOutStart = Math.min(this.options.debug.pageOutStart * this.options.limit,
            this.options.limit - 1.1 * _maxBufferSize);
        var pageOutEnd = Math.min(this.options.debug.pageOutEnd * this.options.limit, pageOutStart);
        if (size > pageOutStart) {
            var i = 0; // tmp (see below)

            // Goal is to page out to limit - limit * percent
            var remaining = size - pageOutEnd;

            // Step 1: Remove untraversed geometries first
            while (i < _culledGeom.length && remaining > 0) {
                // remove culled geom
                remaining -= geomList.removeGeometry(_culledGeom[i++], _loader.viewer3DImpl.glrenderer()) / MEGA;
            }
            _culledGeom.splice(0, i);

            // Step 2: If not enough, continue to remove geometries aren't about to be traversed.
            for (i = geomList.geoms.length; --i > 0 && remaining > 0; ) {
                if (i <= _lastTraversed || i > _lastBufferPending)
                    remaining -= geomList.removeGeometry(i, _loader.viewer3DImpl.glrenderer()) / MEGA;
            }

            // Step 3: If existing geometries are still over the limitation, and force page out enabled,
            //         run through the whole list and page out as much as needed.
            if (forcePageOut) {
                remaining = geomList.geomMemory / MEGA  - pageOutStart;
                for (i = _lastBufferPending; i > 0 && remaining > 0; --i) {
                    remaining -= geomList.removeGeometry(i, _loader.viewer3DImpl.glrenderer()) / MEGA;
                }
                //THREE.log("[On Demand Loading] A force page out occur. ");
            }
            this.lastPageOut = size - geomList.geomMemory / MEGA;

            // When starting chrome with this JS flag: --js-flags="--expose-gc", then window.gc is defined and
            // can be used for doing a force GC. This is useful for testing purpose.
            if (window && window.gc) {
                window.gc();
            }
        }

        // If the iterator is finished and the file is still loading then
        // we request more buffers. This how buffers initially get loaded
        var newSize = geomList.geomMemory / MEGA;
        if (iterationDone && !_finalFrame) {
            var bufferSize = this.options.limit - newSize;
            if (bufferSize > 0) {
                var bufferId = _lastBufferPending;
                while ((bufferSize -= _maxBufferSize) >= 0)
                    this.loadPackFile(++bufferId);
            }
        }

        return PAGEOUT_SUCCESS;
    };

    this.onGeomTraversed = function(geometry) {
        // TODO Paging: refactor this to the proxy object of 2d loader.
        //              2d doesn't have multiple instance geometry, so
        //              just record it as traversed.
        _lastTraversed = geometry.svfid - 1;
    };

    this.onGeomCulled = function(geometry) {
        // TODO Paging: refactor this to the proxy object of 2d loader.
        if (this.onDemandLoadingEnabled() && geometry) {
            _culledGeom.push(geometry.svfid - 1);
        }
    };

    this.onMeshReceived = function(mesh, mindex) {
        // Accept all meshes if not on demand loading
        if (!this.onDemandLoadingEnabled())
            return true;

        // If this buffer was promised, then signal that it is loaded
        // We will do this even if we are canceling render requests.
        var promise = _pendingPromises[mindex];
        if (promise) {
            // Signal that we loaded the buffer
            if (promise.lmv_resolve)
                promise.lmv_resolve();
            // delete the requests from the pending promises and the promise queue
            delete _pendingPromises[mindex];
            var index = _promiseQueue.indexOf(promise);
            if (index >= 0)
                _promiseQueue.splice(index, 1);
        }

        // If on demand loading don't process any meshes that aren't requested
        // Buffers can get queued after canceling all of the meshes, but promises aren't canceled
        if (!(_pendingBuffers[mindex] & BUFFER_SENT_TO_PARSER)
            || (_canceled != 0 && !(_pendingBuffers[mindex] & PROMISE_BUFFER_REQUEST))) {
            return false;
        }

        // Keep track of the buffer count
        if (mindex >= _bufferCount) {
            _bufferCount = mindex + 1;
            _actualGeomSize += mesh.vb.byteLength + mesh.indices.byteLength + GEOMETRY_OVERHEAD;
        }

        // If on demand loading then we need to invalidate for each render mesh
        // is loaded to insure that the visibility flags are correctly updated.
        if (_pendingBuffers[mindex] & RENDER_BUFFER_REQUEST)
            _loader.viewer3DImpl.invalidate(false, true);

        // Keep track of pending buffers
        _pendingBuffers[mindex] = 0;
        _pendingSize -= _maxBufferSize;
        return true;
    };

    this.onFinalFrame = function() {
        // When we are done, reset
        if (this.onDemandLoadingEnabled()) {
            if (!_finalFrame)
                this.totalGeomSize = _actualGeomSize / MEGA + _loader.fileMemorySize;
            _finalFrame = true;
            _pendingBuffers.splice(_bufferCount, _pendingBuffers.length).forEach( function(pending) {
                if (pending & BUFFER_SENT_TO_PARSER)
                    _pendingSize -= _maxBufferSize;
            } );
        }
    };

    this.preparedPackFilesSize = function() {
        return _loader.fileMemorySize + _pendingSize
            + _loader.model.getGeometryList().geomMemory / MEGA;
    };

    this.cancelAcknowledged = function() {
        --_canceled;
    };
};
