
import { logger } from "../logger/Logger";

var _supported = isLocalStorageSupported();

/**
 * Helper function that detects whether localStorage is available.
 * @private
 */
function isLocalStorageSupported() {
    if (typeof window === "undefined")
        return false;

    try {
        var TEST_KEY = 'lmv_viewer_test_localStorage';
        var storage = window.localStorage; // This may assert if browsers disallow sites from setting data.
        if (!storage)
            return false;

        storage.setItem(TEST_KEY, '1');
        storage.removeItem(TEST_KEY);
        return true;

    } catch (error) {
        return false;
    }
}

function LocalStorageClass() {
    // nothing //
}

/**
 * Get an item from localStorage.
 * Returns null localStorage is not available.
 */
LocalStorageClass.prototype.getItem = function(key) {
    if (!_supported) return null;
    return window.localStorage.getItem(key);
};

/**
 * Set an item into localStorage.
 * Does nothing if localStorage is not available OR if
 * the max quota is exceeded.
 */
LocalStorageClass.prototype.setItem = function(key, value) {
    if (!_supported) return;
    try {
        window.localStorage.setItem(key, value);
    } catch (eee) {
        logger.debug('avp.LocalStorage: Failed to setItem()');
    }
};

/**
 * Removes an item from localStorage.
 * Does nothing if localStorage is not available.
 */
LocalStorageClass.prototype.removeItem = function(key) {
    if (!_supported) return;
    window.localStorage.removeItem(key);
};

/**
 * Returns true is localStorage is supported.
 */
LocalStorageClass.prototype.isSupported = function() {
    return _supported;
};

/**
 * Global instance for interacting with localStorage.
 * @private
 */
export const LocalStorage = new LocalStorageClass();

