
'use strict';

/**
 * Basic manager for a list of functions called to render additional content
 */ 
export function RenderCallbacks()
{
    this.entries = {};

    /** 
     * Register a function to call for rendering
     * @param {string} name - Name of this callback
     * @param {Function} callback - Function called when rendering
     */
    this.add = function(name, callback) {
        this.entries[name] = { "callback": callback };
    };

    /** 
     * Remove a previously registered callback by name
     * @param {string} name - Name of the callback to remove
     */
    this.remove = function(name) {
        var entry = this.entries[name];
        if (entry) {
            delete this.entries[name];
        }
    };

    /** 
     * Run all of the registered render callbacks
     */
    this.render = function() {
        for (var name in this.entries) {
            this.entries[name].callback();
        }
    }
}

