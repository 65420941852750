import * as globals from "../../wgs/globals";
import { isIOSDevice, isNodeJS, isMobileDevice, isAndroidDevice } from "../../compat";
import { logger } from "../../logger/Logger";
import { SvfPagingProxy } from "./SvfPagingProxy";
import { LmvMatrix4 } from "../../wgs/scene/LmvMatrix4";
import {ErrorCodes, errorCodeString} from "../net/ErrorCodes";
import { BVHBuilder, NodeArray } from "../../wgs/scene/BVHBuilder";
import { MeshFlags } from '../../wgs/scene/MeshFlags';
import { BufferGeometryUtils } from "../../wgs/scene/BufferGeometry";
import { DbidFragmentMap } from "../../wgs/scene/DbidFragmentMap";
import { MaterialConverter } from "../../wgs/render/MaterialConverter";
import { pathToURL } from "../net/Xhr";
import { PropDbLoader } from "./PropDbLoader";
import { TextureLoader } from "./TextureLoader";
import { initWorkerScript, createWorkerWithIntercept, createWorker } from "./WorkerCreator";
import * as et from "../../application/EventTypes";
import { Model } from "../../application/Model";
import * as THREE from "three";

import {FileLoaderManager} from "../../application/FileLoaderManager";
import {initLoadContext} from "../net/endpoints";

var av = Autodesk.Viewing;

var NUM_WORKER_THREADS = isNodeJS() ? 10 : (isMobileDevice() ? 2 : 6);
var WORKER_LOAD_GEOMETRY = "LOAD_GEOMETRY";
var WORKER_LOAD_SVF = "LOAD_SVF";
var WORKER_LOAD_SVF_CONTD = "LOAD_SVF_CONTD";
var WORKER_FETCH_TOPOLOGY = "FETCH_TOPOLOGY";
var WORKER_CALCULATE_LOAD_ORDER = "CALCULATE_LOAD_ORDER";
// This limit in MB is how much memory needs to be available
// to load textures. The texture loader tries to squeeze
// all textures into 32MB, so this limit should be larger than that.
var MOBILE_TEXTURE_LIMIT = 50;

var GEOM_PACK_SIZE_FACTOR = 0.7;    // Approximate ratio of geometry size to uncompressed pack file size

var MEGA = 1024 * 1024;

// Overhead constants. The total overhead is calculated as
// <#meshes> * MESH_OVERHEAD + <#fragments> * FRAG_OVERHEAD
var MESH_OVERHEAD = 224;        // Fixed overhead per mesh
var FRAG_OVERHEAD = 134;        // Fixed overhead per fragment

var _meshInfo = {};
var _fragLoadedEvent = {
    type: et.FRAGMENTS_LOADED_EVENT,
    model: null,
    data: null,
    getFragIds: function getFragIds() {
        if (this.fragIds)
            return this.fragIds;

        var rm = this.model;
        if (!rm || !this.data || !this.data.meshes)
            return null;

        var fragIds = this.fragIds = [];
        var packId = this.data.packId;
        var meshIndex = 0;

        var svf = rm.getData();
        var fragments = svf.fragments;
        var meshesLength = this.data.meshes.length;

        for (meshIndex = 0; meshIndex < meshesLength; ++meshIndex) {
            //Find all fragments that instance this mesh
            var meshid = packId + ":" + meshIndex++;

            var fragIndexes = fragments.mesh2frag[meshid];
            if (fragIndexes === undefined)
                return;

            if (Array.isArray(fragIndexes)) {
                fragIndexes.forEach(function(fragId) {
                    fragIds.push(fragId);
                });
            } else
                fragIds.push(fragIndexes);
        }

        return fragIds;
    }
};

var fireFragmentsLoadedEvent = (loader, data) => {
    _fragLoadedEvent.model = loader.model;
    _fragLoadedEvent.data = data;
    loader.viewer3DImpl.dispatchEvent(_fragLoadedEvent);
}


/** @constructor */
export var SvfLoader = function (parent, config) {
    this.viewer3DImpl = parent;
    this.next_pack = 0;
    this.loading = false;
    this.loadedPacksCount = 0;
    this.tmpMatrix = new THREE.Matrix4();
    this.tmpBox = new THREE.Box3();
    this.fetchingTopology = false;
    this.loadTime = 0;
    this.notifiesFirstPixel = true;

    var memoryOpts = config && config.memory;
    var options = this.memoryOpts = {
        debug: {}
    };
    if (!isNodeJS()) {
        // Default on demand loading if not node.js
        options.limit = isAndroidDevice() ? 195 : (isIOSDevice() ? 150 : 600);
        options.onDemandLoading = true;
        options.pageOutGeometryEnabled = true;

        // Turn on demand loading off if we get a memory configuration without a limit
        // or the limit is 0.
        if (memoryOpts) {
            options.debug = memoryOpts.debug || {};
            var newLimit = memoryOpts.hasOwnProperty("limit") ? memoryOpts.limit : 0;
            if ((newLimit | 0) > 0) {
                options.limit = newLimit | 0;
            } else if (newLimit == 0) {
                options.onDemandLoading = false;
                options.pageOutGeometryEnabled = false;
                options.debug.force = false;
            } else {
                logger.warn("Memory limit, " + newLimit + ", is invalid - ignored");
            }
        }
    }
};

SvfLoader.prototype.dtor = function () {

    // Cancel all potential process on loading a file.

    // 1. init worker script can be cancelled.
    //
    if (this.initWorkerScriptToken) {
        this.initWorkerScriptToken.cancel();
        this.initWorkerScriptToken = null;
        logger.debug("SVF loader dtor: on init worker script.");
    }

    // 2. load model root (aka. svf) can be cancelled.
    //
    if (this.svfWorker) {
        this.svfWorker.clearAllEventListenerWithIntercept();
        this.svfWorker.terminate();
        this.svfWorker = null;
        logger.debug("SVF loader dtor: on svf worker.");
    }

    if (this.svfLoadOrderWorker) {
        this.svfLoadOrderWorker.terminate();
        this.svfLoadOrderWorker = null;
        logger.debug("SVF loader dtor: on load order worker.");
    }

    // 3. load geometry pack files can be cancelled.
    //
    if (this.pack_workers) {
        for (var i=0; i<this.pack_workers.length; i++) {
            this.pack_workers[i].clearAllEventListenerWithIntercept();
            this.pack_workers[i].terminate();
        }
        this.pack_workers = null;
        logger.debug("SVF loader dtor: on geom worker.");
    }

    // 4. load property can be cancelled.
    //
    if (this.svf && this.svf.propDbLoader) {
        this.svf.propDbLoader.dtor();
        this.svf.propDbLoader = null;
    }

    if (this.pagingProxy) {
        this.pagingProxy.dtor();
        this.pagingProxy = null;
    }

    // and clear metadata.
    this.tmpMatrix = null;
    this.tmpBox = null;

    this.next_pack = 0;
    this.loading = false;
    this.loadedPacksCount = 0;
    this.loadTime = 0;
    this.viewer3DImpl = null;
};

SvfLoader.prototype.isValid = function() {
    return this.viewer3DImpl;
};


SvfLoader.prototype.loadFile = function(path, options, onDone, onWorkerStart) {

    if (!this.viewer3DImpl) {
        logger.log("SVF loader was already destructed. So no longer usable.");
        return false;
    }

    if (this.loading) {
        logger.log("Loading of SVF already in progress. Ignoring new request.");
        return false;
    }

    // Need to re-update on-demand loading settings based on options
    // specific for the file being loaded.
    if (!isNodeJS() && options.forceDisableOnDemandLoading) {
        this.memoryOpts.limit = 0;
        this.memoryOpts.onDemandLoading = false;
        this.memoryOpts.pageOutGeometryEnabled = true;
        this.memoryOpts.debug.force = false;
        logger.log('Animation file disables on-demand-loading...');
    }


    // Mark it as loading now.
    this.loading = true;
    this.viewer3DImpl._addLoadingFile(this);

    var index = path.indexOf('urn:');
    if (index != -1) {
        // Extract urn:adsk.viewing:foo.bar.whateverjunks out of the path URL and bind it to logger.
        // From now on, we can send logs to viewing service, and logs are grouped by urn to make Splunk work.
        path = decodeURIComponent(path);
        var urn = path.substr(index, path.substr(index).indexOf('/'));
        logger.log("Extracted URN: " + urn);

        // Extract urn(just base64 code)
        var _index = urn.lastIndexOf(':');
        this.svfUrn = urn.substr(_index + 1);
    } else {
        this.svfUrn = path;
    }

    this.sharedDbPath = options.sharedPropertyDbPath;

    this.currentLoadPath = path;
    var lastSlash = this.currentLoadPath.lastIndexOf("/");
    if (lastSlash != -1)
        this.basePath = this.currentLoadPath.substr(0, lastSlash+1);

    this.acmSessionId = options.acmSessionId;

    this.queryParams = "";
    if (this.acmSessionId) {
        this.queryParams = "acmsession=" + this.acmSessionId;
    }

    this.options = options;

    var scope = this;
    this.initWorkerScriptToken = initWorkerScript(function() {
        scope.loadSvfCB(path, options, onDone, onWorkerStart);
    });

    return true;
};


SvfLoader.prototype.loadSvfCB = function(path, options, onDone, onWorkerStart) {

    this.t0 = new Date().getTime();
    this.firstPixelTimestamp = null;
    this.failedToLoadSomeGeometryPacks = null;
    this.failedToLoadPacksCount = 0;
    var first = true;

    if (options.doneWhenDownloaded) {
        this.onDone = onDone;
    }

    var scope = this;
    var msg = {
        url: pathToURL(path),
        basePath: this.currentLoadPath,
        objectIds : options.ids,
        globalOffset : options.globalOffset,
        fragmentTransformsDouble: options.fragmentTransformsDouble,
        placementTransform : options.placementTransform,
        applyRefPoint: options.applyRefPoint,
        queryParams : this.queryParams,
        bvhOptions : options.bvhOptions || {isWeakDevice : isMobileDevice()},
        applyScaling: options.applyScaling,
        loadInstanceTree: options.loadInstanceTree,
        max_pf_files: 0
    };

    var optLoad = this.memoryOpts.onDemandLoading || !!this.memoryOpts.debug.memoryOptimizedSvfLoading;
    if (optLoad) {
        msg.perfOpt = {
            memoryOptimizedSvfLoading: optLoad,
            forceMemoryOptimizedMode: !!this.memoryOpts.debug.forceMemoryOptimizedModeOnSvfLoading
        };
    }

    this.viewer3DImpl._signalNoMeshes();

    var w = this.svfWorker = createWorkerWithIntercept();

    var onSVFLoad = function (ew) {
        var cleaner = function() {
            if (w) {
                w.clearAllEventListenerWithIntercept();
                w.terminate();
                scope.svfWorker = null;
                w = null;
            }
        };

        if (first && onWorkerStart) {
            first = false;
            onWorkerStart();
        }

        if (ew.data && ew.data.manifest) {

            SvfLoader.interceptManifest.call(scope, ew.data.manifest);
            msg.operation = WORKER_LOAD_SVF_CONTD;
            msg.manifest = ew.data.manifest;
            w.doOperation(msg);
        } else if (ew.data && ew.data.svf) {
            //Decompression is done.
            var svf = scope.svf = ew.data.svf;

            if (scope.failedToLoadSomeGeometryPacks) {
                // Report a warning. It is not a fatal error.
                if (onDone) {
                    onDone(scope.failedToLoadSomeGeometryPacks, null);
                    delete scope.onDone;
                }
                scope.failedToLoadSomeGeometryPacks = null;
            }

            scope.onModelRootLoadDone(svf);

            if (onDone && !options.doneWhenDownloaded) {
                onDone(null, scope.model);
            }

            scope.viewer3DImpl.dispatchEvent({type:et.MODEL_ROOT_LOADED_EVENT, svf:svf, model:scope.model});
            scope.svf.loadDone = false;

            var isGltf = false;
            if (scope.svf.metadata && scope.svf.metadata.gltf) {
                isGltf = true;
            }

            if (!isGltf) {
                var numGeomPacks = svf.geompacks.length;

                if (numGeomPacks == 0) {
                    scope.onGeomLoadDone();
                }
                else {

                    if (scope.model.getFragmentList().onDemandLoadingEnabled()) {
                        // On demand loading is enabled, then
                        // Defer to launch jobs for loading some geometry packs,
                        // until the viewer really need them.
                        logger.debug("[On Demand Loading]: Enabled.");
                        scope.loadedPacksCount = 0;
                    }
                    else {
                        // On demand loading is disabled, then
                        // Require loading immediately
                        if (numGeomPacks) {
                            var count = Math.min(numGeomPacks, av.NUM_WORKER_THREADS || NUM_WORKER_THREADS);
                            for (var i=0; i<count; i++) {
                                var pf = svf.geompacks[scope.next_pack++];
                                pf.loading = true;
                                if (isNodeJS()) {
                                    scope.loadGeometryPack(pf.id, pf.uri);
                                } else {
                                    (function(pf) {
                                        setTimeout(function() {scope.loadGeometryPack(pf.id, pf.uri);}, i * 200);
                                    })(pf);
                                }
                            }
                        }
                    }
                }
            }

            if (ew.data.progress == 1) {
                scope.loading = false;
                cleaner();
            }

            if (!svf.fragments.polygonCounts)
                svf.fragments.polygonCounts = new Int32Array(svf.fragments.length);

            // Set bvh to svf, if it is posted with svf together.
            if (ew.data.bvh) {
                scope.svf.bvh = ew.data.bvh;
                scope.model.setBVH(new NodeArray(scope.svf.bvh.nodes, scope.svf.bvh.useLeanNodes), scope.svf.bvh.primitives, scope.options.bvhOptions);
                scope.viewer3DImpl.invalidate(false, true);
            }

        } else if (ew.data && ew.data.bvh) {
            //Spatial index was done by the worker:
            if (scope.svf && !scope.svf.bvh) {
                scope.svf.bvh = ew.data.bvh;
                scope.model.setBVH(new NodeArray(scope.svf.bvh.nodes, scope.svf.bvh.useLeanNodes), scope.svf.bvh.primitives, scope.options.bvhOptions);
                scope.viewer3DImpl.invalidate(false, true);
            }
            scope.loading = false;
            cleaner();
        } else if (ew.data && ew.data.mesh) {
            //GLTF loader sends meshes from the main loader thread
            scope.processReceivedMesh(ew.data);

            if (ew.data.progress === 1) {
                scope.onGeomLoadDone();
                scope.loading = false;
                cleaner();
            }
        } else if (ew.data && ew.data.progress) {
            if (ew.data.progress == 1) {
                scope.loading = false;
                cleaner();
            }
        } else if (ew.data && ew.data.error) {
            scope.loading = false;
            cleaner();
            logger.error("Error while processing SVF: " + JSON.stringify(ew.data.error.args));
            if (onDone) {
                onDone(ew.data.error, null);
                delete scope.onDone;
            }
        } else if (ew.data && ew.data.debug) {
            logger.debug(ew.data.message);
        } else {
            logger.error("SVF download failed.", errorCodeString(ErrorCodes.NETWORK_FAILURE));
            //Download failed.
            scope.loading = false;
            cleaner();
        }
    };

    w.addEventListenerWithIntercept(onSVFLoad);

    msg.operation = WORKER_LOAD_SVF;
    msg.interceptManifest = !!SvfLoader.interceptManifest;
    w.doOperation(initLoadContext(msg));

    return true;
};

SvfLoader.prototype.cancelGeometryPackLoading = function() {
        
    // Cancel any ongoing geometry pack file loading.
    if (!this.pack_workers || !this.isValid()) {
        return;
    }

    for (var i=0; i<this.pack_workers.length; i++) {
        if (this.svf) {
            var pf = this.geommap[this.pack_workers[i].packId];
            if (pf)
                pf.loading = false;
        }
        this.pack_workers[i].queued = 0;
        this.pack_workers[i].clearAllEventListenerWithIntercept();
        this.pack_workers[i].terminate();
    }

    this.pack_workers = null;
};

SvfLoader.prototype.loadGeometryPackOnDemand = function(packId, inMemory) {

    // If loader is already destructed, do nothing.
    if(!this.svf || !this.isValid()) {
        return true;
    }

    // Do nothing if the geometry pack file is already in loading.
    var pf = this.geommap[packId];
    if (!pf || pf.loading) {
        return true;
    }

    // Record the time on first on demand geometry pack file loading request
    if (!this.t0) {
        this.t0 = new Date().getTime();
    }

    var pp = this.pagingProxy;
    var i;
    var scope = this;

    // Common handling when a worker is done with a pack file
    function packFileWorkerComplete(worker, packId) {
        worker.queued = 0;
        scope.geommap[packId].loading = false;

        pp.doOnDemandLoadFinished();
    }

    var onMeshLoad = function (ew) {
        if (ew.data && ew.data.meshes) {

            var meshes = ew.data.meshes;

            var mdata = {
                packId: ew.data.packId,
                meshIndex: 0,
                mesh:null
            };

            var geomSize = 0;
            for (var i=0; i<meshes.length; i++) {
                var mesh = meshes[i];

                if (!mesh)
                    continue;

                mdata.meshIndex = i;
                mdata.mesh = mesh;

                scope.processReceivedMesh(mdata);
                if (mdata.geometry) {
                    geomSize += mdata.geometry.byteSize + globals.GEOMETRY_OVERHEAD;
                    mdata.geometry = null;
                }
            }

            pp.onPackFileLoaded(ew.data.packId, ew.data, geomSize / MEGA);
            fireFragmentsLoadedEvent(scope, ew.data);

            if (ew.data.progress >= 1.0) {
                scope.loadedPacksCount++;
                packFileWorkerComplete(scope.pack_workers[ew.data.workerId], ew.data.packId);
            }
        } else if (ew.data && ew.data.progress) {
            scope.pack_workers[ew.data.workerId].queued -= 1;
            scope.viewer3DImpl.signalProgress(100 * scope.loadedPacksCount / scope.svf.geompacks.length);
        } else if (ew.data && ew.data.debug) {
            logger.debug(ew.data.message);
        } else if (ew.data && ew.data.error) {
            pp.onPackFileLoaded(ew.target.packId, null, 0);
            packFileWorkerComplete(ew.target, ew.target.packId);
            ++scope.failedToLoadPacksCount;
            scope.failedToLoadSomeGeometryPacks = {code:ew.data.error.code, msg:ew.data.error.msg};
        } else {
            //Download failed.
            pp.onPackFileLoaded(ew.target.packId, null, 0);
            packFileWorkerComplete(ew.target, ew.target.packId);
        }
    };

    // Initialize pack workers if it is not ready yet.
    if (!this.pack_workers) {
        this.pack_workers = [];

        var numWorkers = NUM_WORKER_THREADS;

        for (i = 0; i < numWorkers; i++) {
            var wr = createWorkerWithIntercept();
            wr.addEventListenerWithIntercept(onMeshLoad);

            wr.queued = 0;
            this.pack_workers.push(wr);
        }
    }

    //Find the least busy worker
    var which = 0;
    var queued = this.pack_workers[0].queued;
    for (i = 1; i < this.pack_workers.length; i++) {
        if (this.pack_workers[i].queued < queued) {
            which = i;
            queued = this.pack_workers[i].queued;
        }
    }

    // If worker is busy queue this reqest for next try.
    if (queued > 1 || pp.preparedPackFilesSize() >= pp.getMemoryLimit()) {

        // All workers are busy, then queue it for next try.
        if (!this.model.addGeomPackMissingLastFrame(packId)) {
            // If failed to add, it means that it is too many queued.
            // then restart render.
            this.viewer3DImpl.invalidate(false, true);
            //logger.debug("[On Demand Loading] Re-render on too many geom pack file requests.");
        }

        return false;
    }

    var w, workerId;
    var path = pf.uri;
    w = this.pack_workers[which];
    w.queued += 2;
    w.packId = packId;
    workerId = which;

    pp.onPackFileLoading(packId);

    pf.loading = true;
    this.svf.partPacksLoadDone = false; // Still loading geometry pack files.

    logger.debug("[On Demand Loading] Loading Geometry Pack file: " + packId);

    //Pass unzip job to the worker
    var reqPath = pathToURL(this.svf.basePath + path);
    var xfer = { "operation":WORKER_LOAD_GEOMETRY,
                "url": reqPath,
                "packId": packId, /* mesh IDs treat the pack file id as integer to save on storage in the per-fragment arrays */
                "workerId": workerId,
                "packNormals": this.options.packNormals,
                "createWireframe" : this.options.createWireframe || 
                                    this.model.getMetadata('renderEnvironmentDisplayEdges', 'value', false),
                "skipAssetCallback": true,
                "queryParams" : this.queryParams,
                "inMemory" : inMemory };

    w.doOperation(initLoadContext(xfer)); // Send data to our worker.
    return true;
};


SvfLoader.prototype.loadGeometryPack = function(packId, path) {
    var w;
    var workerId;
    var i, j;
    var scope = this;

    // If loader is already destructed, do nothing.
    if(!this.svf || !this.isValid()) {
        return;
    }

    var onMeshLoad = function (ew) {
        if (ew.data && ew.data.meshes) {

            var meshes = ew.data.meshes;

            var mdata = {
                packId: ew.data.packId,
                meshIndex: 0,
                mesh:null
            };

            for (var i=0; i<meshes.length; i++) {
                var mesh = meshes[i];

                if (!mesh)
                    continue;

                mdata.meshIndex = i;
                mdata.mesh = mesh;

                scope.processReceivedMesh(mdata);
            }

            //Is the worker done loading the geom pack?
            if (ew.data.progress >= 1.0) {
                scope.pack_workers[ew.data.workerId].queued -= 1;

                scope.loadedPacksCount++;
                scope.viewer3DImpl.signalProgress(100 * scope.loadedPacksCount / scope.svf.geompacks.length);

                //Are all workers done?
                var isdone = true;
                for (j = 0; j < scope.pack_workers.length; j++) {
                    if (scope.pack_workers[j].queued != 0) {
                        isdone = false;
                        break;
                    }
                }

                if (isdone) {
                    for (j = 0; j < scope.pack_workers.length; j++) {
                        scope.pack_workers[j].clearAllEventListenerWithIntercept();
                        scope.pack_workers[j].terminate();
                    }
                    scope.pack_workers = null;
                }

                if (scope.loadedPacksCount + scope.failedToLoadPacksCount == scope.svf.geompacks.length) { //all workers are done?
                    scope.onGeomLoadDone();
                }
            }
        } else if (ew.data && ew.data.progress) {
            //download is done, queue the next download
            scope.pack_workers[ew.data.workerId].queued -= 1;

            if (scope.next_pack < scope.svf.geompacks.length) {

                var pf = null;
                while(scope.next_pack < scope.svf.geompacks.length) {
                    pf = scope.svf.geompacks[scope.next_pack++];
                    if(!pf.loading) {
                        break;
                    }
                }

                if(pf && !pf.loading) {
                    pf.loading = true;
                    scope.loadGeometryPack(pf.id, pf.uri);
                }
                else {
                    scope.svf.fragments.packIds = null; // not needed anymore
                }
            }
        } else if (ew.data && ew.data.debug) {
            logger.debug(ew.data.message);
        } else if (ew.data && ew.data.error) {
            ++scope.failedToLoadPacksCount;
            scope.failedToLoadSomeGeometryPacks = {code:ew.data.error.code, msg:ew.data.error.msg};
        } else {
            //Download failed.
            scope.pack_workers[ew.data.workerId].queued -= 2;
        }
    };

    var pw = this.pack_workers;
    if (!pw) {
        pw = this.pack_workers = [];
    }

    //If all workers are busy and we are allowed to create more, then create a new one
    if (pw.length < (av.NUM_WORKER_THREADS || NUM_WORKER_THREADS)) {
        var allBusy = true;
        for (i=0; i<pw.length; i++) {
            if (pw[i].queued === 0) {
                allBusy = false;
                break;
            }
        }

        if (allBusy) {
            var wr = createWorkerWithIntercept();
            wr.addEventListenerWithIntercept(onMeshLoad);

            wr.queued = 0;
            pw.push(wr);
        }
    }

    //Find the least busy worker
    var which = 0;
    var queued = pw[0].queued;
    for (i = 1; i < pw.length; i++) {
        if (pw[i].queued < queued) {
            which = i;
            queued = pw[i].queued;
        }
    }
    w = pw[which];
    w.queued += 2;
    workerId = which;


    //Pass unzip job to the worker
    var reqPath = pathToURL(this.svf.basePath + path);
    var xfer = { "operation":WORKER_LOAD_GEOMETRY,
                "url": reqPath,
                "packId": parseInt(packId), /* mesh IDs treat the pack file id as integer to save on storage in the per-fragment arrays */
                "workerId": workerId,
                "createWireframe" : this.options.createWireframe ||
                                    this.model.getMetadata('renderEnvironmentDisplayEdges', 'value', false),
                "packNormals": this.options.packNormals,
                "queryParams" : this.queryParams };

    w.doOperation(initLoadContext(xfer)); // Send data to our worker.
};


SvfLoader.prototype.processReceivedMesh = function(mdata) {
    //Find all fragments that instance this mesh
    var meshid = mdata.packId + ":" + mdata.meshIndex;

    var svf = this.svf;
    var fragments = svf.fragments;
    var rm = this.model;

    var fragIndexes = fragments.mesh2frag[meshid];
    if (fragIndexes === undefined) {
        logger.warn("Mesh " + meshid + " was not referenced by any fragments.");
        return;
    }
    if (!Array.isArray(fragIndexes))
        fragIndexes = [fragIndexes];

    //Convert the received mesh to THREE buffer geometry
    BufferGeometryUtils.meshToGeometry(mdata);
    mdata.geometry.packId = mdata.packId;
    mdata.geometry.meshIndex = mdata.meshIndex;

    var numInstances = fragIndexes.length;

    //Reuse previous index of this geometry, if available
    var idx = rm.getFragmentList().getGeometryId(fragIndexes[0]);
    var geomId = rm.getGeometryList().addGeometry(mdata.geometry, numInstances, idx);

    // This is to record how many instances this geometry has,
    // and the number of instances have been rendered in one frame.
    this.pagingProxy && this.pagingProxy.onProcessReceivedMesh(mdata.geometry, numInstances);

    var ib = mdata.geometry.attributes['index'].array || mdata.geometry.ib;
    var polyCount = ib.length / 3;

    //For each fragment, add a mesh instance to the renderer
    for (var i=0; i<fragIndexes.length; i++) {
        var fragId = 0|fragIndexes[i];

        // option: skip meshes if they are marked as hidden
        if (svf.loadOptions.skipHiddenFragments) {
            var isHidden = !(fragments.visibilityFlags[fragId] & MeshFlags.MESH_VISIBLE);
            if (isHidden) {
                continue;
            }
        }

        //We get the matrix from the fragments and we set it back there
        //with the activateFragment call, but this is to maintain the
        //ability to add a plain THREE.Mesh -- otherwise it could be simpler
        rm.getFragmentList().getOriginalWorldMatrix(fragId, this.tmpMatrix);

        var materialId = fragments.materials[fragId].toString();

        if (fragments.polygonCounts)
            fragments.polygonCounts[fragId] = polyCount;

        var m = this.viewer3DImpl.setupMesh(this.model, mdata.geometry, materialId, this.tmpMatrix);

        //If there is a placement transform, we tell activateFragment to also recompute the
        //world space bounding box of the fragment from the raw geometry model box, for a tighter
        //fit compared to what we get when loading the fragment list initially.
        rm.activateFragment(fragId, m, !!svf.placementTransform);
    }

    //Call a custom mesh processing callback if one is supplied.
    //This is used for streaming geometry pack processing in node.js tools.
    //We are avoiding a more generic fireEvent mechanism in the interests of performance.
    //TODO: In the future, consider skipping all the geometry adding logic above
    //if such a callback is given -- it's most likely it only needs to look at the geometry then drop it
    if (this.options.onMeshReceived) {
        this.options.onMeshReceived(this.model, geomId, fragIndexes /*, mdata.geometry*/);
    }

    if (!this.model.getFragmentList().onDemandLoadingEnabled()) {
        //don't need this mapping anymore.
        fragments.mesh2frag[meshid] = null;
    }

    //Repaint and progress reporting
    fragments.numLoaded += fragIndexes.length;

    this.viewer3DImpl._signalMeshAvailable();

    //repaint every once in a while -- more initially, less as the load drags on.
    var geomList = rm.getGeometryList();
    if (geomList.geomPolyCount > svf.nextRepaintPolys) {
        //logger.log("num loaded " + numLoaded);
        this.firstPixelTimestamp = this.firstPixelTimestamp || Date.now();
        svf.numRepaints ++;
        svf.nextRepaintPolys += 10000 * Math.pow(1.5, svf.numRepaints);
        this.viewer3DImpl.invalidate(false, true);
    }
};

    //Some insane files come without any material reuse
    //which means we end up with ten of thousands of material objects
    //that are all the same. Create a re-mapping that gives a single ID
    //for all material IDs whose materials are duplicates
    /* currently not used
    function deduplicateMaterials(svf) {

        var mats = svf.materials.materials;

        var dedup = {};
        var remap = [];
        var count = 0;
        var ucount = 0;

        for (var p in mats) {

            var matIdx = parseInt(p);
            var hash = JSON.stringify(mats[p]);

            var idx = dedup[hash];

            if (idx === undefined) {
                remap[matIdx] = matIdx;
                dedup[hash] = matIdx;
                ucount++;
            } else {
                remap[matIdx] = idx;
                mats[p].duplicateOf = idx;
            }

            count++;
        }

        logger.log("Total mats: " + count + " Unique mats:" + ucount);

        var fmats = svf.fragments.materials;
        for (var i=0; i<fmats.length; i++) {
            fmats[i] = remap[fmats[i]];
        }

        return remap;
    }
    */

SvfLoader.prototype.onModelRootLoadDone = function(svf) {

    svf.geomMemory = 0;
    svf.fragments.numLoaded = 0;
    svf.gpuNumMeshes = 0;
    svf.gpuMeshMemory = 0;

    svf.nextRepaintPolys = 0;
    svf.numRepaints = 0;

    svf.urn = this.svfUrn;
    svf.acmSessionId = this.acmSessionId;

    svf.basePath = this.basePath;

    svf.loadOptions = this.options;

    //var tM = Date.now();
    //deduplicateMaterials(svf);

    var t1 = Date.now();
    this.loadTime += t1 - this.t0;
    logger.log("SVF load: " + (t1 - this.t0));
    //logger.log("Material dedup: " + (t1 - tM));

    // Create the API Model object and its render proxy
    var model = this.model = new Model(svf);
    model.loader = this;

    // Let's set the options through for each model that control how memory saving mode start,
    // which decide how to load geometry pack files, and whether paging out if needed.
    // And assume the performance tuning options passed through viewer's config.
    var memoryOpts = this.memoryOpts;

    // So, for now do not support on paging for multiple models.
    if (this.viewer3DImpl.modelQueue() && this.viewer3DImpl.modelQueue().length > 0) {
        // If already a model loaded into viewer, then disable paging for the other ones.
        memoryOpts.pageOutGeometryEnabled = false;
    }

    var geommap = this.geommap = {};  // Map packids to geompacks objects
    // Calculate the size of pack files and the geometry in them
    var meshCount = Object.keys(svf.fragments.mesh2frag).length;
    var fragCount = svf.fragments.length;
    var overhead = (meshCount * MESH_OVERHEAD + fragCount * FRAG_OVERHEAD) / MEGA;
    var totalGeomSize = 0;
    svf.geompacks.forEach(function(pf) {
        // If the geometry worker doesn't give us a geom size, then supply an estimate
        if (!pf.hasOwnProperty("geomSize"))
            pf.geomSize = pf.usize * GEOM_PACK_SIZE_FACTOR;
        pf.usize /= MEGA;
        pf.geomSize /= MEGA;
        totalGeomSize += pf.geomSize;
        geommap[parseInt(pf.id)] = pf;
    });
    // On mobile devices the memory used by the GPU is take from system memory, so we need
    // to include that memory in our estimate of the size of the model. This is also true
    // for laptops and other systems that have an integrated GPU, but we only include it
    // for mobile devices.
    var gpuSize = isMobileDevice() ? (Math.min(totalGeomSize, globals.GPU_MEMORY_LIMIT / MEGA)) : 0;
    totalGeomSize += overhead;

    // The estimated total geom size is the size of the geometry plus
    // the amount of memory needed to hold the uncompressed pack files in workers
    memoryOpts.totalGeomSize = totalGeomSize;
    memoryOpts.overheadSize = overhead;

    // On demand loading will be controlled by two factors.
    // 1. A global switch that enable/disable this behavior.
    // 2. The size of the model.
    if (memoryOpts.onDemandLoading) {
        if (totalGeomSize + gpuSize < memoryOpts.limit && !memoryOpts.debug.force)
            memoryOpts.onDemandLoading = false;
        else
            this.pagingProxy = new SvfPagingProxy(this, memoryOpts);
    }

    model.initialize(this.pagingProxy);

    var scope = this;
    function onLoaded(ew) {
        if (!scope.isValid() || !scope.pagingProxy)
            return;

        scope.pagingProxy.onLoadOrderCalculated(ew.data);
    }

    // If this is an svf file, then start the worker that calculates the load order
    if  (this.model.getFragmentList().onDemandLoadingEnabled() && svf.fragments.boxes && svf.fragments.packIds) {
        // Create the worker and send the boxes and packids to it.
        this.svfLoadOrderWorker = createWorker();
        this.svfLoadOrderWorker.addEventListener('message', onLoaded, false);
        this.svfLoadOrderWorker.doOperation(
            { operation: WORKER_CALCULATE_LOAD_ORDER,
            fragments: { boxes: svf.fragments.boxes, packIds : svf.fragments.packIds }
            });
        this.model.setUUID(svf.urn);
    }

    //For 3D models, we can start loading the property database as soon
    //as we know the fragment list which contains the fragId->dbId map.
    //We would not load property db when we are on mobile device AND on demand loading is on (which
    //implies the model is not 'normal' in terms of its size.). This is only a temp solution that
    //allow big models loads on mobile without crash. Without property db loading selection could break.
    var shouldLoadPropertyDb = !(this.model.getFragmentList().onDemandLoadingEnabled() && (isMobileDevice()));
    if (this.options.skipPropertyDb === undefined ? shouldLoadPropertyDb : !this.options.skipPropertyDb) {
        this.loadPropertyDb();
    } else {
        // Not loading the property database, supply a flat dbid to fragment map
        this.svf.fragmentMap = new DbidFragmentMap(this.svf.fragments.fragId2dbId);
    }

    var numMaterials = this.convertMaterials(model);

    this.t1 = t1;

    //The BBox object loses knowledge of its
    //type when going across the worker thread boundary...
    svf.bbox = new THREE.Box3().copy(svf.bbox);

    if (svf.refPointTransform) {
        svf.refPointTransform = new LmvMatrix4(true).copy(svf.refPointTransform);
    }
    if (svf.placementTransform) {
        svf.placementTransform = new LmvMatrix4(true).copy(svf.placementTransform);
    }

    //Camera vectors also lose their prototypes when they
    //cross the thread boundary...
    if (svf.cameras) {
        for (var i = 0; i < svf.cameras.length; i++) {
            var camera = svf.cameras[i];
            camera.position = new THREE.Vector3().copy(camera.position);
            camera.target = new THREE.Vector3().copy(camera.target);
            camera.up = new THREE.Vector3().copy(camera.up);

            // We have a case of a camera coming in with Nans for position and target. Not sure what to do in that case.
            if (!isFinite(camera.position.x + camera.position.y + camera.position.z
                + camera.target.x + camera.target.y + camera.target.z
                + camera.up.x + camera.up.y + camera.up.z)) {
                // Some coordinate in position, target or up is junk. Scrap them. Put the target at
                // the center of the bounding box and the position outside of the bounding box.
                camera.target = svf.bbox.center();
                camera.position.copy(camera.target);
                camera.position.z += svf.bbox.max.z - svf.bbox.min.z;
                camera.up = { x:0, y: 1, z: 0};
            }
            if (!isFinite(camera.aspect)) {
                camera.aspect = 1;
            }
            if (!isFinite(camera.fov)) {
                camera.fov = 90;
            }
            if (!isFinite(camera.orthoScale)) {
                camera.orthoScale = 1;
            }
        }
    }

    logger.log("scene bounds: " + JSON.stringify(svf.bbox));

    var metadataStats = {
        category: "metadata_load_stats",
        urn: svf.urn,
        has_topology: !!svf.topologyPath,
        has_animations: !!svf.animations,
        cameras: svf.cameras ? svf.cameras.length : 0,
        lights: svf.lights ? svf.lights.length : 0,
        materials: numMaterials,
        is_mobile: isMobileDevice()
    };
    logger.track(metadataStats);

    this.viewer3DImpl.signalProgress(5);
    this.viewer3DImpl.invalidate(false, true);
};


SvfLoader.prototype.calculateLoadOrder = function(id, camera, pixelCullingThreshold) {
    if (!this.svfLoadOrderWorker)
        return false;

    var cvtcam = {
        projectionMatrix: { elements: camera.projectionMatrix.elements },
        matrixWorldInverse: { elements: camera.matrixWorldInverse.elements },
        aspect: camera.aspect,
        position: { x: camera.position.x, y: camera.position.y, z: camera.position.z },
        clientWidth: camera.clientWidth,
        clientHeight: camera.clientHeight
    };
    var msg = {
        operation: WORKER_CALCULATE_LOAD_ORDER,
        id: id,
        camera : cvtcam,
        pixelCullingThreshold: pixelCullingThreshold
    };
    this.svfLoadOrderWorker.doOperation(msg);
    return true;
};

    /**
     * Converts from SVF materials json to THREE.js materials and adds them to the MaterialManager
     * @param {RenderModel} model
     */
SvfLoader.prototype.convertMaterials = function(model) {

    var matman = this.viewer3DImpl.matman();
    var svf = model.getData();
    var totalAdded = 0;
    var p;

    if (!svf.materials) {
        return totalAdded;
    }

    if (svf.gltfMaterials) {

        var gltfmats = svf.materials["materials"];
        for (p in gltfmats) {

            var gltfMat = gltfmats[p];
            var phongMat = MaterialConverter.convertMaterialGltf(gltfMat, svf);
            var matName = matman._getMaterialHash(model, p);
            matman.addMaterial(matName, phongMat, false);
            totalAdded++;
        }

        return totalAdded;
    }

    // Get outer Protein materials block.
    // The way this works: there is always (supposed to be) a Materials.json file in SVF. This
    // is put into svf.materials["materials"]. There is also, optionally, a ProteinMaterials.json
    // file, read into svf.proteinMaterials["materials"]. We look through the Protein materials
    // (if present) and see which ones we can interpret (currently only PRISM materials). If we
    // can interpret it, great. Otherwise, we use the Materials.json file's version, which is
    // (always) a SimplePhong material.
    var mats = svf.materials["materials"];
    var prismmats = svf.proteinMaterials ? svf.proteinMaterials["materials"] : null;

    for (p in mats) {

        var isPrism = prismmats && prismmats[p] && MaterialConverter.isPrismMaterial(prismmats[p]);

        //If the definition is prism, use the prism object.
        var matObj = isPrism ? prismmats[p] : mats[p];

        matman.convertOneMaterial(model, matObj, matman._getMaterialHash(model, p));

        totalAdded++;
    }

    return totalAdded;
};


SvfLoader.prototype.makeBVH = function(svf) {
    var t0 = performance.now();
    var mats = svf.materials ? svf.materials["materials"] : null;
    svf.bvh = new BVHBuilder(svf.fragments, mats);
    svf.bvh.build(this.options.bvhOptions || {isWeakDevice : isMobileDevice()});
    var t1 = performance.now();
    logger.log("BVH build time: " + (t1 - t0));
};

SvfLoader.prototype.onDemandGeomLoadDone = function() {
    // Only check to load textures once.
    if (!this.svf.loadDone) {
        if (isMobileDevice()) {
            // If we are on a mobile device, then we will check the memory used
            // to see if we think there may be enough to load textures.

            // Get the memory limit from the renderScene, which can hold multiple models. If
            // we don't have a renderScene to use, just use the model to get the memory limit.
            var info = this.viewer3DImpl.modelQueue() ? this.viewer3DImpl.modelQueue().getMemoryInfo() : this.model.getMemoryInfo();

            // If we can't get the info, then don't load the textures.
            if (info) {
                // If the effectiveLimit is larger than the limit, then the model has
                // so many fragments, that we don't think we can restrict memory to
                // the requested limit. This means memory should be really tight and
                // we won't load the textures. If the loaded memory size if too close
                // to the limit, then don't load the texture.
                if (info.effectiveLimit <= info.limit && info.limit - info.loaded > MOBILE_TEXTURE_LIMIT) {
                    // Listen for the textures loaded event
                    var callback = function(e) {
                        this.viewer3DImpl.api.removeEventListener(et.TEXTURES_LOADED_EVENT, callback);
                        // Calculate the size of textures used by this model
                        var size = 0;
                        this.viewer3DImpl.matman().enumTextures(this.model, function(tex) {
                            if (tex) {
                                size += TextureLoader.calculateTextureSize(tex);
                            }
                        });
                        // Adjust the limit in the SvfPagingProxy.
                        size /= MEGA;
                        this.pagingProxy.options.limit -= size;
                    }.bind(this);
                    // When all of the textures are loaded, adjust the memory limit
                    this.viewer3DImpl.api.addEventListener(et.TEXTURES_LOADED_EVENT, callback);
                    // Load the textures
                    TextureLoader.loadModelTextures(this.model, this.viewer3DImpl);
                }
            }
        } else {
            // If we aren't on a mobile device, then load the textures.
            TextureLoader.loadModelTextures(this.model, this.viewer3DImpl);
        }
    }

    this.svf.loadDone = true;

    // Time for loading part of the on-demanded geometries.
    var t1 = Date.now();
    var msg = "[On Demand Loading] On demand requested geometries load time: " + (t1 - this.t0);

    logger.log(msg);

    // Track the on demand geom load stats.
    var modelStats = {
        category: "on_demand_geom_load_stats",
        is_f2d: false,
        has_prism: this.viewer3DImpl.matman().hasPrism,
        load_time: (t1 - this.t0),
        geometry_size: this.model.getGeometryList().geomMemory,
        meshes_count: this.model.getGeometryList().geoms.length,
        fragments_count: this.model.getFragmentList().getCount(),
        load_pack_count: this.loadedPacksCount,
        urn: this.svfUrn
    };
    logger.track(modelStats);

    // clear the start time, which can be set again if on demand loading geometry again.
    this.t0 = null;

    this.loadedPacksCount = 0;
    this.svf.partPacksLoadDone = true;
    this.viewer3DImpl.dispatchEvent({type:et.GEOMETRY_DOWNLOAD_COMPLETE_EVENT, model:this.model, memoryLimited: true});

    if (this.onDone) {
        this.onDone(null, this.model);
        delete this.onDone;
    }
};

SvfLoader.prototype.onGeomLoadDone = function() {
    this.svf.loadDone = true;

    //launch the texture loads in case that was not done already
    TextureLoader.loadModelTextures(this.model, this.viewer3DImpl);

    // We need to keep a copy of the original fragments
    // transforms in order to restore them after explosions, etc.
    // the rotation/scale 3x3 part.
    // TODO: consider only keeping the position vector and throwing out
    //
    //delete this.svf.fragments.transforms;

    // Release that won't be used. the on demand loading won't call this anyway.
    this.svf.fragments.entityIndexes = null;
    this.svf.fragments.mesh2frag = null;

    // flags for initial visibility have now all been processed
    this.svf.fragments.visibilityFlags = null;

    var t1 = Date.now();
    var msg = "Fragments load time: " + (t1 - this.t1);
    this.loadTime += t1 - this.t0;

    var firstPixelTime = this.firstPixelTimestamp - this.t0;
    msg += ' (first pixel time: ' + firstPixelTime + ')';

    //If there is a post-transform, the BVH has to be computed after
    //all the world transforms/boxes are updated
    if (!this.svf.bvh || this.svf.placementTransform) {
        this.makeBVH(this.svf);
        this.model.setBVH(this.svf.bvh.nodes, this.svf.bvh.primitives, this.options.bvhOptions);
        this.viewer3DImpl.invalidate(false, true);
    }
    logger.log(msg);

    // Run optional consolidation step
    if (this.options.useConsolidation && this.viewer3DImpl.glrenderer())
        this.model.consolidate(this.viewer3DImpl.matman(), this.options.consolidationMemoryLimit, this.viewer3DImpl.glrenderer());

    var modelStats = {
        category: "model_load_stats",
        is_f2d: false,
        has_prism: this.viewer3DImpl.matman().hasPrism,
        load_time: this.loadTime,
        geometry_size: this.model.getGeometryList().geomMemory,
        meshes_count: this.model.getGeometryList().geoms.length,
        fragments_count: this.model.getFragmentList().getCount(),
        urn: this.svfUrn
    };
    if (firstPixelTime > 0) {
        modelStats['first_pixel_time'] = firstPixelTime; // time [ms] from SVF load to first geometry rendered
    }
    logger.track(modelStats);

    this.currentLoadPath = null;

    this.viewer3DImpl.dispatchEvent({type:et.GEOMETRY_DOWNLOAD_COMPLETE_EVENT, model:this.model, memoryLimited: false});

    if (this.onDone) {
        this.onDone(null, this.model);
        delete this.onDone;
    }
};


SvfLoader.prototype.loadPropertyDb = function() {
    this.svf.propDbLoader = new PropDbLoader(this.sharedDbPath, this.model, this.viewer3DImpl.api);
    this.svf.propDbLoader.load();
};


SvfLoader.prototype.fetchTopologyFile = function(fullpath, onComplete) {
        
    if (this.fetchingTopology)
        return;

    this.fetchingTopology = true;

    var ww = createWorkerWithIntercept();
    ww.addEventListenerWithIntercept( onTopology );

    var msg = {
        path: fullpath,
        queryParams : this.queryParams,
    };
    var t0 = new Date().getTime();
    var t1, t2, timeSpan;
    logger.log('Fetching topology file...');
    msg.operation = WORKER_FETCH_TOPOLOGY;
    ww.doOperation(initLoadContext(msg));

    var that = this;
    function onTopology(workerEvent) {

        // Status check
        if (workerEvent.data['status-topology']) {
            t1 = new Date().getTime();
            timeSpan = Math.round((t1-t0)/1000);
            logger.log('Topology file downloaded. (' + timeSpan + ' seconds). Processing...');
            return;
        }

        // Done processing.
        var topoData = workerEvent.data['fetch-topology'];
        if (topoData) {
            t2 = new Date().getTime();
            timeSpan = Math.round((t2-t1)/1000);
            if (topoData.topology) {
                logger.log('Topology file processed successfully! (' + timeSpan + ' seconds).');
            } else {
                logger.log('Topology file processed, but an error ocurred. (' + timeSpan + ' seconds).');
            }
            onComplete( topoData );
            that.fetchingTopology = false;
            ww.clearAllEventListenerWithIntercept();
            ww.terminate();
            ww = null;
        }
    }
};

SvfLoader.prototype.is3d = function() {
    return true;
};


FileLoaderManager.registerFileLoader("svf", ["svf", "gltf", "glb"], SvfLoader);