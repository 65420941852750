
import { Extension } from "../../src/application/Extension";
import { ToolBar } from "../../src/gui/toolbar/ToolBar";
import { ControlGroup } from "../../src/gui/controls/ControlGroup";
import { Button } from "../../src/gui/controls/Button";
import { Control } from "../../src/gui/controls/Control";
import { TOOLBAR } from "../../src/gui/GuiViewerToolbarConst";
import { isTouchDevice, isIE11 } from "../../src/compat";
import { i18n } from "../../src/globalization/i18next";
import * as et from "../../src/application/EventTypes"
import { TOOLBAR_CREATED_EVENT } from "../../src/gui/GuiViewerToolbarConst";


export const ANIMATION_PLAY_EVENT = 'animationPlayEvent';
export const ANIMATION_PAUSE_EVENT = 'animationPauseEvent';
export const ANIMATION_SEEK_EVENT = 'animationSeekEvent';
export const ANIMATION_PREVIOUS_FRAME_EVENT = 'animationPreviousFrameEvent';
export const ANIMATION_NEXT_FRAME_EVENT = 'animationNextFrameEvent';
export const ANIMATION_TRACK_UPDATE_EVENT = 'animationTrackUpdateEvent';
export const ANIMATION_TOOLBAR_CLOSE_EVENT = 'animationToolbarCloseEvent';

import CSS from './AnimationToolbar.css' // IMPORTANT!!

//TODO: These exports into the global namespace seem to only be used by unit tests
var av = Autodesk.Viewing;
av.ANIMATION_PLAY_EVENT = ANIMATION_PLAY_EVENT;
av.ANIMATION_PAUSE_EVENT = ANIMATION_PAUSE_EVENT;
av.ANIMATION_SEEK_EVENT = ANIMATION_SEEK_EVENT;
av.ANIMATION_PREVIOUS_FRAME_EVENT = ANIMATION_PREVIOUS_FRAME_EVENT;
av.ANIMATION_NEXT_FRAME_EVENT = ANIMATION_NEXT_FRAME_EVENT;
av.ANIMATION_TRACK_UPDATE_EVENT = ANIMATION_TRACK_UPDATE_EVENT;
av.ANIMATION_TOOLBAR_CLOSE_EVENT = ANIMATION_TOOLBAR_CLOSE_EVENT;


/**
 * 
 * AnimationExtension adds a toolbar with buttons (play/pause/forward/backward/goto start/end)
 * and timeline scrubber to control animation playback. 
 * The extension provides api methods that will be reflected by the animation toolbar.
 * 
 * The extension id is: `Autodesk.Fusion360.Animation`
 * 
 * @example
 *   viewer.loadExtension('Autodesk.Fusion360.Animation')
 * 
 * @memberof Autodesk.Viewing.Extensions
 * @alias Autodesk.Viewing.Extensions.AnimationExtension
 * @see {@link Autodesk.Viewing.Extension} for common inherited methods.
 * @constructor
 */
export function AnimationExtension(viewer, options) {
    Extension.call(this, viewer, options);
    this.viewer = viewer;
    this.animTools = null;
    this.animToolsId = "animationTools";
    this.playButton = null;
    this.playButtonIsPaused = true;
    this.prevAnimationTime = -1;
    this.name = 'fusionanimation';
}

AnimationExtension.prototype = Object.create(Extension.prototype);
AnimationExtension.prototype.constructor = AnimationExtension;

/**
 * Converts seconds into Hours:Minutes:Seconds String
 * @param {Number} time in seconds
 * @returns {string}
 * @private
 */
function convertSecsToHMS(time) {
    var sign = "";
    if (time < 0) {sign="-"; time = -time;}
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = time % 60;
    var ret = sign;
    if (hrs > 0)
        ret += hrs + ":" + (mins < 10 ? "0" : "");
    ret += mins + ":" + (secs < 10 ? "0" : "");
    ret += secs.toFixed(2);
    return ret;
}

/**
 * Adds a toolbar button and hooks animation listeners.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#load
 */
AnimationExtension.prototype.load = function() {
    var viewer = this.viewer;

    this.onPlayCallbackBinded = this.onPlayCallback.bind(this);
    this.onCameraChangeBinded = this.onCameraChange.bind(this);
    this.onExplodeBinded = this.onExplode.bind(this);
    this.onResizeBinded = this.onResize.bind(this);
    this.onEscapeBinded = this.onEscape.bind(this);

    viewer.addEventListener(et.CAMERA_CHANGE_EVENT, this.onCameraChangeBinded);
    viewer.addEventListener(et.EXPLODE_CHANGE_EVENT, this.onExplodeBinded);
    viewer.addEventListener(et.VIEWER_RESIZE_EVENT, this.onResizeBinded);
    viewer.addEventListener(et.ESCAPE_EVENT, this.onEscapeBinded);

    // init animations after object tree created and geometry loaded
    if (viewer.model && viewer.model.isObjectTreeCreated()) {
        this.onAnimationReady();
    } else {
        this.onAnimationReadyBinded = this.onAnimationReady.bind(this);
        viewer.addEventListener(et.ANIMATION_READY_EVENT, this.onAnimationReadyBinded);
    }

    return true;
};

/**
 * Removes toobar button and unhooks animation listeners.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#unload
 */
AnimationExtension.prototype.unload = function () {
    var viewer = this.viewer;

    if (this.onAnimationReadyBinded) {
        viewer.removeEventListener(et.ANIMATION_READY_EVENT, this.onAnimationReadyBinded);
        this.onAnimationReadyBinded = null;
    }

    // stop animations
    this.rewind();
    viewer.impl.invalidate(true, true, true); // Required to reset animations when Extension unloads and viewer remains.

    this.onPlayCallbackBinded = null;

    if (this.animTools) {
        this.animTools.removeControl(this.animTools.timeText.getId());
        this.animTools.removeControl(this.animTools.timeline.getId());
        this.animTools.removeControl(this.animTools.timeLeftText.getId());
        this.animTools.removeControl(this.animTools.forwardButton.getId());
        this.animTools.removeControl(this.animTools.backwardButton.getId());
        this.animTools.removeControl(this.animTools.closeButton.getId());
    }

    if (this.toolbar) {
        this.toolbar.removeControl(this.animTools);
        this.toolbar.container.parentNode.removeChild(this.toolbar.container);
        this.toolbar = null;
    }

    if (this.playButton) {
        var toolbar = viewer.getToolbar(false);
        if (toolbar) {
            toolbar.getControl(TOOLBAR.MODELTOOLSID).removeControl(this.playButton.getId());
        }
    }

    // Remove event listeners
    viewer.removeEventListener(et.CAMERA_CHANGE_EVENT, this.onCameraChangeBinded);
    viewer.removeEventListener(et.EXPLODE_CHANGE_EVENT, this.onExplodeBinded);
    viewer.removeEventListener(et.VIEWER_RESIZE_EVENT, this.onResizeBinded);
    viewer.removeEventListener(et.ESCAPE_EVENT, this.onEscapeBinded);

    if (this.onToolbarCreatedBinded) {
        viewer.removeEventListener(TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
        this.onToolbarCreatedBinded = null;
    }

    return true;
};

/**
 * Plays the animation. Invoke pause() to stop the animation.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#play
 */
AnimationExtension.prototype.play = function() {

    if (this.isPlaying()) {
        return;
    }

    this.resetExplode(0, true);

    var viewer = this.viewer;
    var animator = viewer.impl.keyFrameAnimator;
    if (!animator) return;

    // restore previous animation if set
    if (this.prevAnimationTime > 0) {
        animator.goto(this.prevAnimationTime);
        this.prevAnimationTime = -1;
    }

    animator.play(0, this.onPlayCallbackBinded);

    this.updatePlayButton(animator.isPaused);

    if (this.animTools) {
        this.animTools.setVisible(true);
        if (!this.animTools.isPositionAdjusted) {
            this.adjustToolbarPosition();
            this.animTools.isPositionAdjusted = true;
        }
    }
};

/**
 * Pauses an active animation. Can resume by invoking play()
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#pause
 */
AnimationExtension.prototype.pause = function() {

    if (this.isPaused()) {
        return;
    }

    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return;
    animator.pause();

    // UI stuff
    this.updatePlayButton(animator.isPaused);
};

/**
 * Whether the animation is currently playing.
 * Always returns the opposite of isPaused()
 * @returns {Boolean}
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#isPlaying
 */
AnimationExtension.prototype.isPlaying = function() {

    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return false;
    return animator.isPlaying && !animator.isPaused;
};

/**
 * Wether the animation is currently paused.
 * Always returns the opposite of isPlaying()
 * @returns {Boolean}
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#isPaused
 */
AnimationExtension.prototype.isPaused = function() {

    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return false;
    return animator.isPaused;
};

/**
 * Rewinds and pauses the animation.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#rewind
 */
AnimationExtension.prototype.rewind = function() {
    this.setTimelineValue(0);
};

/**
 * Sets the animation at the very beginning (0), at the end(1) or anywhere in between.
 * For example, use value 0.5 to set the animation half way through it's completion.
 * Will pause a playing animation.
 *
 * @param {Number} scale - value between 0 and 1
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#setTimelineValue
 */
AnimationExtension.prototype.setTimelineValue = function(scale) {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return;
    scale = Math.min(Math.max(0,scale), 1);
    var time = scale * animator.duration;
    animator.goto(time);
    this.updateUI();
};

/**
 * Sets animation onto the previous keyframe.
 * Will pause the animation if playing.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#prevKeyframe
 */
AnimationExtension.prototype.prevKeyframe = function() {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return;
    animator.prev();
    this.updateUI();
};

/**
 * Sets animation onto the next keyframe.
 * Will pause the animation if playing.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#nextKeyframe
 */
AnimationExtension.prototype.nextKeyframe = function() {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return;
    animator.next();
    this.updateUI();
};

/**
 * Returns how many seconds does the animation take to complete.
 * @return {Number}
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#getDuration
 */
AnimationExtension.prototype.getDuration = function() {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return 0;
    return animator.duration;
};

/**
 * Returns duration as a formatted String h:mm:ss (hours:minutes:seconds)
 * @returns {string}
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#getDurationLabel
 */
AnimationExtension.prototype.getDurationLabel = function() {
    return convertSecsToHMS(this.getDuration());
};

/**
 * Returns the elapsed time (in seconds) of the animation.
 * @return {Number}
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#getCurrentTime
 */
AnimationExtension.prototype.getCurrentTime = function() {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator) return 0;
    return animator.currentTime;
};

/**
 * Returns the current animation time as a formatted String h:mm:ss (hours:minutes:seconds)
 * @returns {string}
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#getCurrentTimeLabel
 */
AnimationExtension.prototype.getCurrentTimeLabel = function() {
    return convertSecsToHMS(this.getCurrentTime());
};

/**
 * Whether a playing animation updates the camera position.
 * 
 * @param {boolean} followCam - true to allow animation to update camera position (default behavior).
 * 
 * @returns {boolean} true if the operation was successful.
 *
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#setFollowCamera
 */
AnimationExtension.prototype.setFollowCamera = function(followCam) {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator)
        return false;

    animator.setFollowCamera(followCam || false);
    return true;
};

/**
 * @returns {boolean} Whether animations will update the camera's position (true) or not (false)
 *
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#isFollowingCamera
 */
AnimationExtension.prototype.isFollowingCamera = function() {
    var animator = this.viewer.impl.keyFrameAnimator;
    if (!animator)
        return true; // default behavior is following the camera

    return animator.isFollowingCamera();
};

/**
 * @private
 */
AnimationExtension.prototype.onAnimationReady = function() {
    var viewer = this.viewer;

    if (this.onAnimationReadyBinded) {
        viewer.removeEventListener(et.ANIMATION_READY_EVENT, this.onAnimationReadyBinded);
        this.onAnimationReadyBinded = null;
    }

    // Check for animator class
    if (!viewer.impl.keyFrameAnimator)
        return;

    // Add the ui only if an animation is available.
    if (viewer.toolbar && viewer.modelTools) {
        this.onToolbarCreated();
    } else {
        this.onToolbarCreatedBinded = this.onToolbarCreated.bind(this);
        viewer.addEventListener(TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
    }
};

/**
 *
 * @private
 */
AnimationExtension.prototype.updateUI = function() {

    var animator = this.viewer.impl.keyFrameAnimator;
    if (!this.animTools || !animator) {
        return;
    }
    this.animTools.input.value = animator.duration > 0 ? animator.currentTime / animator.duration * 100 : 0;
    this.animTools.lapse.value = convertSecsToHMS(animator.currentTime);
    this.animTools.lapseLeft.value = convertSecsToHMS(animator.duration);
    this.updatePlayButton(animator.isPaused);
    this.updateToolbarBackground();
};

/**
 * @private
 */
AnimationExtension.prototype.onPlayCallback = function(value) {

    // TODO: We should be able to replace this whole method body with a call to update().
    // The only problem for now is taht we would also need to change KeyFrameAnimator because
    // the onPlayCallback() is being invoked BEFORE the animation is paused.
    if (!this.animTools) return;

    var animator = this.viewer.impl.keyFrameAnimator;
    this.viewer.dispatchEvent( {type: ANIMATION_TRACK_UPDATE_EVENT, data: {time: animator.currentTime, duration: animator.duration }});
    this.animTools.input.value = value;
    this.animTools.lapse.value = convertSecsToHMS(animator.currentTime);
    this.animTools.lapseLeft.value = convertSecsToHMS(animator.duration);

    if (value >= 100) {
        this.updatePlayButton(true);
    }
    this.updateToolbarBackground();
};

/**
 *
 * @param isPaused
 * @private
 */
AnimationExtension.prototype.updatePlayButton = function(isPaused) {
    if (!this.playButton) {
        return;
    }

    if (isPaused === this.playButtonIsPaused) {
        return;
    }

    this.playButtonIsPaused = isPaused;
    var animator = this.viewer.impl.keyFrameAnimator;

    if (isPaused) {
        this.playButton.setIcon('toolbar-animation-play-icon');
        this.playButton.setToolTip('Play');
        this.viewer.dispatchEvent({ type: ANIMATION_PAUSE_EVENT, data: {time: animator.currentTime, duration: animator.duration } });

    } else {
        this.playButton.setIcon('toolbar-animation-pause-icon');
        this.playButton.setToolTip('Pause');
        this.viewer.dispatchEvent({ type: ANIMATION_PLAY_EVENT, data: {time: animator.currentTime, duration: animator.duration } });
    }
};

/**
 * Helper function that resets model explosion.
 * @param value
 * @param setSlider
 * @private
 */
AnimationExtension.prototype.resetExplode = function(value, setSlider) {
    var viewer = this.viewer;
    if (!viewer.model.is2d() && viewer.getExplodeScale() !== 0) {
        if (setSlider && viewer.explodeSlider) { // explodeSlider is only in GuiViewer3D instances
            viewer.explodeSlider.value = value;
        }
        viewer.explode(value);
    }
};

/**
 * @private
 */
AnimationExtension.prototype.adjustToolbarPosition = function() {
    // set timeline width
    var viewer = this.viewer;
    if (!viewer.toolbar) return;
    var fullwidth = viewer.toolbar.getDimensions().width;
    var viewportWidth = viewer.container.getBoundingClientRect().width;
    if (fullwidth > viewportWidth)
        fullwidth = viewer.modelTools.getDimensions().width;
    var inputWidth = fullwidth - (2 *
        this.animTools.backwardButton.getDimensions().width + 3 *
        this.animTools.timeText.getDimensions().width + this.animTools.closeButton.getDimensions().width + 20);
    this.animTools.input.style.width = inputWidth + 'px';
};

/**
 * @private
 */
AnimationExtension.prototype.hideAnimateToolbar = function() {
    if (this.animTools) {
        this.animTools.setVisible(false);
    }
};

/**
 * @private
 */
AnimationExtension.prototype.updateToolbarBackground = function() {
    if (!this.animTools) return;
    var input = this.animTools.input;
    var percentage = input.value;
    var col1 = "#ffffff", col2 = "#393939";
    input.style.background = "-webkit-linear-gradient(left,"+col1+" "+percentage+"%, "+col2+" "+percentage+"%)";
    input.style.background = "-moz-linear-gradient(left,"+col1+" "+percentage+"%, "+col2+" "+percentage+"%)";
    input.style.background = "-ms-linear-gradient(left,"+col1+" "+percentage+"%, "+col2+" "+percentage+"%)";
    input.style.background = "-o-linear-gradient(left,"+col1+" "+percentage+"%, "+col2+" "+percentage+"%)";
    input.style.background = "linear-gradient(to right,"+col1+" "+percentage+"%, "+col2+" "+percentage+"%)";
};

/**
 * @private
 */
AnimationExtension.prototype.onCameraChange = function() {

    if (this.viewer.impl.keyFrameAnimator && !this.viewer.impl.keyFrameAnimator.isFollowingCamera())
        return;

    if (this.viewer.toolController.cameraUpdated) {
        var animator = this.viewer.impl.keyFrameAnimator;
        if (!animator) return;
        if (animator.isPlaying && !animator.isPaused) {
            animator.pause();
            this.updatePlayButton(animator.isPaused);
        }
    }
};

/**
 * @private
 */
AnimationExtension.prototype.onResize = function() {
    if (!this.toolbar) return;
    if (this.viewer.container.clientWidth < (isTouchDevice() ? 560 : 600)) {
        this.toolbar.setCollapsed(true);
    } else {
        this.toolbar.setCollapsed(false);
        this.adjustToolbarPosition();
    }
};

/**
 * @private
 */
AnimationExtension.prototype.onEscape = function () {

    if (this.isPlaying()) {
        this.pause();
    } else {
        this.hideAnimateToolbar();
    }
};

/**
 * @private
 */
AnimationExtension.prototype.onExplode = function() {
    // reset animation
    var animator = this.viewer.impl.keyFrameAnimator;
    if (animator) {
        if (animator.currentTime !== 0) {
            this.prevAnimationTime = animator.currentTime;
            animator.goto(0);
        }
        this.updatePlayButton(true);
    }
    this.hideAnimateToolbar();
};

/**
 * @private
 */
AnimationExtension.prototype.onToolbarCreated = function() {

    var viewer = this.viewer;
    var that = this;

    if (this.onToolbarCreatedBinded) {
        viewer.removeEventListener(TOOLBAR_CREATED_EVENT, this.onToolbarCreatedBinded);
        this.onToolbarCreatedBinded = null;
    }

    this.toolbar = new ToolBar('animation-toolbar');
    this.toolbar.addClass('toolbar-animation-subtoolbar');
    viewer.container.appendChild(this.toolbar.container);

    this.animTools = new ControlGroup(this.animToolsId);
    this.animTools.setVisible(false);
    this.toolbar.addControl(this.animTools);

    // play button at first of modelTools
    this.playButton = new Button('toolbar-animationPlay');
    this.playButton.setIcon('toolbar-animation-play-icon');
    this.playButton.setToolTip('Play');
    this.playButton.onClick = function() {
        if (that.isPaused()) {
            that.activate();
        } else {
            that.deactivate();
        }
    };
    viewer.modelTools.addControl(this.playButton);

    // override reset button's onClick method
    if (viewer.modelTools.resetModelButton) {
        viewer.modelTools.resetModelButton.onClick = function(e) {
            viewer.showAll();
            var animator = viewer.impl.keyFrameAnimator;
            if (animator) {
                animator.goto(0);
                input.value = 0;
                lapse.value = convertSecsToHMS(0);
                lapseLeft.value = convertSecsToHMS(animator.duration);
                that.updatePlayButton(true);
            }
            that.resetExplode(0, true);
            that.updateToolbarBackground();
        };
    }

    // backward button
    this.animTools.backwardButton = new Button('toolbar-animationBackward');
    this.animTools.backwardButton.setToolTip('Previous keyframe');
    this.animTools.backwardButton.onClick = function(e) {
        var animator = viewer.impl.keyFrameAnimator;
        if (animator !== undefined && animator) {
            animator.prev();
            viewer.dispatchEvent({ type: ANIMATION_PREVIOUS_FRAME_EVENT, data: {time: animator.currentTime, duration: animator.duration} });
            that.updateUI();
        }
    };
    this.animTools.backwardButton.addClass('toolbar-animation-button');
    this.animTools.backwardButton.setIcon('toolbar-animation-backward-icon');
    this.animTools.addControl(this.animTools.backwardButton);

    // forward button
    this.animTools.forwardButton = new Button('toolbar-animationForward');
    this.animTools.forwardButton.setToolTip('Next keyframe');
    this.animTools.forwardButton.onClick = function(e) {
        var animator = viewer.impl.keyFrameAnimator;
        if (animator !== undefined && animator) {
            animator.next();
            viewer.dispatchEvent({ type: ANIMATION_NEXT_FRAME_EVENT, data: {time: animator.currentTime, duration: animator.duration} });
            that.updateUI();
        }
    };
    this.animTools.forwardButton.addClass('toolbar-animation-button');
    this.animTools.forwardButton.setIcon('toolbar-animation-forward-icon');
    this.animTools.addControl(this.animTools.forwardButton);

    // current time lapse
    this.animTools.timeText = new Control('toolbar-animation-time-lapse');
    var lapse = this.animTools.lapse = document.createElement("input");
    lapse.type = "text";
    lapse.value = "0";
    lapse.className = "animation-time-lapse";
    lapse.disabled = true;
    this.animTools.timeText.container.appendChild(lapse);
    this.animTools.timeText.addClass('toolbar-animation-button');
    this.animTools.addControl(this.animTools.timeText);

    // timeline
    this.animTools.timeline = new Control('toolbar-animation-timeline');
    var input = this.animTools.input = document.createElement("input");
    input.type = "range";
    input.value = "0";
    input.className = "animation-timeline";
    if (isIE11) {
	// In IE11, the input type=range has a weird default layout...
        input.style['padding-top'] = '0';
        input.style['padding-bottom'] = '0';
        input.style['margin-top'] = '10px';
    }
    this.animTools.timeline.container.appendChild(input);
    // oninput doesn't work on IE11, use onchange instead
    input.addEventListener(isIE11 ? "change" : "input", function(e) {
        var animator = viewer.impl.keyFrameAnimator;
        if (animator !== undefined && animator) {
            var time = input.value * animator.duration / 100;
            lapse.value = convertSecsToHMS(time);
            lapseLeft.value = convertSecsToHMS(animator.duration);
            animator.goto(time);
            viewer.dispatchEvent({ type: ANIMATION_SEEK_EVENT, data: {time: time, duration: animator.duration} });
            that.updatePlayButton(animator.isPaused);
            that.updateToolbarBackground();
        }
    });
    // tooltip for slider
    var inputTooltip = document.createElement("div");
    inputTooltip.className = "adsk-control-tooltip";
    inputTooltip.textContent = i18n.translate("Click-drag to scrub");
    this.animTools.timeline.container.appendChild(inputTooltip);
    input.addEventListener("mouseover", function(event) {
        if (event.target === input)
            inputTooltip.style.visibility = "visible";
    });
    input.addEventListener("mouseout", function(event) {
        if (event.target === input)
            inputTooltip.style.visibility = "hidden";
    });

    this.animTools.timeline.addClass('toolbar-animation-button');
    this.animTools.timeline.addClass('toolbar-animation-timeline');
    this.animTools.addControl(this.animTools.timeline);

    // remaining time lapse
    this.animTools.timeLeftText = new Control('toolbar-animationRemainingTime');
    var lapseLeft = this.animTools.lapseLeft = document.createElement("input");
    lapseLeft.type = "text";
    lapseLeft.value = "0";
    lapseLeft.className = "animation-time-lapse";
    lapseLeft.disabled = true;
    this.animTools.timeLeftText.container.appendChild(lapseLeft);
    this.animTools.timeLeftText.addClass('toolbar-animation-button');
    this.animTools.addControl(this.animTools.timeLeftText);

    // close button
    this.animTools.closeButton = new Button('toolbar-animation-Close');
    this.animTools.closeButton.addClass('docking-panel-close');
    this.animTools.closeButton.addClass('toolbar-animation-button');
    this.animTools.closeButton.addClass('toolbar-animation-close-button');
    this.animTools.closeButton.onClick = function() {
        that.hideAnimateToolbar();
        viewer.dispatchEvent({ type: ANIMATION_TOOLBAR_CLOSE_EVENT });
    };

    this.animTools.addControl(this.animTools.closeButton);
};

/**
 * Plays the animation.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#activate
 */
AnimationExtension.prototype.activate = function () {
    this.play();
    return true;
};

/**
 * Pauses the animation.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#deactivate
 */
AnimationExtension.prototype.deactivate = function () {
    this.pause();
    return true;
};

/**
 * @returns {boolean} true when the animation is playing.
 * 
 * @alias Autodesk.Viewing.Extensions.AnimationExtension#isActive
 */
AnimationExtension.prototype.isActive = function () {
    return this.isPlaying();
};
