
export var RenderFlags = {
    RENDER_NORMAL      : 0,
    RENDER_HIGHLIGHTED : 1,
    RENDER_HIGHLIGHTED1: 1,
    RENDER_HIGHLIGHTED2: 2,
    RENDER_HIDDEN      : 3,
    RENDER_SHADOWMAP   : 4,
    RENDER_FINISHED    : 5,
};

