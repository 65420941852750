
import { ViewCube } from "../tools/autocam/ViewCube";
import { getGlobal } from "../compat";

const global = getGlobal();

export function ViewCubeUi(viewer) {

    // Keep the constructor LEAN
    // The actual initialization happens in create()
    this.viewer = viewer;
    this.container = null;
    this.cube = null; // Autocam.ViewCube
    this.viewcube = null;
    this.homeViewContainer = null;
    this._created = false;
    this._initTriadVisibility = false;
};

ViewCubeUi.prototype = {
    constructor: ViewCubeUi,

    create: function () {
        if (this._created)
            return;
        this.initContainer();
        this.initHomeButton();
        this._created = true;
    },

    initContainer: function() {
        this.container = document.createElement('div');
        this.container.className = "viewcubeWrapper";
        this.viewer.container.appendChild(this.container);
    },

    initHomeButton: function () {
        if (this.homeViewContainer) {
            return;
        }

        var homeViewContainer = document.createElement('div');
        homeViewContainer.className = "homeViewWrapper";

        this.container.appendChild(homeViewContainer);
        this.homeViewContainer = homeViewContainer;

        var self = this;
        homeViewContainer.addEventListener("click", function(e) {
            self.viewer.navigation.setRequestHomeView(true);
        });
    },

    setVisible: function (show) {
        this.container.style.display = show ? 'block' : 'none';
    },

    showTriad: function (show) {
        if (this.cube)
            this.cube.showTriad(show);
        else 
            this._initTriadVisibility = show;
    },

    displayViewCube: function (display, updatePrefs) {
        if (updatePrefs)
            this.viewer.prefs.set('viewCube', display);

        if (display && !this.cube) {
            this.viewcube = document.createElement("div");
            this.viewcube.className = "viewcube";
            this.container.appendChild(this.viewcube);
            this.cube = new ViewCube("cube", this.viewer.autocam, this.viewcube, global.LOCALIZATION_REL_PATH);

            // Move sibling on top of the viewcube.
            this.container.appendChild(this.homeViewContainer);

            if (this._initTriadVisibility) {
                this.showTriad(true);
            }
            delete this._initTriadVisibility;
        }
        else if (!this.cube) {
            this._positionHomeButton();
            return; //view cube is not existent and we want it off? Just do nothing.
        }

        this.viewcube.style.display = (display ? "block" : "none");

        this._positionHomeButton();

        if( display ) {
            this.viewer.autocam.refresh();
        }
    },

    localize: function() {
        this.cube && this.cube.localize();
    },

    _positionHomeButton: function () {
        if (this.homeViewContainer) {
            var viewCubeVisible = this.cube && this.viewcube && (this.viewcube.style.display === 'block');
            if (viewCubeVisible) {
                this.homeViewContainer.classList.remove('no-viewcube');
            } else {
                this.homeViewContainer.classList.add('no-viewcube');
            }
        }
    },

    uninitialize: function () {
        if (this.container) {
            this.viewer.container.removeChild(this.container);
            this.viewcube = null;
        }

        if (this.cube) {
            this.cube.dtor();
            this.cube = null;
        }

        this.homeViewContainer = null;
        this.hideHomeViewMenu = null;
        this.viewer = null;
    }
};
