
import { theExtensionManager } from "../src/application/ExtensionManager";

/**
 * These are extensions that get built into their own bundles,
 * which are not part of the main viewer3D.js bundle.
 * 
 * DONT REMOVE THIS FUNCTION, webpack.js GENERATES CODE FOR IT !!
 */

function register(id, filePath) {
    theExtensionManager.registerExternalExtension(id, filePath);
}

BUILD_FLAG__REGISTER_EXTERNAL_EXTENSIONS

// See webpack.js for code that replaces BUILD_FLAG_... above.

