var pako = require("pako");

"use strict";


/** @constructor */
// This class will read value from compressed data,
// decopress only necessary data and throw away unused.
export function InputStreamLess(buf, usize) {

    // Offset is the offset to decompressed data.
    // byteLength is the total size of decompressed data.
    this.offset = 0;
    this.byteLength = usize;
    this.range = 0;
    // Assume the buffer is compressed.
    this.compressedBuffer = buf;
    this.compressedByteLength = buf.length;
    this.compressedOffset = 0;
    this.decompressEnd = false;
    // This is to record how many times decompress from scratch. for debug purpose.
    this.resetCount = 0;

    //We will use these shared memory arrays to
    //convert from bytes to the desired data type.
    this.convBuf = new ArrayBuffer(8);
    this.convUint8 = new Uint8Array(this.convBuf);
    this.convUint16 = new Uint16Array(this.convBuf);
    this.convInt32 = new Int32Array(this.convBuf);
    this.convUint32 = new Uint32Array(this.convBuf);
    this.convFloat32 = new Float32Array(this.convBuf);
    this.convFloat64 = new Float64Array(this.convBuf);

    // Compressed chunk size is the size for decompressing each time.
    // Decompressed chunk size is the buffer to hold decompressed data.
    this.COMPRESSED_chunk_SIZE = 512*1024;
    this.DECOMPRESSED_chunk_SIZE = 256*1024;

    // chunks for decompressed data.
    this.chunks = [];
    this.chunksByteLengthMax = 0;
    this.chunksByteLengthMin = 0;

    // Maintain chunk and chunk offset for reading current data.
    this.chunkPointer = null;
    this.chunkOffset = 0;
    // temp chunk is for reading data that stride over multiple chunks.
    this.tempchunk = {
        startIdx: 0,
        endIdx: 0,
        buffer: null
    };

    // Infalte for decompressing incremantally. The lib we used is pako_inflate.min.js
    this.inflate = this.getInflate();

    // Prepare first 1K data for quick access.
    this.prepare(0, 1024);
}

InputStreamLess.prototype.getInflate = function() {
    if (!this.inflate) {
        this.inflate = new pako.Inflate({ level: 3, chunkSize: this.DECOMPRESSED_chunk_SIZE});

        var self = this;
        this.inflate.onData = function(chunk) {

            // Remove unused chunk for current decompressing.
            self.chunksByteLengthMax += chunk.byteLength;
            if (self.chunksByteLengthMax < self.offset) {
                chunk = null;
                self.chunksByteLengthMin = self.chunksByteLengthMax;
            }

            self.chunks.push(chunk);
        };

        this.inflate.onEnd = function() {
            self.decompressEnd = true;
            self.inflate = null;
            // Check decompressed size is expected.
            if (self.chunksByteLengthMax != self.byteLength)
                throw "Decompress error, unexpected size.";
        };
    }

    return this.inflate;
}

InputStreamLess.prototype.prepare = function(off, range, donotclear) {
    // If required data hasn't decompressed yet, let's do it.
    if (this.chunksByteLengthMin > off) {
        // In this case, need to reset stream and decompress from scratch again.
        this.reset();
        this.offset = off;
        this.range = range;
    }

    // Remove unused chunks if no longer used for subsequent reading.
    if (!donotclear) {
        var idx = Math.floor(off / this.DECOMPRESSED_chunk_SIZE);
        var startIdx = Math.floor(this.chunksByteLengthMin / this.DECOMPRESSED_chunk_SIZE);
        var endIdx = this.chunks.length < idx ? this.chunks.length : idx;
        for (var i = startIdx; i<endIdx; i++) {
            this.chunks[i] = null;
        }
        this.chunksByteLengthMin = endIdx * this.DECOMPRESSED_chunk_SIZE;
    }

    // Prepare further decompressed data.
    var range = range || 1;
    var expectEnd = off + range;
    expectEnd = expectEnd > this.byteLength ? this.byteLength : expectEnd;
    var reachEnd = false;
    while (expectEnd > this.chunksByteLengthMax)
    {
        var len = this.COMPRESSED_chunk_SIZE;
        if (this.compressedOffset + len >= this.compressedByteLength) {
            len = this.compressedByteLength - this.compressedOffset;
            reachEnd = true;
        }

        // Push another compressed data chunk to decompress.
        var data = new Uint8Array(this.compressedBuffer.buffer, this.compressedOffset, len);
        this.getInflate().push(data, reachEnd);

        // Move offset forward as decompress processing.
        this.compressedOffset += len;

        if (reachEnd) {
            break;
        }
    }

}

InputStreamLess.prototype.ensurechunkData = function(len) {
    // ensure the data is ready for immediate reading.
    len = len || 1;
    var chunkLen = this.chunks.length;

    var chunkIdx = Math.floor(this.offset / this.DECOMPRESSED_chunk_SIZE);
    var endIdx = Math.floor((this.offset + len - 1) / this.DECOMPRESSED_chunk_SIZE);
    if (endIdx >= chunkLen) {
        var length = (endIdx - chunkLen + 1) * this.DECOMPRESSED_chunk_SIZE;
        // When do another prepare in the middle of ensuring data,
        // do not clear any chunk yet, as it may be still in use.
        this.prepare(this.DECOMPRESSED_chunk_SIZE*chunkLen, length, true);
    }

    if (chunkIdx < endIdx) {
        if (this.tempchunk.startIdx>chunkIdx || this.tempchunk.endIdx<endIdx) {
            var size = (endIdx-chunkIdx+1) * this.DECOMPRESSED_chunk_SIZE;
            this.tempchunk.buffer = new Uint8Array(size);
            var pos = 0;
            for (var i=chunkIdx; i<=endIdx; i++) {
                this.tempchunk.buffer.set(this.chunks[i], pos);
                pos += this.DECOMPRESSED_chunk_SIZE;
            }
            this.tempchunk.startIdx = chunkIdx;
            this.tempchunk.endIdx = endIdx;
        }
        this.chunkPointer = this.tempchunk.buffer;
    }
    else {
        this.chunkPointer = this.chunks[chunkIdx];
    }

    this.chunkOffset = this.offset - chunkIdx * this.DECOMPRESSED_chunk_SIZE;
    this.offset += len;
}

InputStreamLess.prototype.seek = function(off, range, donotclear) {
    this.offset = off;
    this.range = range;
    this.prepare(off, range, donotclear);
};

InputStreamLess.prototype.getBytes = function(len) {
    this.ensurechunkData(len);
    var ret = new Uint8Array(this.chunkPointer.buffer, this.chunkOffset, len);

    return ret;
};

InputStreamLess.prototype.getVarints = function () {
    var b;
    var value = 0;
    var shiftBy = 0;
    do {
        this.ensurechunkData();
        b = this.chunkPointer[this.chunkOffset];
        value |= (b & 0x7f) << shiftBy;
        shiftBy += 7;
    } while (b & 0x80);
    return value;
}

InputStreamLess.prototype.getUint8 = function() {
    this.ensurechunkData();
    return this.chunkPointer[this.chunkOffset];
};

InputStreamLess.prototype.getUint16 = function() {

    this.ensurechunkData();
    this.convUint8[0] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    this.convUint8[1] = this.chunkPointer[this.chunkOffset];
    return this.convUint16[0];
};

InputStreamLess.prototype.getInt16 = function() {
    var tmp = this.getUint16();
    //make negative integer if the ushort is negative
    if (tmp > 0x7fff)
        tmp = tmp | 0xffff0000;
    return tmp;
};

InputStreamLess.prototype.getInt32 = function() {

    var dst = this.convUint8;

    this.ensurechunkData();
    dst[0] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[1] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[2] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[3] = this.chunkPointer[this.chunkOffset];

    return this.convInt32[0];
};

InputStreamLess.prototype.getUint32 = function() {

    var dst = this.convUint8;

    this.ensurechunkData();
    dst[0] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[1] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[2] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[3] = this.chunkPointer[this.chunkOffset];

    return this.convUint32[0];
};

InputStreamLess.prototype.getFloat32 = function() {

    var dst = this.convUint8;

    this.ensurechunkData();
    dst[0] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[1] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[2] = this.chunkPointer[this.chunkOffset];
    this.ensurechunkData();
    dst[3] = this.chunkPointer[this.chunkOffset];

    return this.convFloat32[0];
};

InputStreamLess.prototype.getFloat64 = function() {

    var dst = this.convUint8;
    for (var i=0; i<8; i++) {
        this.ensurechunkData();
        dst[i] = this.chunkPointer[this.chunkOffset];
    }

    return this.convFloat64[0];
};

InputStreamLess.prototype.getString = function(len) {
    var dst = "";
    this.ensurechunkData(len);
    var src = this.chunkPointer;

    for (var i = this.chunkOffset, iEnd = this.chunkOffset + len; i < iEnd; i++) {
        dst += String.fromCharCode(src[i]);
    }

    var res;
    try {
        res = decodeURIComponent(escape(dst));
    } catch (e) {
        res = dst;
        debug("Failed to decode string " + res);
    }

    return res;
};

InputStreamLess.prototype.reset = function (buf) {
    this.resetCount++;
    debug("InputStream Less Reset: " + this.resetCount);

    if (buf) {
        this.compressedBuffer = buf;
        this.compressedByteLength = buf.length;
    }

    this.offset = 0;
    this.chunks = [];
    this.chunksByteLengthMax = 0;
    this.chunksByteLengthMin = 0;
    this.compressedOffset = 0;
    this.decompressEnd = false;
    this.chunkPointer = null;
    this.chunkOffset = 0;
    this.inflate = null;

    this.tempchunk.startIdx = 0;
    this.tempchunk.endIdx = 0;
    this.tempchunk.buffer = null;
};
