import * as THREE from "three";
import { i18n } from "../../globalization/i18next";

/**
 * Clamps a vector to an axis aligned unit vector if it's sufficiently close. This is to help deal with THREE doing:
 * var t = new THREE.Vector3(0, 0, -0.6873695734180347);
 * t.normalize();
 * t.z; // -0.9999999999999999
 *
 * and us doing direct comparisons of floating point numbers.
 *
 * @param {THREE.Vector3} vec - The vector to check and clamp. This vector may be modified
 */
function clampToUnitAxisIfNeeded(vec) {
    const tolerance = 1e-12;
    if (Math.abs(vec.x) < tolerance && Math.abs(vec.y) < tolerance) {
        vec.set(0, 0, vec.z > 0 ? 1 : -1);
    } else if (Math.abs(vec.y) < tolerance && Math.abs(vec.z) < tolerance) {
        vec.set(vec.x > 0 ? 1 : -1, 0, 0);
    } else if (Math.abs(vec.z) < tolerance && Math.abs(vec.x) < tolerance) {
        vec.set(0, vec.y > 0 ? 1 : -1, 0);
    }
}

/**
 * Autocam is the container for the view cube and steering wheel classes.
 * It contains math for camera transformations and most of the functions are retrieved from SampleCAM.
 * Refer to their documentation for explanation.
 */
export function Autocam(camera, navApi, canvas) {

    var cam = this;
    var dropDownMenu = null;
    var cubeContainer = null;
    var _changing = false;

    this.cube = null;
    this.camera = camera;
    this.renderer = 'WEBGL';
    this.startState = {};
    this.navApi = navApi;   // TODO: use this for camera sync.
    this.orthographicFaces = false;
    this.canvas = canvas;

    this.cameraChangedCallback = function(){};
    this.pivotDisplayCallback = function(){};
    this.transitionCompletedCallback = function(){};

    //delta Time
    var startTime = Date.now();
    var deltaTime;
    var setHomeDeferred = false;

    function changed(worldUpChanged)
    {
        _changing = true;
        camera.target.copy(cam.center);
        camera.pivot.copy(cam.pivot);

        if( camera.worldup )
            camera.worldup.copy(cam.sceneUpDirection);
        else
            camera.up.copy(cam.sceneUpDirection);

        cam.cameraChangedCallback(worldUpChanged);
        _changing = false;
    }
    
    this.dtor = function() {
        this.cube = null;
        this.cameraChangedCallback = null;
        this.pivotDisplayCallback = null;
        this.transitionCompletedCallback = null;
        this.canvas = null;
    };

    this.registerCallbackCameraChanged = function(callback) {
        this.cameraChangedCallback = callback;
    };
    this.registerCallbackPivotDisplay = function(callback) {
        this.pivotDisplayCallback = callback;
    };
    this.registerCallbackTransitionCompleted = function(callback) {
        this.transitionCompletedCallback = callback;
    };

    this.showPivot = function(state)
    {
        this.pivotDisplayCallback(state);
    };

        /*
    this.setViewCubeContainer = function( div )
    {
        cubeContainer = div;
    };
    */

    this.setWorldUpVector = function( newUp )
    {
        if( _changing )
            return;

        if( newUp && (newUp.lengthSq() > 0) && !newUp.normalize().equals(this.sceneUpDirection) )
        {
            // Changing up resets the front face:
            this.sceneUpDirection.copy( newUp );
            this.sceneFrontDirection.copy( this.getWorldFrontVector() );
            this.cubeFront.copy(this.sceneFrontDirection).cross(this.sceneUpDirection).normalize();
            if( this.cube )
                requestAnimationFrame(this.cube.render);
        }
    };

    this.getWorldUpVector = function()
    {
        return this.sceneUpDirection.clone();
    };

    // Assumes sceneUpDirection is set.
    this.getWorldRightVector = function()
    {
        var vec = this.sceneUpDirection.clone();

        if (Math.abs(vec.z) <= Math.abs(vec.y))
        {
            // Cross(Vertical, ZAxis)
            vec.set(vec.y, -vec.x, 0);
        }
        else if (vec.z >= 0)
        {
            // Cross(YAxis, Vertical)
            vec.set(vec.z, 0, -vec.x);
        }
        else
        {
            // Cross(Vertical, YAxis)
            vec.set(-vec.z, 0, vec.x);
        }
        return vec.normalize();
    };

    // Assumes sceneUpDirection is set.
    this.getWorldFrontVector = function()
    {
        var up = this.getWorldUpVector();
        return up.cross(this.getWorldRightVector()).normalize();
    };

    this.goToView = function( viewVector ) {
        if( this.navApi.isActionEnabled('gotoview') ) {
            var destination = {
                position: viewVector.position.clone(),
                      up: viewVector.up.clone(),
                  center: viewVector.center.clone(),
                   pivot: viewVector.pivot.clone(),
                     fov: viewVector.fov,
                 worldUp: viewVector.worldUp.clone(),
                 isOrtho: viewVector.isOrtho
            };
            cam.elapsedTime = 0;
            this.animateTransition(destination);
        }
    };

    this.getCurrentView = function () {
        return {
            position: camera.position.clone(),
            up: camera.up.clone(),
            center: this.center.clone(),
            pivot: this.pivot.clone(),
            fov: camera.fov,
            worldUp: this.sceneUpDirection.clone(),
            isOrtho: (camera.isPerspective === false)
        };
    };

    this.setCurrentViewAsHome = function( focusFirst ) {
        if( focusFirst ) {
            this.navApi.setRequestFitToView(true);
            setHomeDeferred = true;
        }
        else {
            this.homeVector = this.getCurrentView();
        }
    };

    // This method sets both the "current" home and the "original" home.
    // The latter is used for the "reset home" function.
    this.setHomeViewFrom = function(camera) {
        var pivot   = camera.pivot   ? camera.pivot   : this.center;
        var center  = camera.target  ? camera.target  : this.pivot;
        var worldup = camera.worldup ? camera.worldup : this.sceneUpDirection;

        this.homeVector = {
            position: camera.position.clone(),
                  up: camera.up.clone(),
              center: center.clone(),
               pivot: pivot.clone(),
                 fov: camera.fov,
             worldUp: worldup.clone(),
             isOrtho: (camera.isPerspective === false)
        };

        this.originalHomeVector = {
            position: camera.position.clone(),
                  up: camera.up.clone(),
              center: center.clone(),
               pivot: pivot.clone(),
                 fov: camera.fov,
             worldUp: worldup.clone(),
          worldFront: this.sceneFrontDirection.clone(),  // Extra for reset orientation
             isOrtho: (camera.isPerspective === false)
        };
    };

    this.toPerspective = function() {
        if( !camera.isPerspective ) {
            camera.toPerspective();
            changed(false);
        }
    };

    this.toOrthographic = function() {
        if( camera.isPerspective ) {
            camera.toOrthographic();
            changed(false);
        }
    };

    this.setOrthographicFaces = function(state) {
         this.orthographicFaces = state;
    };

    this.goHome = function() {
        if( this.navApi.isActionEnabled('gotoview') ) {
            this.navApi.setPivotSetFlag(false);
            this.goToView( this.homeVector );
        }
    };

    this.resetHome = function() {
        this.homeVector.position.copy(this.originalHomeVector.position);
        this.homeVector.up.copy(this.originalHomeVector.up);
        this.homeVector.center.copy(this.originalHomeVector.center);
        this.homeVector.pivot.copy(this.originalHomeVector.pivot);
        this.homeVector.fov = this.originalHomeVector.fov;
        this.homeVector.worldUp.copy(this.originalHomeVector.worldUp);
        this.homeVector.isOrtho = this.originalHomeVector.isOrtho;
        this.goHome();
    };

    this.getView = function() {
        return this.center.clone().sub(camera.position);
    };

    this.setCameraUp = function(up) {
        var view = this.dir.clone();
        var right = view.cross(up).normalize();
        if( right.lengthSq() === 0 )
        {
            // Try again after perturbing eye direction:
            view.copy(this.dir);
            if( up.z > up.y )
                view.y += 0.0001;
            else
                view.z += 0.0001;

            right = view.cross(up).normalize();
        }
        // Orthogonal camera up direction:
        camera.up.copy(right).cross(this.dir).normalize();
    };

    /***
    this.render = function(){
        //renderer.render( scene, camera );
        //We need to remove all calls to this render
        avp.logger.log("Unrequired call to render within Autocam.js:17")
    };
    ***/

    (function animate() {
        requestAnimationFrame(animate);
        // Is there an assumption here about the order of animation frame callbacks?
        var now = Date.now();
        deltaTime = now - startTime;
        startTime = now;
    }());

    //Control variables
    this.ortho = false;
    this.center = camera.target ? camera.target.clone() : new THREE.Vector3(0,0,0);
    this.pivot = camera.pivot ? camera.pivot.clone() : this.center.clone();

    this.sceneUpDirection    = camera.worldup ? camera.worldup.clone() : camera.up.clone();
    this.sceneFrontDirection = this.getWorldFrontVector();

    //
    //dir, up, left vector
    this.dir = this.getView();

    // Compute "real" camera up:
    this.setCameraUp(camera.up);

    this.saveCenter = this.center.clone();
    this.savePivot  = this.pivot.clone();
    this.saveEye    = camera.position.clone();
    this.saveUp     = camera.up.clone();
    var prevEye, prevCenter, prevUp, prevPivot;

    this.cubeFront = this.sceneFrontDirection.clone().cross(this.sceneUpDirection).normalize();

    this.setHomeViewFrom(camera);

    var rotInitial = new THREE.Quaternion();
    var rotFinal   = new THREE.Quaternion();
    var rotTwist   = new THREE.Quaternion();
    var rotSpin    = new THREE.Quaternion();
    var distInitial;
    var distFinal;

    /**
     * Holds the default pan speed multiplier of 0.5
     * @type {number}
     */
    this.userPanSpeed = 0.5;

    /**
     * Holds the default look speed multiplier of 2.0
     * @type {number}
     */
    this.userLookSpeed = 2.0;

    /**
     * Holds the default height speed multiplier of 5.0 (used in updown function)
     * @type {number}
     */
    this.userHeightSpeed = 5.0;

    /**
     * Holds the current walk speed multiplier, which can be altered in the steering wheel drop down menu (between 0.24 and 8)
     * @type {number}
     */
    this.walkMultiplier = 1.0;

    /**
     * Holds the default zoom speed multiplier of 1.015
     * @type {number}
     */
    this.userZoomSpeed = 1.015;

    /**
     * Holds the orbit multiplier of 5.0
     * @type {number}
     */
    this.orbitMultiplier = 5.0;
    this.currentlyAnimating = false;

    //look
    camera.keepSceneUpright = true;

    //orbit
    this.preserveOrbitUpDirection = true;
    this.alignOrbitUpDirection = true;
    this.constrainOrbitHorizontal = false;
    this.constrainOrbitVertical = false;
    this.doCustomOrbit = false;
    this.snapOrbitDeadZone = 0.045;
    this.snapOrbitThresholdH = this.snapOrbitThresholdV = THREE.Math.degToRad(15.0);
    this.snapOrbitAccelerationAX = this.snapOrbitAccelerationAY = 1.5;
    this.snapOrbitAccelerationBX = this.snapOrbitAccelerationBY = 2.0;
    this.snapOrbitAccelerationPointX = this.snapOrbitAccelerationPointY = 0.5;
    this.alignDirTable = new Array(26);
    this.alignDirTable[0] = new THREE.Vector3(-1,0,0);
    this.alignDirTable[1] = new THREE.Vector3(1,0,0);
    this.alignDirTable[2] = new THREE.Vector3(0,-1,0);
    this.alignDirTable[3] = new THREE.Vector3(0,1,0);
    this.alignDirTable[4] = new THREE.Vector3(0,0,-1);
    this.alignDirTable[5] = new THREE.Vector3(0,0,1);

    // fill edges
    this.alignDirTable[6] = new THREE.Vector3(-1,-1,0);
    this.alignDirTable[7] = new THREE.Vector3(-1,1,0);
    this.alignDirTable[8] = new THREE.Vector3(1,-1,0);
    this.alignDirTable[9] = new THREE.Vector3(1,1,0);
    this.alignDirTable[10] = new THREE.Vector3(0,-1,-1);
    this.alignDirTable[11] = new THREE.Vector3(0,-1,1);
    this.alignDirTable[12] = new THREE.Vector3(0,1,-1);
    this.alignDirTable[13] = new THREE.Vector3(0,1,1);
    this.alignDirTable[14] = new THREE.Vector3(-1,0,-1);
    this.alignDirTable[15] = new THREE.Vector3(1,0,-1);
    this.alignDirTable[16] = new THREE.Vector3(-1,0,1);
    this.alignDirTable[17] = new THREE.Vector3(1,0,1);

    // fill corners
    this.alignDirTable[18] = new THREE.Vector3(-1,-1,-1);
    this.alignDirTable[19] = new THREE.Vector3(-1,-1,1);
    this.alignDirTable[20] = new THREE.Vector3(-1,1,-1);
    this.alignDirTable[21] = new THREE.Vector3(-1,1,1);
    this.alignDirTable[22] = new THREE.Vector3(1,-1,-1);
    this.alignDirTable[23] = new THREE.Vector3(1,-1,1);
    this.alignDirTable[24] = new THREE.Vector3(1,1,-1);
    this.alignDirTable[25] = new THREE.Vector3(1,1,1);

    this.combined = false;

    //variables used for snapping
    this.useSnap = false;
    this.lockDeltaX = 0.0;
    this.lockedX = false;
    this.lastSnapRotateX = 0.0;
    this.lockDeltaY = 0.0;
    this.lockedY = false;
    this.lastSnapRotateY = 0.0;
    this.lastSnapDir = new THREE.Vector3(0,0,0);

    //up-down
    this.topLimit = false;
    this.bottomLimit = false;
    this.minSceneBound = 0;
    this.maxSceneBound = 0;

    //shot
    var shotParams = { destinationPercent:1.0, duration:1.0, zoomToFitScene:true, useOffAxis:false };
    this.shotParams = shotParams;   // Expose these for modification
    var camParamsInitial, camParamsFinal;

    //zoom
    this.zoomDelta = new THREE.Vector2();
    var unitAmount = 0.0;

    //walk
    var m_resetBiasX, m_resetBiasY, m_bias;

    //info about model object we need to save for fit to window
    var boundingBoxMin = new THREE.Vector3();
    var boundingBoxMax = new THREE.Vector3();

    /**
     * Parameters to control the saving and displaying of the rewind timeline
     * @example <caption> Changing the maximum number of stored rewind cameras from 25(default) to 50 </caption>
     * cam.rewindParams.maxHistorySize = 50;
     */
    this.rewindParams = {
        history:[],
        startTime:undefined,
        thumbnailSize:56.0,
        thumbnailGapSize:12.0,
        maxHistorySize:25,
        snappingEnabled:true,
        timelineIndex:0,
        timelineIndexSlide:0,
        open:false,
        openLocation:new THREE.Vector2(0,0),
        openBracket:new THREE.Vector2(0,0),
        openBracketA:new THREE.Vector2(0,0),
        openBracketB:new THREE.Vector2(0,0),
        openLocationOrigin:new THREE.Vector2(0,0),
        locationOffset:new THREE.Vector2(0,0),
        snapOffset:new THREE.Vector2(0,0),
        slideOffset:new THREE.Vector2(0,0),
        snapped:true,
        resetWeights:false,
        recordEnabled:false,
        elementIsRecording:false
    };

    this.viewCubeMenuOpen = false;
    this.menuSize = new THREE.Vector2(0,0);
    this.menuOrigin = new THREE.Vector2(0,0);

    camera.lookAt(this.center);

    // function windowResize(){
        // refresh camera on size change

        // We handle this elsewhere
        /*
            renderer.setSize( window.innerWidth, window.innerHeight );
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.topFov = camera.bottomFov = camera.fov/2;
            camera.leftFov = camera.rightFov = (camera.aspect * camera.fov)/2;
            camera.updateProjectionMatrix();
        */
    // }

    /***
    windowResize();
    window.addEventListener('resize', windowResize, false);
    ***/

    this.setCube = function(viewcube)
    {
        this.cube = viewcube;    // DOH!!!
    };

    /**
     * Function which loads the JSON object to the scene
     * @param {JSONObject} model - The correctly formatted JSON model
     * @param {Vector3} scale - The scale multiplier for the input model
     * @param {Vector3} position - Where to load the model
     * @example <caption>Load an object called car.json to (0,0,0) with a scale of 50 </caption>
     * cam.loadObject('Objects/car.json', new THREE.Vector3(50,50,50), new THREE.Vector3(0,0,0));
     */
    this.loadObject = function(model, scale, position){
        var loader = new THREE.JSONLoader();
        loader.load( model, function( geometry, materials ) {
            var faceMaterial = new THREE.MeshPhongMaterial( materials );
            var mesh = new THREE.Mesh( geometry, faceMaterial );
            mesh.scale = scale;
            mesh.position.copy(position);
            mesh.geometry.computeBoundingBox();
            var bBox = mesh.geometry.boundingBox.clone();
            boundingBoxMax.set(bBox.max.x,bBox.max.y,bBox.max.z);
            boundingBoxMin.set(bBox.min.x,bBox.min.y,bBox.min.z);
            boundingBoxMax.multiply(scale);
            boundingBoxMin.multiply(scale);
            scene.add( mesh );
            objects.push( mesh );
        });
    };


    // Sync our local data from the given external camera:
    this.sync = function(clientCamera) {
        if( clientCamera.isPerspective !== camera.isPerspective ) {
            if( clientCamera.isPerspective ) {
                camera.toPerspective();
            }
            else {
                camera.toOrthographic();
                if( clientCamera.saveFov )
                    camera.saveFov = clientCamera.saveFov;
            }
        }
        camera.fov = clientCamera.fov;
        camera.position.copy(clientCamera.position);

        if( clientCamera.target ) {
            this.center.copy(clientCamera.target);
            camera.target.copy(clientCamera.target);
        }
        if( clientCamera.pivot ) {
            this.pivot.copy(clientCamera.pivot);
            camera.pivot.copy(clientCamera.pivot);
        }
        this.dir.copy(this.center).sub(camera.position);

        this.setCameraUp(clientCamera.up);

        var worldUp = clientCamera.worldup ? clientCamera.worldup : clientCamera.up;
        if( worldUp.distanceToSquared(this.sceneUpDirection) > 0.0001 ) {
            this.setWorldUpVector(worldUp);
        }

        if( setHomeDeferred && !this.navApi.getTransitionActive() ) {
            setHomeDeferred = false;
            this.setCurrentViewAsHome(false);
        }
        if( this.cube )
            requestAnimationFrame(this.cube.render);
    };


    this.refresh = function() {
        if( this.cube )
            this.cube.refreshCube();
    };

    /*        Prototyped Functions          */

    //extending Box2 to be used like AutoCam::Box2
    THREE.Box2.prototype.setCenter = function (center){
        var halfSize = new THREE.Vector2((Math.abs(this.max.x - this.min.x)/2.0),(Math.abs(this.max.y - this.min.y))/2.0);
        this.min.copy( center ).sub( halfSize );
        this.max.copy( center ).add( halfSize );
        return this;
    };

    //Using Box2 like an AutoCam::Icon2D
    THREE.Box2.prototype.getIcon2DCoords = function(Pscreen,PIcon2D){
        var zero = this.center;
        PIcon2D.set((Pscreen.x - zero.x)/(this.size().x/2.0) , (Pscreen.y - zero.y)/(this.size().y / 2.0));
    };

    //so we dont need a matrix4 as an intermediate
    THREE.Matrix3.prototype.makeRotationFromQuaternion = function ( q ) {
        var te = this.elements;

        var x = q.x, y = q.y, z = q.z, w = q.w;
        var x2 = x + x, y2 = y + y, z2 = z + z;
        var xx = x * x2, xy = x * y2, xz = x * z2;
        var yy = y * y2, yz = y * z2, zz = z * z2;
        var wx = w * x2, wy = w * y2, wz = w * z2;

        te[0] = 1 - ( yy + zz );
        te[3] = xy - wz;
        te[6] = xz + wy;

        te[1] = xy + wz;
        te[4] = 1 - ( xx + zz );
        te[7] = yz - wx;

        te[2] = xz - wy;
        te[5] = yz + wx;
        te[8] = 1 - ( xx + yy );

        return this;
    };

    // changed to accept a matrix3
    THREE.Quaternion.prototype.setFromRotationMatrix3 = function ( m ) {
        // http://www.euclideanspace.com/maths/geometry/rotations/conversions/matrixToQuaternion/index.htm

        var te = m.elements,
            m11 = te[0], m12 = te[3], m13 = te[6],
            m21 = te[1], m22 = te[4], m23 = te[7],
            m31 = te[2], m32 = te[5], m33 = te[8],

            trace = m11 + m22 + m33,
            s;

        if ( trace > 0 ) {
            s = 0.5 / Math.sqrt( trace + 1.0 );
            this.w = 0.25 / s;
            this.x = ( m32 - m23 ) * s;
            this.y = ( m13 - m31 ) * s;
            this.z = ( m21 - m12 ) * s;
        } else if ( m11 > m22 && m11 > m33 ) {
            s = 2.0 * Math.sqrt( 1.0 + m11 - m22 - m33 );
            this.w = (m32 - m23 ) / s;
            this.x = 0.25 * s;
            this.y = (m12 + m21 ) / s;
            this.z = (m13 + m31 ) / s;
        } else if ( m22 > m33 ) {
            s = 2.0 * Math.sqrt( 1.0 + m22 - m11 - m33 );
            this.w = (m13 - m31 ) / s;
            this.x = (m12 + m21 ) / s;
            this.y = 0.25 * s;
            this.z = (m23 + m32 ) / s;
        } else {
            s = 2.0 * Math.sqrt( 1.0 + m33 - m11 - m22 );
            this.w = ( m21 - m12 ) / s;
            this.x = ( m13 + m31 ) / s;
            this.y = ( m23 + m32 ) / s;
            this.z = 0.25 * s;
        }
        return this;
    };

    // NOTE: This modifies the incoming vector!!
    // TODO: Change all calls to use Vector3.applyQuaternion instead.
    THREE.Quaternion.prototype.rotate = function (vector){
        //From AutoCamMath.h file
        var kRot = new THREE.Matrix4().makeRotationFromQuaternion(this);
        var e = kRot.elements;

        //converting 4d matrix to 3d
        var viewRot = new THREE.Matrix3().set( e[0],e[1],e[2], e[4],e[5],e[6], e[8],e[9],e[10] );

        return vector.applyMatrix3(viewRot);
    };

    THREE.Vector3.prototype.findAngleWith = function(b, axis){
        var angle = 0.0;
        var cosAngle = this.clone().normalize().clone().dot(b.clone().normalize());

        var axisCheck = (this.clone().cross(b)).clone().normalize();
        if (axisCheck.clone().length() < Number.MIN_VALUE){
            if(cosAngle > 0.0){
                angle = 0.0;
            }else{
                angle = 180.0;
            }
        }else{

            var cosCheck = axisCheck.clone().dot(axis.clone().normalize());

            //check to make sure user specified axis is orthogonal to vectors.
            //If it isn't we take the closer of the two choices.
            axis = cosCheck > 0.0 ? axisCheck : -axisCheck;

            var cosAngleNextQuadrant = new THREE.Quaternion().setFromAxisAngle( axis, 90.0 * THREE.Math.degToRad );
            cosAngleNextQuadrant = ((cosAngleNextQuadrant.clone().rotate(b)).clone().normalize()).clone().dot(this);
            angle = Math.acos(cosAngle) * THREE.Math.radToDeg;

            if(Math.abs(angle - 90.0) < Number.MIN_VALUE)
                angle = 90.0;

            if ((angle < 90.0 && cosAngle * cosAngleNextQuadrant > 0.0)||
                (angle > 90.0 && cosAngle * cosAngleNextQuadrant < 0.0)||
                (angle == 90.0 && cosAngleNextQuadrant > 0.0))
                angle = -1.0 * angle;	//figure out whether we need to turn left or right
        }

        angle = THREE.Math.degToRad(angle);
        return angle;
    };

    if(!('contains' in String.prototype))
        String.prototype.contains = function(str, startIndex) { return -1 !== String.prototype.indexOf.call(this, str, startIndex); };

    Math.linearClamp = function( x, a, b ){
        if ( x <= a ) { return 0.0; }
        if ( x >= b ) { return 1.0; }

        return ( x - a ) / ( b - a );
    };

    Math.easeClamp = function( x, a, b ){
        if ( x <= a ) { return 0.0; }
        if ( x >= b ) { return 1.0; }

        var t = ( x - a ) / ( b - a );
        return 0.5 * ( Math.sin( (t - 0.5) * Math.PI ) + 1.0 );
    };

    Math.linearInterp = function( t, a, b ){
        return a * (1.0 - t) + b * t;
    };

    Math.equalityClamp = function (x,a,b){
        if ( x <= a ) { return a; }
        if ( x >= b ) { return b; }

        return x;
    };

    Math.round2 = function (x){
        return (Math.round(x*100))/100;
    };

    Math.round1 = function (x){
        return (Math.round(x*10))/10;
    };


    /*      SHOT OPERATION      */

    //transitions smoothly to destination
    this.animateTransition = function ( destination ) {

        if ( !destination ) { return; }

        var worldUpChanged = false;
        var unitTime = 0.0;

        this.setCameraOrtho(destination.isOrtho);

        if ( cam.elapsedTime >= shotParams.duration ) {
            unitTime = 1.0;

            cam.center.copy(destination.center);
            cam.pivot.copy(destination.pivot);
            camera.position.copy(destination.position);
            camera.up.copy(destination.up);
            camera.target.copy(destination.center);
            if( !destination.isOrtho )
                camera.fov = destination.fov;
            camera.dirty = true;

            worldUpChanged = !destination.worldUp.equals(this.sceneUpDirection);
            if( worldUpChanged )
                this.setWorldUpVector(destination.worldUp);

            this.currentlyAnimating = false;
            changed(worldUpChanged);
            this.showPivot(false);
            if( this.cube )
                requestAnimationFrame(this.cube.render);

            this.addHistoryElement();
            this.navApi.setTransitionActive(false);
            this.transitionCompletedCallback();
            return;
        }
        this.currentlyAnimating = true;
        this.showPivot(true);
        this.navApi.setTransitionActive(true);

        var tMax = shotParams.destinationPercent;
        unitTime =  Math.easeClamp( cam.elapsedTime / shotParams.duration, 0.0, tMax );
        var oneMinusTime = 1.0 - unitTime;
        cam.elapsedTime += deltaTime/500;

        var center = (cam.center.clone().multiplyScalar(oneMinusTime)).add( destination.center.clone().multiplyScalar( unitTime ));
        var position = (camera.position.clone().multiplyScalar(oneMinusTime)).add(destination.position.clone().multiplyScalar( unitTime ));
        var up = (camera.up.clone().multiplyScalar(oneMinusTime)).add(destination.up.clone().multiplyScalar( unitTime ));
        var pivot = (camera.pivot.clone().multiplyScalar(oneMinusTime)).add(destination.pivot.clone().multiplyScalar( unitTime ));
        var worldUp = (this.sceneUpDirection.clone().multiplyScalar(oneMinusTime)).add(destination.worldUp.clone().multiplyScalar( unitTime ));
        var fov = camera.fov * oneMinusTime + destination.fov * unitTime;

        cam.center.copy(center);
        cam.pivot.copy(pivot);
        camera.position.copy(position);
        camera.up.copy(up);
        camera.target.copy(center);
        if( !destination.isOrtho )
            camera.fov = fov;
        camera.dirty = true;

        worldUpChanged = (worldUp.distanceToSquared(this.sceneUpDirection) > 0.0001);
        if( worldUpChanged )
            this.setWorldUpVector(worldUp);

        camera.lookAt(cam.center);
        changed(worldUpChanged);

        if( this.cube )
            requestAnimationFrame(this.cube.render);

        requestAnimationFrame(function() { cam.animateTransition(destination); });
    };

    //used for view cube transforms, to see difference between this and linear interpolation watch
    //http://www.youtube.com/watch?v=uNHIPVOnt-Y
    this.sphericallyInterpolateTransition = function( completionCallback )
    {
        var center, position, up;
        var unitTime = 0.0;
        this.currentlyAnimating = true;
        this.navApi.setTransitionActive(true);

        if ( cam.elapsedTime >= shotParams.duration ){
            unitTime = 1.0;
            this.currentlyAnimating = false;
        }
        else {
            var tMax = shotParams.destinationPercent;
            unitTime =  Math.easeClamp( cam.elapsedTime / shotParams.duration, 0.0, tMax );
            cam.elapsedTime += deltaTime/500;
        }

        // This seems to avoid some error in the rotation:
        if( unitTime === 1.0 ) {
            position = camParamsFinal.position;
            center   = camParamsFinal.center;
            up       = camParamsFinal.up;
        }
        else {
            var M = new THREE.Matrix3();
            var rot = rotInitial.clone();
            rot.slerp(rotFinal,(unitTime));
            M.makeRotationFromQuaternion(rot);
            var dist = Math.linearInterp(unitTime, distInitial, distFinal);

            var e = M.elements;

            center = camParamsInitial.center.clone().multiplyScalar(1.0 - unitTime).add(camParamsFinal.center.clone().multiplyScalar(unitTime));
            position = center.clone().sub(new THREE.Vector3(e[0],e[1],e[2]).multiplyScalar(dist));
            up = new THREE.Vector3(e[3],e[4],e[5]);
        }
        cam.center.copy(center);
        camera.position.copy(position);
        camera.up.copy(up);

        // The above code will have to change if we want the proper rotation
        // to occur about the pivot point instead of the center.
        if( !cam.navApi.getUsePivotAlways() )
            cam.pivot.copy(center);

        camera.lookAt(cam.center);

        if( this.currentlyAnimating === true ) {
            this.showPivot(true);
            requestAnimationFrame(function() { cam.sphericallyInterpolateTransition(completionCallback); });
        }
        else {
            this.navApi.setTransitionActive(false);
            this.showPivot(false);
            this.addHistoryElement();

            if( this.orthographicFaces && this.isFaceView() )
                this.setCameraOrtho(true);

            if( completionCallback )
                completionCallback();
        }
        changed(false);
        if( this.cube )
            requestAnimationFrame(this.cube.render);
    };

    //This is used to determine the relation between camera up vector and scene direction, used to determine which
    //face to translate to when clicking on a viewcube arrow
    this.getOrientation = function(){
        if( !this.cube )
            return;

        var camX = Math.round1(camera.up.x);
        var camY = Math.round1(camera.up.y);
        var camZ = Math.round1(camera.up.z);
        var sceneFront = this.sceneFrontDirection.clone();
        var sceneUp = this.sceneUpDirection.clone();
        var sceneRight = this.sceneFrontDirection.clone().cross(this.sceneUpDirection).normalize();
        sceneFront.x = Math.round1(sceneFront.x);
        sceneFront.y = Math.round1(sceneFront.y);
        sceneFront.z = Math.round1(sceneFront.z);
        sceneUp.x = Math.round1(sceneUp.x);
        sceneUp.y = Math.round1(sceneUp.y);
        sceneUp.z = Math.round1(sceneUp.z);
        sceneRight.x = Math.round1(sceneRight.x);
        sceneRight.y = Math.round1(sceneRight.y);
        sceneRight.z = Math.round1(sceneRight.z);
        var sceneLeft = sceneRight.clone().multiplyScalar(-1);
        var sceneDown = sceneUp.clone().multiplyScalar(-1);
        var sceneBack = sceneFront.clone().multiplyScalar(-1);

        switch (this.cube.currentFace){
            case "front":
                if (sceneUp.x == camX && sceneUp.y == camY && sceneUp.z == camZ)
                    return "up";
                else if (sceneDown.x == camX && sceneDown.y == camY && sceneDown.z == camZ)
                    return "down";
                else if (sceneRight.x == camX && sceneRight.y == camY && sceneRight.z == camZ)
                    return "right";
                else if (sceneLeft.x == camX && sceneLeft.y == camY && sceneLeft.z == camZ)
                    return "left"
                break;
            case "right":
                if (sceneUp.x == camX && sceneUp.y == camY && sceneUp.z == camZ)
                    return "up";
                else if (sceneDown.x == camX && sceneDown.y == camY && sceneDown.z == camZ)
                    return "down";
                else if (sceneBack.x == camX && sceneBack.y == camY && sceneBack.z == camZ)
                    return "left";
                else if (sceneFront.x == camX && sceneFront.y == camY && sceneFront.z == camZ)
                    return "right"
                break;
            case "left":
                if (sceneUp.x == camX && sceneUp.y == camY && sceneUp.z == camZ)
                    return "up";
                else if (sceneDown.x == camX && sceneDown.y == camY && sceneDown.z == camZ)
                    return "down";
                else if (sceneFront.x == camX && sceneFront.y == camY && sceneFront.z == camZ)
                    return "left";
                else if (sceneBack.x ==camX && sceneBack.y == camY && sceneBack.z == camZ)
                    return "right"
                break;
            case "back":
                if (sceneUp.x == camX && sceneUp.y == camY && sceneUp.z == camZ)
                    return "up";
                else if (sceneDown.x == camX && sceneDown.y == camY && sceneDown.z == camZ)
                    return "down";
                else if (sceneLeft.x == camX && sceneLeft.y == camY && sceneLeft.z == camZ)
                    return "right";
                else if (sceneRight.x == camX && sceneRight.y == camY && sceneRight.z == camZ)
                    return "left"
                break;
            case "top":
                if (sceneBack.x == camX && sceneBack.y == camY && sceneBack.z == camZ)
                    return "down";
                else if (sceneFront.x == camX && sceneFront.y == camY && sceneFront.z == camZ)
                    return "up";
                else if (sceneRight.x == camX && sceneRight.y == camY && sceneRight.z == camZ)
                    return "right";
                else if (sceneLeft.x == camX && sceneLeft.y == camY && sceneLeft.z == camZ)
                    return "left"
                break;
            case "bottom":
                if (sceneFront.x == camX && sceneFront.y == camY && sceneFront.z == camZ)
                    return "down";
                else if (sceneBack.x == camX && sceneBack.y == camY && sceneBack.z == camZ)
                    return "up";
                else if (sceneRight.x == camX && sceneRight.y == camY && sceneRight.z == camZ)
                    return "right";
                else if (sceneLeft.x == camX && sceneLeft.y == camY && sceneLeft.z == camZ)
                    return "left"
                break;
        }
    };

    this.setCameraOrtho = function(yes) {
        if( yes && camera.isPerspective )
            camera.toOrthographic();

        if( !yes && !camera.isPerspective )
            camera.toPerspective();
    };

    this.resetOrientation = function(){
        this.setCameraOrtho(this.originalHomeVector.isOrtho);
        this.sceneUpDirection.copy(this.originalHomeVector.worldUp);
        this.sceneFrontDirection.copy(this.originalHomeVector.worldFront);
        this.cubeFront.copy(this.sceneFrontDirection).cross(this.sceneUpDirection).normalize();
        this.setCameraUp(this.sceneUpDirection);
        changed(true);
    };

    this.setCurrentViewAsFront = function(){
        if( this.cube )
            this.cube.currentFace = "front";

        this.sceneUpDirection.copy(camera.up.clone());
        clampToUnitAxisIfNeeded(this.sceneUpDirection);

        this.sceneFrontDirection.copy(this.getView()).normalize();
        clampToUnitAxisIfNeeded(this.sceneFrontDirection);

        this.cubeFront.copy(this.sceneFrontDirection).cross(this.sceneUpDirection).normalize();
        clampToUnitAxisIfNeeded(this.cubeFront);

        if( this.orthographicFaces )
            this.setCameraOrtho(true);

        changed(true);
    };

    this.setCurrentViewAsTop = function(){
        if( this.cube )
            this.cube.currentFace = "top";

        this.sceneUpDirection.copy(this.getView()).multiplyScalar(-1).normalize();
        clampToUnitAxisIfNeeded(this.sceneUpDirection);

        this.sceneFrontDirection.copy(camera.up);
        clampToUnitAxisIfNeeded(this.sceneFrontDirection);

        this.cubeFront.copy(this.sceneFrontDirection).cross(this.sceneUpDirection).normalize();
        clampToUnitAxisIfNeeded(this.cubeFront);

        changed(true);
    };

    this.calculateCubeTransform = function(faceString){
        var worldUp = this.sceneUpDirection.clone();
        var worldFront = this.sceneFrontDirection.clone();
        var worldRight = this.sceneFrontDirection.clone().cross( this.sceneUpDirection).normalize();

        camParamsInitial = camera.clone();
        camParamsInitial.center = cam.center.clone();
        camParamsInitial.pivot = cam.pivot.clone();

        camParamsFinal = camera.clone();
        camParamsFinal.center = cam.center.clone();
        camParamsFinal.pivot = cam.pivot.clone();

        // find movement offset based on given boolean flags
        var offset = new THREE.Vector3( 0, 0, 0 );
        if ( faceString.contains('back')){
            offset = offset.add(worldFront);
        }
        if ( faceString.contains('front')){
            offset = offset.sub(worldFront);
        }
        if ( faceString.contains('top')){
            offset = offset.add(worldUp);
        }
        if ( faceString.contains('bottom')){
            offset = offset.sub(worldUp);
        }
        if ( faceString.contains('right')){
            offset = offset.add(worldRight);
        }
        if ( faceString.contains('left')){
            offset = offset.sub(worldRight);
        }
        var upDir = worldUp;

        // view looking at top or bottom chosen
        var test = offset.clone().normalize();

        if ( ( 1.0 - Math.abs(test.dot(worldUp)) ) < Number.MIN_VALUE ) {
            //( offset == worldUp || offset == -worldUp )
            // find the principal view direction other than top/bottom closest to
            // the current view direction and use it as an up vector

            var viewDir = this.getView().normalize();
            var optUpDir = [ worldFront.clone(), worldFront.clone().negate(), worldRight.clone(), worldRight.clone().negate() ];

            // use both view and up vectors for test vector because transitioning from
            // top and bottom views, view direction is the same (but up direction is different)

            var sign = (test.dot(worldUp) > 0.0) ? +1.0 : -1.0; //( offset == worldUp ) ? +1.0 : -1.0;
            var testDir = viewDir.clone().add(camera.up.clone().multiplyScalar(sign)).normalize();

            var optValue = -2.0;

            for ( var i = 0; i < 4; i++ ){
                var value = testDir.dot( optUpDir[i] );

                if ( value > optValue ){
                    optValue = value;
                    upDir = optUpDir[i].multiplyScalar(sign);
                }
            }
        }

        distFinal = distInitial = this.getView().length();
        // WHY? camParamsFinal.center = this.originalCenter;
        camParamsFinal.position.copy(camParamsFinal.center.clone().add(offset.multiplyScalar(distFinal/offset.length())));
        camParamsFinal.up.copy(upDir);

        var D = camParamsInitial.center.clone().sub(camParamsInitial.position).normalize();
        var R = D.clone().cross(camParamsInitial.up).normalize();
        var U = R.clone().cross(D).normalize();
        var M = new THREE.Matrix3();
        M.set(D.x, U.x, R.x, D.y, U.y, R.y, D.z, U.z, R.z);
        rotInitial.setFromRotationMatrix3(M);

        D = camParamsFinal.center.clone().sub(camParamsFinal.position).normalize();
        R = D.clone().cross(camParamsFinal.up).normalize();
        U = R.clone().cross(D).normalize();
        M.set(D.x, U.x, R.x, D.y, U.y, R.y, D.z, U.z, R.z);
        //TODO: figure out when these angles aren't supposed to be 0, works for now
        rotTwist.setFromAxisAngle(D,0.0);
        rotSpin.setFromAxisAngle(U,0.0);
        rotFinal.setFromRotationMatrix3(M);
        rotFinal.multiply(rotTwist).multiply(rotSpin).normalize();

    };

    //used for center operation from steering wheel and steering wheel menu
    this.centerShot = function(fromWheelMenu){
        //TODO: investigate the problem where it is not animating sometimes (due to lag)

        if (!camParamsInitial || fromWheelMenu){
            cam.elapsedTime = 0;
            camParamsInitial = camParamsFinal = camera.clone();
            camParamsInitial.center = cam.center;
        }

        var pWorld = cam.pivot.clone();
        var P = pWorld.clone().sub(camParamsInitial.position);
        var D = (camParamsInitial.center.clone().sub(camParamsInitial.position)).normalize();
        var U = camParamsInitial.up.clone();
        var R = (D.clone().cross(U)).normalize();
        U = (R.clone().cross(D)).normalize();


        var PprojR = R.clone().multiplyScalar(R.dot(P));
        var PprojU = U.clone().multiplyScalar(U.dot(P));
        var PprojRU = PprojR.clone().add(PprojU);

        camParamsFinal.position.copy(camParamsInitial.position.clone().add(PprojRU));

        camParamsFinal.center = pWorld;
        camParamsFinal.pivot = pWorld;

        var unitTime = 0.0;
        if ( cam.elapsedTime >= shotParams.duration ) {
            unitTime = 1.0;
        } else {
            var tMax = shotParams.destinationPercent;
            unitTime =  Math.easeClamp( cam.elapsedTime / shotParams.duration, 0.0, tMax );
            cam.elapsedTime += deltaTime/2000;
        }

        var position = (camera.position.clone().multiplyScalar( 1.0 - unitTime)).add(camParamsFinal.position.clone().multiplyScalar( unitTime ));
        var center = (cam.center.clone().multiplyScalar( 1.0 - unitTime)).add( camParamsFinal.center.clone().multiplyScalar( unitTime ));
        var pivot = (cam.pivot.clone().multiplyScalar( 1.0 - unitTime)).add( camParamsFinal.pivot.clone().multiplyScalar(unitTime));
        camera.position.copy(position);
        cam.center.copy(center);
        cam.pivot.copy(pivot);

        camera.lookAt(cam.center);
        changed(false);

        if( unitTime === 1.0 )
            this.addHistoryElement();
        else
            requestAnimationFrame(function() { cam.centerShot(false); });
    };

    //This is for the level camera operation in steering wheel menu
    //Integrated from ViewManager::LevelCamera
    this.levelShot = function(){

        var view = this.getView();
        var dist = view.length();
        var worldUp = this.sceneUpDirection.clone();
        var vUp  = camera.up.clone().normalize();
        var vView = view.normalize();
        var dotView = vView.dot(worldUp);

        if ((1.0 - Math.abs(dotView)) > Number.MIN_VALUE){
            var vRight = vView.clone().cross(worldUp);
            vView = worldUp.clone().cross(vRight);
            vView.normalize();
        }else{
            vView = vUp.clone();
        }
        vView.multiplyScalar(dist);

        var destination = {
            center: vView.add(camera.position),
            up: worldUp,
            position: camera.position,
            pivot: cam.center.clone().add(vView),
            fov: camera.fov,
            worldUp: worldUp
        };
        cam.elapsedTime = 0;
        cam.animateTransition(destination);
    };

    //This is for the fit to window operation in the steering wheel drop down menu
    //Integrated from CameraOperations::FitBoundingBoxToView
    //Right now since we only load one mesh we can use the bounding box property of it, if multiple meshes loaded
    //we will need to find the bounding box around them
    this.fitToWindow = function(){

        var viewDir = this.getView();
        var upDir = camera.up.clone();
        viewDir.normalize();
        upDir.normalize();
        camParamsFinal = camera.clone();
        camParamsFinal.center = cam.center;

        upDir = getUpDirection(upDir,viewDir);
        upDir.normalize();
        camParamsFinal.up.copy(upDir);

        var rightDir = viewDir.clone().cross(upDir);
        rightDir.normalize();

        var boxMin = boundingBoxMin.clone();
        var boxMax = boundingBoxMax.clone();
        var boxPoints = [boxMin,boxMax];
        var boxMidpoint = new THREE.Vector3(boxMax.x-boxMin.x, boxMax.y-boxMin.y,boxMax.z-boxMin.z);

        boxPoints[2] = new THREE.Vector3(boxMax.x,boxMin.y,boxMax.z);
        boxPoints[3] = new THREE.Vector3(boxMax.x,boxMin.y,boxMin.z);
        boxPoints[4] = new THREE.Vector3(boxMax.x,boxMax.y,boxMin.z);
        boxPoints[5] = new THREE.Vector3(boxMin.x,boxMax.y,boxMax.z);
        boxPoints[6] = new THREE.Vector3(boxMin.x,boxMax.y,boxMin.z);
        boxPoints[7] = new THREE.Vector3(boxMin.x,boxMin.y,boxMax.z);

        //Move the box into camParams frame coordinates
        for(var j=0; j<8; j++){
            var testVector = boxPoints[j].clone().sub(camera.position);

            boxPoints[j].setX(testVector.clone().dot(rightDir));
            boxPoints[j].setY(testVector.clone().dot(upDir));
            boxPoints[j].setZ(testVector.clone().dot(viewDir));
        }

        //This is to be used when ortho camera is implemented
        /*
        var minPointH = boxPoints[0], maxPointH = boxPoints[0], minPointV = boxPoints[0],maxPointV = boxPoints[0];

        //Solve for the eye position in ortho.  We take the position as the center point
        //Of the 2D projection.
        for(var k=0; k<8; k++){
            var testVertex = boxPoints[k];
            if(testVertex.x < minPointH.x){
                minPointH = testVertex;
            }else if(testVertex.x > maxPointH.x){
                maxPointH = testVertex;
            }

            if(testVertex.y < minPointV.y){
                minPointV = testVertex;
            }else if(testVertex.y > maxPointV.y){
                maxPointV = testVertex;
            }
        }

        var geomWidth = maxPointH.x - minPointH.x;
        var geomHeight = maxPointV.y - minPointV.y;

        //Set ortho width and height
        if (geomWidth/geomHeight > camera.aspect){
            camParams.orthoWidth = geomWidth;
            camParams.orthoHeight = geomWidth/viewAspect;
        }else{
            camParams.orthoWidth = geomHeight * viewAspect;
            camParams.orthoHeight = geomHeight;
        }
        var orthoOffset = new THREE.Vector3((minPointH.x + maxPointH.x)/2.0,(minPointV.y + maxPointV.y)/2.0,0.0);
        */

        //Find the eye position in perspective.
        //While working in 2D, find the equation of the line passing through each box corner of form z = mx + b
        //that is parallel to the sides of the viewing frustum.  Note that all of the coordinates of the box
        //are still defined in the camParams frame.  Compare the z intercept values (ie. b) to figure out which two lines
        //represent the outer edges of the bounding box, and solve for their intersection to find the desired eye (x,z) position
        //that would be required to make the object touch the left and right edges of the viewport (ie. the closest we can get
        //without losing horizontal view of the object).  Repeat with z = my + b to find the eye (y,z) position for the vertical frustum.

        //TODO:fovTop and fovBottom are ALWAYS the same b/c of camera declaration, this needs to change
        var fovTop = THREE.Math.degToRad(camera.topFov);
        var fovBottom = THREE.Math.degToRad(camera.bottomFov);
        var fovLeft = THREE.Math.degToRad(camera.leftFov);
        var fovRight = THREE.Math.degToRad(camera.rightFov);

        var BLeft, BRight, BTop,BBottom;

        BLeft = (fovLeft >= 0) ? Number.MAX_VALUE : Number.MIN_VALUE;
        BRight = (fovRight >= 0)? Number.MAX_VALUE : Number.MIN_VALUE;
        BTop = (fovTop >= 0)? Number.MAX_VALUE : Number.MIN_VALUE;
        BBottom = (fovBottom >= 0)? Number.MAX_VALUE : Number.MIN_VALUE;

        var slopeRight = 1.0/Math.tan(fovRight);
        var slopeLeft = -1.0/Math.tan(fovLeft);
        var slopeTop = 1.0/Math.tan(fovTop);
        var slopeBottom = -1.0/Math.tan(fovBottom);

        for (var i = 0; i<8; i++){
            var testCorner = boxPoints[i].clone();
            var b = testCorner.z - (slopeLeft * testCorner.x);
            BLeft = (fovLeft >= 0)? Math.min(BLeft,b) : Math.max(BLeft,b);

            b = testCorner.z - (slopeRight * testCorner.x);
            BRight = (fovRight >=0) ? Math.min(BRight, b) : Math.max(BRight, b);

            //For vertical frustum
            b = testCorner.z - (slopeTop *testCorner.y);
            BTop = (fovTop >=0) ? Math.min(BTop, b) : Math.max(BTop, b);

            b = testCorner.z - (slopeBottom * testCorner.y);
            BBottom = (fovBottom >=0) ? Math.min(BBottom, b) : Math.max(BBottom, b);
        }

        //Solve for intersection of horizontal frustum
        var eyeX = (BRight - BLeft)/(slopeLeft - slopeRight);
        var eyeZH = (slopeLeft * eyeX) + BLeft;

        //Solve for intersection of vertical frustum
        var eyeY = (BBottom - BTop)/(slopeTop - slopeBottom);
        var eyeZV = slopeTop * eyeY + BTop;

        var eyeZ = 0.0;

        //With the two frustums solved, compare the two frustums to see which one is currently closer to the object based on z value.
        //Slide the closer frustum back along its median line (to ensure that the points stay within the frustum) until it's Z value
        //matches that of the further frustum. Take this as the final eye position.

        if (eyeZH <= eyeZV){
            var medianAngleV = (fovTop - fovBottom) / 2.0;
            if (Math.abs(medianAngleV) > Number.MIN_VALUE){
                var medianSlopeV = 1.0/Math.tan(medianAngleV);
                eyeY = eyeY - eyeZV/medianSlopeV + eyeZH/medianSlopeV; //derived from z1 - my1 = z2 - my2
            }
            eyeZ = eyeZH;
        }else{
            var medianAngleH = (fovRight - fovLeft) / 2.0;
            if (Math.abs(medianAngleH) > Number.MIN_VALUE){
                var medianSlopeH = 1.0/Math.tan(medianAngleH);
                eyeX = eyeX - eyeZH/medianSlopeH + eyeZV/medianSlopeH;
            }
            eyeZ = eyeZV;
        }

        var eyeOffset = new THREE.Vector3(eyeX,eyeY,eyeZ);

        //Transform eyeoffset back into world frame
        var interim1 = (rightDir.clone().multiplyScalar(eyeOffset.x));
        var interim2 = (upDir.clone().multiplyScalar(eyeOffset.y));
        var interim3 = (viewDir.clone().multiplyScalar(eyeOffset.z));
        eyeOffset = interim1.clone().add(interim2.clone().add(interim3));

        camParamsFinal.position.add(eyeOffset);
        var interim = (boxMidpoint.clone().sub(camParamsFinal.position)).dot(viewDir);
        camParamsFinal.center = camParamsFinal.position.clone().add(viewDir.multiplyScalar(interim));
        camParamsFinal.pivot = boxMidpoint.clone();

        var destination = {
              center: camParamsFinal.center,
                  up: camParamsFinal.up,
            position: camParamsFinal.position,
               pivot: camParamsFinal.pivot,
                 fov: camera.fov,
             worldUp: cam.sceneUpDirection.clone()
        };
        cam.elapsedTime = 0;
        cam.animateTransition(destination);
    };

    /*         Functions for operation         */

    //used in fit to window
    function getUpDirection(upDir, viewDir){
        var upp = upDir.clone();

        if((Math.abs(upp.clone().dot(viewDir))) < Number.MIN_VALUE){
            upp.normalize();
            return upp;
        }

        upp = getProjectionOnPlane(upDir,viewDir);
        if (upp.length() < Number.MIN_VALUE){
            upp = getEmpiricalUpDirection(viewDir);
        }
        upp.normalize();
        return upp;
    }

    //used in getUpDirection
    function getProjectionOnPlane(vector,normal){
        normal.normalize();
        var projToNormal = vector.clone().dot(normal);
        var projection = normal.clone().multiplyScalar(projToNormal);
        projection = vector.clone().sub(projection);
        return projection;
    }

    //used in getUpDirection
    function getEmpiricalUpDirection(normal){
        var zeros = new THREE.Vector3(0,0,0);
        var directions = [new THREE.Vector3(0,1,0),
            new THREE.Vector3(1,0,0),
            new THREE.Vector3(0,0,1),
            new THREE.Vector3(0,1,1),
            new THREE.Vector3(1,0,1),
            new THREE.Vector3(1,1,0),
            new THREE.Vector3(1,1,1)
            ];

        for (var i = 0; i <7; i++){
            if (Math.abs(directions[i].dot(normal)) < Number.MIN_VALUE){
                zeros = directions[i];
                break;
            }
        }
        return zeros;
    }

    //convert screen coords to window coords
    function convertCoordsToWindow( pixelX, pixelY ){
        var delta = new THREE.Vector2(0,0);

        delta.x = pixelX / window.innerWidth;
        delta.y = pixelY / window.innerHeight;

        return delta;
    }

    //picking ray intersection with the empty scene(not on object)
    function getScreenRay( mouse ){
        mouse.y = Math.abs(mouse.y - window.innerHeight);
        var rayOrigin, rayDirection;
        var eye = camera.position;
        var center = cam.center;
        var eyeToCenter = center.clone().sub(eye);
        var up = camera.up;
        var right = eyeToCenter.clone().cross(up);
        var dist = eyeToCenter.clone().length();

        var frustumLeft = dist * Math.tan(THREE.Math.degToRad(camera.leftFov));
        var frustumRight = dist * Math.tan(THREE.Math.degToRad(camera.rightFov));
        var frustumTop = dist * Math.tan(THREE.Math.degToRad(camera.topFov));
        var frustumBottom = dist * Math.tan(THREE.Math.degToRad(camera.bottomFov));
        var frustumWidth = (frustumLeft + frustumRight);
        var frustumHeight = (frustumTop + frustumBottom);

        var rightLength = mouse.x * frustumWidth / window.innerWidth;
        var centerToRightLength = rightLength - frustumLeft;

        var upLength = mouse.y * frustumHeight / window.innerHeight;
        var centerToUpLength = upLength - frustumBottom;

        up = up.clone().normalize().clone().multiplyScalar(centerToUpLength);
        right = right.clone().normalize().clone().multiplyScalar(centerToRightLength);

        /*
        // PRH -- account for difference in aspect ratio between camera FOV and viewport --
        AutoCam::AdjustForAspectRatio( params, screenWidth, screenHeight, mouseXunit, mouseYunit );
        */

        if ( cam.ortho ){
            rayOrigin = eye.clone().add(right).clone().add(up);
            rayDirection = eyeToCenter;
        }else{
            rayOrigin = eye;
            rayDirection = eyeToCenter.clone().add(up).clone().add(right);
        }

        return {
            'rayO': rayOrigin,
            'rayD': rayDirection
        };
    }

    //get ray intersection point and set pivot
    this.updatePivotPosition = function( mouse ){
        //TODO: update pivot only when mouse down

        var raycaster;
        var intersects;
        //formula from online
        var direction = new THREE.Vector3((mouse.x / window.innerWidth) * 2 - 1, -(mouse.y / window.innerHeight) * 2 + 1, 0.5);

        direction = direction.unproject(camera);
        raycaster = new THREE.Raycaster(camera.position, direction.sub(camera.position).normalize());
        intersects = raycaster.intersectObjects(objects);

        if (cam.mode == 'zoom'){
            if (intersects[0] !== undefined){
                var point = intersects[0].point;
                cam.pivot.copy(point);
            }else{
                var result = getScreenRay( mouse );
                cam.pivot.copy(result.rayO.clone().add(result.rayD));
            }

        }else if (intersects[0] !== undefined){
            wheel.cursorImage('pivot');
            var point = intersects[0].point;
            if (!cam.isMouseDown){
                cam.pivot.copy(point);
            }
        }else{
            wheel.cursorImage('SWInvalidArea');
        }
    };

    function getNextRotation(rotationType, snapAngle, lastDelta){
        var threshold, accelerationA, accelerationB, shiftZone;
        threshold = accelerationA = accelerationB = shiftZone = 0.0;

        var next = 0.0;
        var lockedAxis = null;
        var lockDelta = null;

        var deadZone = cam.snapOrbitDeadZone;
        var orbitMultiplier = cam.orbitMultiplier;

        if (rotationType == 'h'){
            threshold = cam.snapOrbitThresholdH;
            accelerationA = cam.snapOrbitAccelerationAX;
            accelerationB = cam.snapOrbitAccelerationBX;
            shiftZone = 1.0 -cam.snapOrbitAccelerationPointX;
            lockDelta = cam.lockDeltaX;
            lockedAxis = cam.lockedX;
        }else{
            threshold = cam.snapOrbitThresholdV;
            accelerationA = cam.snapOrbitAccelerationAY;
            accelerationB = cam.snapOrbitAccelerationBY;
            shiftZone = 1.0 -cam.snapOrbitAccelerationPointY;
            lockDelta = cam.lockDeltaY;
            lockedAxis = cam.lockedY;
        }

        if(!lockedAxis){
            if(Math.abs(snapAngle) > threshold){
                next = lastDelta * orbitMultiplier;
            }else if (Math.abs(snapAngle) > shiftZone * threshold){
                if(lastDelta * snapAngle > 0.0){
                    next = lastDelta * orbitMultiplier * accelerationA;
                }else{
                    next = lastDelta * orbitMultiplier * 1.0/accelerationA;
                }

            }else{
                if(lastDelta * snapAngle > 0.0){
                    next = lastDelta * orbitMultiplier * accelerationB;
                }else{
                    next = lastDelta * orbitMultiplier * 1.0/accelerationB;
                }

            }

            if(next * snapAngle > 0.0 && Math.abs(next) > Math.abs(snapAngle)){
                this.lockDeltaX = this.lockDeltaY = 0.0;	//want to reset both regardless of rotation axis
                lockedAxis = true;
                next = snapAngle;
            }

        }else{
            lockDelta += lastDelta;

            if(lockDelta < -deadZone){
                next =  (lockDelta + deadZone) * orbitMultiplier * 1.0/accelerationB;
                lockedAxis = false;
            }else if(lockDelta > deadZone){
                next =  (lockDelta - deadZone) * orbitMultiplier * 1.0/accelerationB;
                lockedAxis = false;
            }
        }
        return next;
    }


    function getClosestAlignDir(Dv, searchPrincipal){
        var maxAngle = -Number.MAX_VALUE;
        var maxIndex = 0;

        for ( var i = 0; i < (searchPrincipal ? 6 : 26); i++ ){
            var Di = cam.alignDirTable[i].clone().multiplyScalar(-1);
            Di.normalize();

            var angle = Di.dot(Dv);

            if ( angle > maxAngle ){
                maxAngle = angle;
                maxIndex = i;
            }
        }
        return cam.alignDirTable[maxIndex];
    }

    function snapToClosestView (up, snapAngleh, snapAnglev){
        if ( !cam.useSnap )
            return;

        if (cam.preserveOrbitUpDirection ){
            // Find closest view direction
            var lastViewDir = (cam.saveCenter.clone().sub(cam.saveEye)).clone().normalize();
            var snapDir = (getClosestAlignDir(lastViewDir, false)).clone().multiplyScalar(-1).clone().normalize();

            if(Math.abs(Math.abs(lastViewDir.clone().dot(up)) - 1.0) < Number.MIN_VALUE){
                //topdown or bottom up case
                snapAnglev = 0.0;
                var snapUp = (getClosestAlignDir(cam.saveUp, true)).clone().multiplyScalar(-1).clone().normalize();
                snapAngleh = cam.saveUp.findAngleWith(snapUp,up);
            } else {
                var lastViewDirProj = lastViewDir.clone().sub(up).multiplyScalar(up.clone().dot(lastViewDir));
                var snapDirProj = snapDir.clone().sub(up).multiplyScalar(up.clone().dot(snapDir));
                snapAngleh = lastViewDirProj.clone().findAngleWith(snapDirProj, up);
                var testRotate = new THREE.Quaternion().setFromAxisAngle( up,snapAngleh );
                var transitionDir = testRotate.clone().rotate(lastViewDir);
                var transitionRight = testRotate.clone().rotate(lastViewDir.clone().cross(cam.saveUp));
                snapAnglev = transitionDir.clone().findAngleWith(snapDir, transitionRight);
            }

            if(snapDir != cam.lastSnapDir){
                //If last and current snapDirs are not on the same plane, unlock vertical orbit
                if (Math.abs(snapDir.clone().dot(up) - cam.lastSnapDir.clone().dot(up)) > Number.MIN_VALUE){
                    cam.lockedY = false;
                }
                cam.lastSnapDir = snapDir;
            }
        } else {
            //Find closest view direction
            /*  var vDirView = cam.saveCenter.clone().sub(cam.saveEye);
            var vRight = vDirView.clone().cross( cam.saveUp );
            var snapDir = -getClosestAlignDir(vDirView, false).clone().normalize();
            var snapDirProj = snapDir.clone.sub(up.clone().multiplyScalar(up.clone().dot(snapDir)));
            snapAngleh = vDirView.findAngleWith(snapDirProj, up);

            var testRotate = new THREE.Quaternion().setFromAxisAngle(up,snapAngleh );
            var transitionDir = testRotate.clone().rotate(vDirView);
            var transitionRight = testRotate.clone().rotate(vRight);
            snapAnglev = transitionDir.findAngleWith(snapDir, transitionRight);

            if(snapDir != cam.lastSnapDir) {
                cam.cam.lockedY = false;
                cam.lockedX = false;
                cam.lastSnapDir = snapDir;
            }*/
        }
    }

/// Returns true if the operation belongs to a chain of combined operations; otherwise returns false.
    function IsCombined(){
        return cam.combined;
    }

    function isInDeadZone(currentCursor, startCursor){

        var deadZone = 30;
        var res = false;

        var w = window.innerWidth;
        var x = currentCursor.x % w;

        var h = window.innerHeight;
        var y = currentCursor.y % h;


        var diffX = (x > 0) ? (x - startCursor.x) : (w + x - startCursor.x);
        var diffY = (y > 0) ? (y - startCursor.y) : (h + y - startCursor.y);

        if((Math.abs(diffX) < deadZone) &&  (Math.abs(diffY) < deadZone))
            res = true;

        return res;
    }

    function GetXYAndWrapCounts(currentCursor, startCursor, wrapCount ){
        wrapCount.x = (currentCursor.x - startCursor.x) / window.innerWidth;
        currentCursor.x = startCursor.x + (currentCursor.x - startCursor.x) % window.innerWidth;

        wrapCount.y = (currentCursor.y - startCursor.y) / window.innerHeight;
        currentCursor.y = startCursor.y + (currentCursor.y - startCursor.y) % window.innerHeight;
    }

    function setBias( set, currentCursor, startCursor ){
        if (m_bias && set){
            return;

        }else if (set){
            var deadZone = 30;
            var wrapCount = new THREE.Vector2();

            var x = currentCursor.x;
            var y = currentCursor.y;

            GetXYAndWrapCounts(currentCursor, startCursor, wrapCount);

            m_resetBiasX = window.innerWidth * wrapCount.x;
            m_resetBiasY = window.innerHeight * wrapCount.y;

            if (x < startCursor.x)
                x = x - 2 * deadZone;
            else
                x = x + 2 * deadZone;

            if (y < startCursor.y)
                y = y - 2 * deadZone;
            else
                y = y + 2 * deadZone;
        }
        m_bias = set;
    }

    function checkBoundaryConditions(amount, cursorOffset, m_amount){
        if (cursorOffset === 0)
            return 0;

        var deltaAmount = amount;
        var eye = cam.saveEye.clone().sub(worldUp.clone().multiplyScalar(m_amount + deltaAmount));
        var prevEye = cam.saveEye.clone().sub(worldUp.clone().multiplyScalar(m_amount));

        var eyeHeight = 0.0;
        var epsilon = (cam.maxSceneBound - cam.minSceneBound) / 1000;

        //avp.logger.log(m_amount);
        //avp.logger.log(deltaAmount);


        if (cam.topLimit && (cursorOffset > 0)){
            // Cursor was on the top of the slider, but now is moving down.
            // Bring eyeHeight below maxSceneBound.
            eyeHeight = cam.maxSceneBound - epsilon;
            cam.topLimit = false;
        }else if (cam.bottomLimit && (cursorOffset < 0)){
            // Cursor was on the bottom of the slider, but now is moving up.
            // Bring eyeHeight above minSceneBound.
            eyeHeight = cam.minSceneBound + epsilon;
            cam.bottomLimit = false;
        }else{
            eyeHeight = eye.dot(worldUp);
        }

        var prevEyeHeight =	prevEye.dot(worldUp);

        //avp.logger.log(eyeHeight);

        if ( eyeHeight < cam.minSceneBound ) {
            if ( prevEyeHeight < cam.minSceneBound) {
                // this limits how far under the min we can go
                cam.bottomLimit = true;
                deltaAmount = 0.0;
            }
        }else if ( eyeHeight > cam.maxSceneBound ) {
            if ( prevEyeHeight > cam.maxSceneBound ) {
                // This limits how far over the max we can go
                cam.topLimit = true;
                deltaAmount = 0.0;
            }
        }

        return deltaAmount;
    }

    function getMoveAmountFromCursorOffset(offset){
        // Manipulating with power of 2 of cursor offset allows to amplify the visible change in the offset
        // when the offset is big to achieve the effect ofhigher sensitivity of the tool on small offsets
        // and lower sensitivity on big offsets.
        var derivedOffset = Math.pow (offset, 2.0);
        if (offset < 0){
            derivedOffset = -derivedOffset;
        }

        //delta.y = derivedOffset;
        var delta = convertCoordsToWindow( 0, derivedOffset );
        var sceneHeight = cam.maxSceneBound - cam.minSceneBound;

        // This empirical step provides a good motion of the scene when moving up/down.
        var p = sceneHeight * 0.01;
        delta.y *= p;

        var deltaAmount = cam.userHeightSpeed * delta.y;
        deltaAmount = checkBoundaryConditions(deltaAmount, offset, cam.m_amount);

        return deltaAmount;
    }

    //draw UI for up-down operation during mouse move
    this.onDrawHeight = function(mouse, pX, pY, dragged, path){
        var sliderHeight = 86;
        var upDir = new THREE.Vector3(0,1,0);
        var h = camera.position.clone().dot(upDir);
        var unitHeight = Math.linearClamp( h, cam.minSceneBound, cam.maxSceneBound );
        var height = unitHeight - 0.5 ;
        if( cubeContainer ) {
            cubeContainer.find("img#updownImageA").remove();
            cubeContainer.prepend('<img src="'+path+'SWheighthandleA.png" id="updownImageA" style="position:fixed; z-index:9999; top:'+(pY - sliderHeight * height)+'px; left:'+pX+'px;"/>');

            if (!dragged){
                cubeContainer.prepend('<img src="'+path+'SWheighthandleI.png" id="updownImageI" style="position:fixed; z-index:9998; top:'+(pY - sliderHeight * height)+'px; left:'+(pX)+'px;"/>');
            }
        }
    };

    /**
     * Draws a menu by appending an unordered list to the given container element.
     * @param {Array} menuOptions - string array of menu options, null meaning seperator
     * @param {Array} menuEnables - boolean array of menu enable flags indicating which corresponding menu entry in menuOptions should be enabled or disabled.
     * @param {Number} mousex - the x coordinate of the menu trigger point, used to position menu
     * @param {Number} mousey - the y coordinate of the menu trigger point, used to position menu
     * @param {HTMLElement} container - the container element to add the menu to.
     * @param {Object} position - object with x, y, w, h of the container element.
     */
    this.drawDropdownMenu = function(menuOptions, menuEnables, menuCallbacks, mousex, mousey, container, position) {
        var itemID = 0;

        if( !dropDownMenu ) {

            dropDownMenu = document.createElement('div');
            dropDownMenu.className = 'dropDownMenu';

            // Initialize the top and left with some approximate values
            // so that the correct width can be returned by gerBoudningClientRect().
            dropDownMenu.style.top    = '100px';
            dropDownMenu.style.left   = '-400px';

            var menuHeight = 0;
            var menuMinWidth = 0;
            for (var i = 0; i<menuOptions.length; i++){
                var listItem;
                if (menuOptions[i] === null) {                       // menu separator
                    listItem = document.createElement("li");
                    listItem.style.height = '1px';
                    menuHeight += 1;
                    listItem.style.backgroundColor="#E0E0E0";
                } else {
                    var content = i18n.translate(menuOptions[i]);
                    menuMinWidth = content.length > menuMinWidth ? content.length : menuMinWidth;

                    if( menuCallbacks[i] ) {
                        listItem = document.createElement("div");
                        var check = document.createElement("input");
                        var text  = document.createElement("label");
                        check.type = "radio";
                        check.className = "dropDownMenuCheck";
                        text.innerHTML = content;
                        text.className = "dropDownMenuCheckText";
                        listItem.appendChild(check);
                        listItem.appendChild(text);
                        listItem.className = "dropDownMenuCheckbox";
                    }
                    else {
                        listItem = document.createElement("li");
                        listItem.textContent = content;
                        listItem.className = menuEnables[i] ? "dropDownMenuItem" : "dropDownMenuItemDisabled";
                    }

                    listItem.id = "menuItem" + itemID;
                    itemID++;
                    menuHeight += 25;       // HACK!!!

                    listItem.setAttribute( "data-i18n", menuOptions[i] );
                }
                dropDownMenu.appendChild(listItem);
            }

            // Add the menu to the DOM before asking for boundingClientRect.
            // Otherwise, it will be zero.
            container.appendChild(dropDownMenu);

            dropDownMenu.style.minWidth = Math.max(256, menuMinWidth * 7.4) + 'px'; // approximate min width
            var menuWidth = dropDownMenu.getBoundingClientRect().width;

            this.menuSize.x = menuWidth;
            this.menuSize.y = menuHeight;
        }
        else {
            // Just add the drop down menu, It already exists.
            container.appendChild(dropDownMenu);
        }
        itemID = 0;
        for (var i = 0; i<menuOptions.length; i++) {
            if( menuOptions[i] === null )
                continue;

            if( menuCallbacks[i] ) {
                var id = "menuItem" + itemID;
                var element = document.getElementById(id);
                if( element ) {
                    element.children[0].checked = menuCallbacks[i]();
                }
            }
            itemID++;
        }
        var top  = mousey - 15;        // 15 offset so list appears @ button
        var left = mousex + 1;

        var rect = this.canvas.getBoundingClientRect();

        if( (left + this.menuSize.x) > rect.right )
            left = mousex - this.menuSize.x - 1;
        if( (top + this.menuSize.y) > rect.bottom )
            top = rect.bottom - this.menuSize.y;

        // Make relative to container:
        top  -= position.y;
        left -= position.x;

        dropDownMenu.style.top  = top + 'px';
        dropDownMenu.style.left = left + 'px';

        this.menuOrigin.x = left;
        this.menuOrigin.y = top;
    };


    this.removeDropdownMenu = function(container) {
        container.removeChild(dropDownMenu);
    };

    function isAxisAligned(vec) {
        var sceneRight  = cam.sceneFrontDirection.clone().cross(cam.sceneUpDirection);
        var checkUp    = Math.abs(Math.abs(vec.dot(cam.sceneUpDirection)) - 1.0);
        var checkFront = Math.abs(Math.abs(vec.dot(cam.sceneFrontDirection)) - 1.0);
        var checkRight = Math.abs(Math.abs(vec.dot(sceneRight)) - 1.0);

        return (checkUp < 0.00001 || checkFront < 0.00001 || checkRight < 0.00001);
    }

    this.isFaceView = function() {
        var dir = this.center.clone().sub(camera.position).normalize();
        return isAxisAligned(dir) && isAxisAligned(camera.up);
    };

    this.startInteraction = function (x, y) {
        this.startCursor = new THREE.Vector2(x, y);

        this.startState = {
            saveCenter: this.center.clone(),
            saveEye:    this.camera.position.clone(),
            savePivot:  this.pivot.clone(),
            saveUp:     this.camera.up.clone()
        };

        this.lockDeltaX = 0.0;
        this.lockedX = false;
        this.lastSnapRotateX = 0.0;
        this.lockDeltaY = 0.0;
        this.lockedY = false;
        this.lastSnapRotateY = 0.0;
        this.lastSnapDir = new THREE.Vector3(0,0,0);

        this.navApi.setTransitionActive(true);
    };

    this.orbit = function (currentCursor, startCursor, distance, startState){
        if( !this.navApi.isActionEnabled('orbit') || this.currentlyAnimating === true )
            return;

        var mode = 'wheel';

        // If orthofaces is enabled, and camera is ortho
        // then switch to perspective
        if(cam.orthographicFaces && !camera.isPerspective) {
            camera.toPerspective();

            // Hack: update the start state with the new position:
            if( startState )
                startState.saveEye.copy(this.camera.position);
        }
        if (startState){
            mode = 'cube';
        }
        if (mode == 'cube'){
            this.saveCenter.copy(startState.saveCenter);
            this.saveEye.copy(startState.saveEye);
            this.savePivot.copy(startState.savePivot);
            this.saveUp.copy(startState.saveUp);
            this.useSnap = true;
            this.doCustomOrbit = true;
        } else {
            this.saveCenter.copy(this.center);
            this.savePivot.copy(this.pivot);
            this.saveEye.copy(camera.position);
            this.saveUp.copy(camera.up);
            this.useSnap = false;
            this.doCustomOrbit = false;
        }

        if (IsCombined() && prevCenter == undefined) {
            prevCenter = this.saveCenter.clone();
            prevEye    = this.saveEye.clone();
            prevPivot  = this.savePivot.clone();
            prevUp     = this.saveUp.clone();
        }

        // TODO: fold the two cases into one and prevent duplicate code
        if (this.preserveOrbitUpDirection ) {

            var delta = convertCoordsToWindow( currentCursor.x - startCursor.x, currentCursor.y - startCursor.y );
            var lastDelta = convertCoordsToWindow(distance.x, distance.y);

            var worldUp = this.sceneUpDirection.clone();
            var worldFront = this.sceneFrontDirection.clone();
            var worldRight = this.sceneFrontDirection.clone().cross( this.sceneUpDirection).normalize();

            /* ????? WTF:
            var worldFront = new THREE.Vector3(1,0,0);
            var worldUp = new THREE.Vector3(0,1,0);
            */

            //viewcube
            // if (this.doCustomOrbit ) {
            //     worldUp = new THREE.Vector3(0,1,0);
            //     worldFront = new THREE.Vector3(1,0,0);
            // }

            /* ?????? WTF:
            var worldR = worldFront.clone().cross( worldUp );
            worldUp = worldR.clone().cross(worldFront);
            worldUp.clone().normalize();
            */

            var pivot  = IsCombined() ? prevPivot  : this.savePivot;
            var eye    = IsCombined() ? prevEye    : this.saveEye;
            var center = IsCombined() ? prevCenter : this.saveCenter;
            var camUp  = IsCombined() ? prevUp     : this.saveUp;

            var initViewDir  = pivot.clone().sub(eye).normalize();
            var initViewDirV = center.clone().sub(eye).normalize();
            var initRightDir = initViewDirV.clone().cross( camUp );

            var fTargetDist  = eye.clone().sub(pivot).length();
            var fTargetDistV = eye.clone().sub(center).length();

            var vLookUpdate  = initViewDir.clone().multiplyScalar(-1);
            var vLookUpdateV = initViewDirV.clone().multiplyScalar(-1);
            var vRightUpdate = initRightDir;
            var vUpUpdate = camUp.clone();

            var snapAngleh = 0.0;
            var snapAnglev = 0.0;

            //viewcube

            // DOESN'T DO ANYTHING: snapToClosestView(worldUp, snapAngleh, snapAnglev);

            if ( !this.constrainOrbitHorizontal ) {
                // Need to check if:
                //  1. camera is "upside-down" (angle between world up and camera up is obtuse) or
                //  2. camera is in top view (camera up perpendicular to world up and view angle acute to world up)
                // These cases required a reversed rotation direction to maintain consistent mapping of tool:
                //  left->clockwise, right->counter-clockwise
                //
                //  PHB June 2014 - #2 above makes no sense to me. If the camera up is perpendicular to the
                //  world up then the view is parallel to world up (view dot up == 1). So the second test is
                //  meaningless. There is no good way to determine the rotation direction in this case. If you
                //  want it to feel like direct manipulation then it would be better to determine if the cursor
                //  is above or below the pivot in screen space.

                var worldUpDotCamUp = worldUp.dot(this.saveUp);
                // var worldUpDotView  = worldUp.dot(this.saveCenter.clone().sub(this.saveEye).normalize());

                // if ((worldUpDotCamUp < -Number.MIN_VALUE) ||
                //     ((Math.abs(worldUpDotCamUp) < Number.MIN_VALUE) && (worldUpDotView > 0.0)))
                //
                var kFlipTolerance = 0.009;     // Must be flipped by more than about 0.5 degrees
                if( worldUpDotCamUp < -kFlipTolerance ) {
                    delta.x = -delta.x;
                    lastDelta.x = -lastDelta.x;
                }

                var dHorzAngle = 0.0;
                if (IsCombined()) {
                    dHorzAngle = lastDelta.x * this.orbitMultiplier;
                } else {
                    dHorzAngle = this.useSnap ? this.lastSnapRotateX + getNextRotation('h', snapAngleh, -lastDelta.x) :
                        delta.x * this.orbitMultiplier;
                }

                this.lastSnapRotateX = dHorzAngle;
                // Define rotation transformation

                var quatH = new THREE.Quaternion().setFromAxisAngle( worldUp, -dHorzAngle );

                vLookUpdate.applyQuaternion(quatH);
                vLookUpdateV.applyQuaternion(quatH);
                vRightUpdate.applyQuaternion(quatH);
                vUpUpdate.applyQuaternion(quatH);
            }

            if ( !this.constrainOrbitVertical ) {
                var vRightProjF = worldFront.clone().multiplyScalar(worldFront.dot(vRightUpdate));
                var vRightProjR = worldRight.clone().multiplyScalar(worldRight.dot(vRightUpdate));
                var vRightProj = vRightProjF.clone().add(vRightProjR);
                vRightProj.clone().normalize();

                var dVertAngle = 0.0;

                if (IsCombined()){
                    dVertAngle = lastDelta.y * this.orbitMultiplier;
                }else{
                    var next = getNextRotation('v', snapAnglev, lastDelta.y);
                    dVertAngle = this.useSnap ? this.lastSnapRotateY + next : delta.y * this.orbitMultiplier;
                }
                var quatV = new THREE.Quaternion().setFromAxisAngle( vRightProj, -dVertAngle );

                if( !this.navApi.getOrbitPastWorldPoles() ) {

                    var vUpUpdateTemp = vUpUpdate.clone();
                    vUpUpdateTemp.applyQuaternion(quatV).normalize();

                    // Check if we've gone over the north or south poles:
                    var wDotC = worldUp.dot(vUpUpdateTemp);
                    if( wDotC < 0.0 )
                    {
                        var vLookUpdateVtemp = vLookUpdateV.clone();
                        vLookUpdateVtemp.applyQuaternion(quatV).normalize();

                        // How far past Up are we?
                        var dVertAngle2 = vLookUpdateVtemp.angleTo(worldUp);
                        if( Math.abs(dVertAngle2) > (Math.PI * 0.5) )
                            dVertAngle2 -= (dVertAngle2 > 0.0) ? Math.PI : -Math.PI;

                        dVertAngle -= dVertAngle2;

                        quatV.setFromAxisAngle( vRightProj, -dVertAngle );
                        vLookUpdate.applyQuaternion(quatV).normalize();
                        vLookUpdateV.applyQuaternion(quatV).normalize();
                        vUpUpdate.applyQuaternion(quatV).normalize();

                    }
                    else
                    {
                        vLookUpdate.applyQuaternion(quatV).normalize();
                        vLookUpdateV.applyQuaternion(quatV).normalize();
                        vUpUpdate.applyQuaternion(quatV).normalize();
                    }
                }
                else
                {
                    vLookUpdate.applyQuaternion(quatV).normalize();
                    vLookUpdateV.applyQuaternion(quatV).normalize();
                    vUpUpdate.applyQuaternion(quatV).normalize();
                }
                this.lastSnapRotateY = dVertAngle;
            }

            // figure out new eye point
            var vNewEye = vLookUpdate.multiplyScalar(fTargetDist).add(pivot);

            camera.position.copy(vNewEye);
            camera.up.copy(vUpUpdate);
            this.center.copy(vNewEye);
            this.center.sub(vLookUpdateV.multiplyScalar(fTargetDistV));

            if( IsCombined() )
            {
                prevCenter.copy(this.center);
                prevEye.copy(camera.position);
                prevPivot.copy(this.pivot);
                prevUp.copy(camera.up);
            }
        }
        else {
            /*var lastDelta = convertCoordsToWindow(distance.x, distance.y);
            var vDir = prevPivot.clone().sub(prevEye);
            var vDirView = prevCenter.clone().sub(prevEye);
            var vRight = vDirView.clone().cross(prevUp);
            var vUp = vRight.clone().cross(vDirView);
            vUp.clone().normalize();

            var dist = (prevPivot.clone().sub(prevEye)).clone().length();
            var distView = (prevCenter.clone().sub(prevEye)).clone().length();

            var snapAngleh = 0.0;
            var snapAnglev = 0.0;

            //viewcube
            //snapToClosestView(vUp, snapAngleh, snapAnglev);

            if ( !this.constrainOrbitHorizontal ){

            var dHorzAngle = this.useSnap ? getNextRotation(HORIZONTAL, snapAngleh, lastDelta.x):
            lastDelta.x *this.orbitMultiplier;

            var quatH = new THREE.Quaternion().setFromAxisAngle( vUp.clone().normalize(), dHorzAngle );
            vDir = quatH.clone().rotate(vDir);
            vDirView = quatH.clone().rotate(vDirView);
            }

            if ( !this.constrainOrbitVertical ){
            var dVertAngle = this.useSnap ? getNextRotation(VERTICAL, snapAnglev, lastDelta.y) :
            lastDelta.y *this.orbitMultiplier;

            var quatV = new THREE.Quaternion().setFromAxisAngle( vRight.clone().normalize(), dVertAngle );
            vDir = quatV.clone().rotate(vDir);
            vDirView = quatV.clone().rotate(vDirView);
            vUp = quatV.clone().rotate(vUp);
            }

            camera.eye = this.pivot.clone().sub((vDir.clone().normalize()).clone().multiplyScalar(dist));
            this.center.copy(camera.eye.clone().add((vDirView.clone().normalize()).clone().multiplyScalar(distView)));
            camera.up.copy(vUp.clone().normalize());

            prevCenter = this.center;
            prevEye = camera.position;
            prevPivot = this.pivot;
            prevUp = camera.up;*/
        }
        camera.lookAt(this.center);
        changed(false);

        /*avp.logger.log("Camera Position: ( "+camera.position.x +", "+camera.position.y+", "+camera.position.z+" )");
        avp.logger.log("Up Vector: ( "+camera.up.x +", "+camera.up.y+", "+camera.up.z+" )");
        avp.logger.log("Center: ( "+this.center.x +", "+this.center.y+", "+this.center.z+" )");
        */
    };

    this.endInteraction = function() {

        this.navApi.setTransitionActive(false);
    };

    this.look = function( distance ){
        if( !this.navApi.isActionEnabled('walk') )
            return;

        var delta = convertCoordsToWindow(distance.x, distance.y);
        var multiplier = this.userLookSpeed;

        //if ( m_manager->GetApplicationParameters().lookInvertVerticalAxis ) { deltaY = -deltaY; }

        var eyeToCenter = this.getView();

        var camUp = camera.up;
        var camRight = eyeToCenter.clone().cross(camUp).normalize();
        var worldUp = this.sceneUpDirection.clone();

        // TODO: scale look by camera's FOV
        // vertical rotation around the camera right vector
        var angle = delta.clone();
        angle.x *= Math.PI;
        angle.y *= Math.PI / camera.aspect;
        angle.multiplyScalar(multiplier);
        var qRotY = new THREE.Quaternion().setFromAxisAngle( camRight, -angle.y);

        if (camera.keepSceneUpright && !this.navApi.getOrbitPastWorldPoles()) {
            var futureUp = camUp.clone();
            futureUp.applyQuaternion(qRotY).normalize();

            if (futureUp.dot(worldUp) < 0) {
                var futureEyeToCenter = eyeToCenter.clone();
                futureEyeToCenter.applyQuaternion(qRotY);

                var deltaAngle = futureEyeToCenter.angleTo(worldUp);

                if(Math.abs(deltaAngle) > (Math.PI * 0.5))
                    deltaAngle -= (deltaAngle > 0.0) ? Math.PI : -Math.PI;

                angle.y -= deltaAngle;

                qRotY.setFromAxisAngle(camRight, -angle.y);
            }
        }

        eyeToCenter = qRotY.clone().rotate(eyeToCenter);
        camUp = qRotY.clone().rotate(camUp);
        camUp.normalize();

        var vertAxis = camera.keepSceneUpright ? worldUp : camUp;
        var qRotX = new THREE.Quaternion().setFromAxisAngle( vertAxis, -angle.x );

        eyeToCenter = qRotX.clone().rotate( eyeToCenter );
        camUp = qRotX.clone().rotate( camUp );

        this.center.copy(eyeToCenter.add(camera.position));
        camera.up.copy(camUp);

        camera.lookAt(this.center);
        changed(false);
    };

    this.pan = function ( distance ) {
        if( !this.navApi.isActionEnabled('pan') )
            return;

        distance = convertCoordsToWindow(distance.x, distance.y);

        var W = this.getView();
        var U = camera.up.clone().cross(W);
        var V = W.clone().cross(U);

        U.normalize();
        V.normalize();
        W.normalize();

        var Pscreen = this.pivot.clone().sub(camera.position);
        var screenW = W.clone().dot(Pscreen);
        var screenU = screenW * (Math.tan( THREE.Math.degToRad(camera.leftFov)) + Math.tan(THREE.Math.degToRad(camera.rightFov)));
        var screenV = screenW * (Math.tan( THREE.Math.degToRad(camera.topFov)) + Math.tan(THREE.Math.degToRad(camera.bottomFov)));

        var offsetU = distance.x * Math.abs(screenU);
        var offsetV = distance.y * Math.abs(screenV);

        var offset = new THREE.Vector3();
        var u = U.clone().multiplyScalar(offsetU);
        var v = V.clone().multiplyScalar(offsetV);

        offset = (u.clone().add(v)).clone().multiplyScalar(this.userPanSpeed);

        camera.position.add(offset);
        this.center.add(offset);

        camera.lookAt(this.center);
        changed(false);
    };

    this.zoom = function(zoomDelta){
        if( !this.navApi.isActionEnabled('zoom') )
            return;

        //TODO: bug - when pivot is set outside the object, object zooms past the pivot point
        var zoomMin = 0.05;
        var zoomBase = this.userZoomSpeed;
        var distMax = Number.MAX_VALUE;
        var deltaXY = zoomDelta.x + zoomDelta.y;
        var dist = Math.pow ( zoomBase, deltaXY);

        var zoomPosition = (this.pivot.clone().sub((this.pivot.clone().sub(this.saveEye).clone()).multiplyScalar(dist)));
        var zoomCenter = zoomPosition.clone().add(cam.D.clone().multiplyScalar(cam.D.clone().dot((this.pivot.clone().sub(zoomPosition)).clone())));

        if (dist >= distMax)
            return;

        if (deltaXY > 0.0){
            var snapSize = 0;
            var dist2 = Math.pow(zoomBase, deltaXY - snapSize);

            // PERSP zoom out
            if ( deltaXY < snapSize ){
                // inside the zoomout speedbump region
                unitAmount = 0.0;
                return;

            } else {
                camera.position.copy(zoomPosition);
                this.center.copy(zoomCenter);

                var EprojD = (zoomPosition.clone().sub(this.saveEye)).dot(cam.D);

                if ( EprojD > distMax ) {
                    camera.position.copy((this.saveEye.sub(cam.D)).clone().multiplyScalar(distMax));
                    unitAmount = (distMax > 0.0) ? -1.0 : 0.0;
                } else {
                    unitAmount = -(EprojD / distMax);
                }
            }
        } else {


            camera.position.copy(zoomPosition);
            this.center.copy(zoomCenter);

            //Zoom In
            /*if ( dist < zoomMin) {
                //exponential zoom moved in as far as it can
                var zoomMinLinear = ( Math.log(zoomMin) / Math.log(zoomBase) );
                var distLinearXY = Math.abs(deltaXY) - Math.abs(zoomMinLinear);
                var snapSize = 0;

                // do linear zoomin
                if ( distLinearXY > snapSize ) {

                    var distLinearXY = distLinearXY - snapSize/window.innerHeight;
                    var amount = -distLinearXY;

                    var multiplier = this.userZoomSpeed;
                    var dist2 = amount * multiplier;

                    var Esnap = this.pivot.clone().sub((this.pivot.clone().sub(this.saveEye)).clone().multiplyScalar(zoomMin));
                    var E = Esnap.clone().sub((this.pivot.clone().sub(this.saveEye)).clone().multiplyScalar(dist2));

                    this.center.copy(E.clone().add(cam.D.clone().multiplyScalar(zoomMin)));
                    camera.position.copy(E);
                }
            } else {
                cam.D = (this.saveCenter.clone().sub(this.saveEye)).clone().normalize();
                camera.position.copy(zoomPosition);
                this.center.copy(zoomCenter);
            }*/
        }
        camera.lookAt(this.center);
        changed(false);
    };

    this.walk = function(currentCursor, startCursor, movementX, movementY, deltaTime){
        if( !this.navApi.isActionEnabled('walk') )
            return;

        var worldUp = this.sceneUpDirection.clone();
        var worldFront = this.sceneFrontDirection.clone();
        var worldRight = this.sceneFrontDirection.clone().cross( this.sceneUpDirection);
        //TODO: figure out what deltaTime does

        var flyPlanarMotion = true;
        var flyUpDownSensitivity = 0.01;

        if (isInDeadZone(currentCursor, startCursor)){
            wheel.cursorImage('SWWalk');
            setBias(true, currentCursor, startCursor);
            x = startCursor.x;
            y = startCursor.y;
        }else{
            setBias(false, currentCursor, startCursor);
        }

        //x = currentCursor.x - m_resetBiasX;
        //y = currentCursor.y - m_resetBiasY;
        x = currentCursor.x;
        y = currentCursor.y;

        var delta = convertCoordsToWindow( x - startCursor.x, y - startCursor.y );

        var fInitialMoveX = -delta.x;
        var fInitialMoveY = -delta.y;
        var fSignX = (fInitialMoveX < 0.0) ? -1.0 : 1.0;
        var fSignY = (fInitialMoveY < 0.0) ? -1.0 : 1.0;
        var fMoveX = Math.abs(fInitialMoveX);
        var fMoveY = Math.abs(fInitialMoveY);

        var deadzoneRadius = new THREE.Vector2(30, 30);
        deadzoneRadius = convertCoordsToWindow (deadzoneRadius.x, deadzoneRadius.y);

        fMoveX = ( isInDeadZone(currentCursor, startCursor)) ? 0.0 : Math.abs(fInitialMoveX) - deadzoneRadius.x;
        fMoveY = ( isInDeadZone(currentCursor, startCursor)) ? 0.0 : Math.abs(fInitialMoveY) - deadzoneRadius.y;

        var rampRadius = 0.25;
        fMoveX /= rampRadius;
        fMoveY /= rampRadius;

        fMoveX = ( fMoveX < 1.0 ) ? Math.easeClamp( fMoveX, 0.0, 1.0 ) : Math.pow(fMoveX, 1.0);
        fMoveY = ( fMoveY < 1.0 ) ? Math.easeClamp( fMoveY, 0.0, 1.0 ) : Math.pow(fMoveY, 1.0);


        // scale by time
        //fMoveX *= deltaTime;
        //fMoveY *= deltaTime;

        var fDeltaX = (fMoveX > 0.0) ? fMoveX * fSignX : 0.0;
        var fDeltaY = (fMoveY > 0.0) ? fMoveY * fSignY : 0.0;

        var vViewDir = this.getView();
        var fViewDist = vViewDir.length();
        vViewDir.normalize();

        var vRightDir = vViewDir.clone().cross( camera.up );
        vRightDir.normalize();

        // project vViewDir onto plane perpendicular to up direction to get
        // better walking inside houses, etc
        // (but prevents flying down to model from 3/4 view...)

        var vYViewDirRight = worldRight.clone().multiplyScalar(worldRight.clone().dot(vViewDir));
        var vYviewDirFront = worldFront.clone().multiplyScalar(worldFront.clone().dot(vViewDir));
        var vYViewDir = vYviewDirFront.clone().add(vYViewDirRight);

        vYViewDir = (vYViewDir.clone().length() > Number.MIN_VALUE) ? vYViewDir.normalize() : camera.up;

        var scale = 1.0;
        var fDollyDist = fDeltaY * (this.walkMultiplier * scale );

        var dir = flyPlanarMotion ? vYViewDir : vViewDir;


        // Free-flying or constrained walk?
        if (flyPlanarMotion) {
            // Constrained Walk
            // To avoid perceptually confusing motion, force a reversal of flying direction along a shifted axis

           // Angle to offset threshold from up-axis
           // TODO: make cos(0.65) into an AutoCam Parameter
           var dDirThreshold = Math.cos(0.65);

           if ((dDirThreshold != 1) &&
               (((worldUp.clone().dot(camera.up) < -Number.MIN_VALUE) && (worldUp.clone().dot(vViewDir) < -dDirThreshold)) ||
                   ((worldUp.clone().dot(camera.up) > Number.MIN_VALUE) && (worldUp.clone().dot(vViewDir) > dDirThreshold)))) {
               dir = -dir;
           }
        }


        var fSpinAngle = -fDeltaX *this.walkMultiplier*0.05;

        // rotate around world-up vector instead of CameraOperations up vector (more like head movement!)
        //Quaternion quat( m_cameraParams.up, (float)fSpinAngle );

        // Define rotation axis direction
        var vRotAxis = camera.up;

        // Free-flying or constrained walk?
        if (flyPlanarMotion) {
            // Constrained Walk
            // Need to check if:
            //  1. camera is "upside-down" (angle between world up and camera up is obtuse) or
            //  2. camera is in top view (camera up perpendicular to world up and view angle acute to world up)
            // These cases require a reversed rotation direction to maintain consistent mapping of tool:
            //  left->clockwise, right->counter-clockwise
            if ((worldUp.clone().dot(camera.up) < -Number.MIN_VALUE) ||
                ((Math.abs(worldUp.clone().dot(camera.up)) < Number.MIN_VALUE)
                    && (worldUp.clone().dot(vViewDir) > Number.MIN_VALUE))) {
                fSpinAngle = -fSpinAngle;
            }
            vRotAxis = worldUp;
        }

        // Define rotation transformation

        var quat = new THREE.Quaternion().setFromAxisAngle( vRotAxis, fSpinAngle );
        quat.normalize();

        vViewDir = quat.clone().rotate( vViewDir );
        vViewDir.normalize();
        camera.up.copy(quat.clone().rotate( camera.up ));
        camera.up.normalize();

        camera.position.add(dir.clone().multiplyScalar(fDollyDist));
        this.center.copy(camera.position.clone().add(vViewDir.clone().multiplyScalar(fViewDist)));

        dir = flyPlanarMotion ? worldUp : camera.up;
        dir.normalize();

        if(fDollyDist === 0)
            fDollyDist = flyUpDownSensitivity;

        camera.lookAt(this.center);
        changed(false);
    };

    this.updown = function(movementY){
        if( this.navApi.getIsLocked() )
            return;

        var deltaCursor = movementY;
        var deltaAmount = getMoveAmountFromCursorOffset(deltaCursor);

        cam.m_amount += deltaAmount;

        var upDir = new THREE.Vector3(0,1,0);

        var eye = cam.saveEye.clone().sub(upDir.clone().multiplyScalar(cam.m_amount));
        var eyeHeight = eye.clone().dot(upDir);

        camera.position.copy(eye);

        if ( eyeHeight < cam.minSceneBound ) {
            camera.position.add(upDir.clone().multiplyScalar(cam.minSceneBound - eyeHeight));
        }

        if ( eyeHeight > cam.maxSceneBound ) {
            camera.position.add(upDir.clone().multiplyScalar(cam.maxSceneBound - eyeHeight));
        }

        this.center.copy(camera.position.clone().add(cam.saveCenter.clone().sub(cam.saveEye)));
        camera.lookAt(this.center);
        changed(false);
    };


    /*      REWIND FUNCTIONS */

    /**
     * This takes a snapshot of the current camera passed into Autocam and saves it to the history. A screenshot
     * is taken of the sceneContainer canvas
     */
    this.addHistoryElement = function(){

        // --- We don't require history being saved ---

        // if (cam.rewindParams.maxHistorySize > 0 && cam.rewindParams.history.length >= cam.rewindParams.maxHistorySize){
        //     this.rewindParams.history.shift();
        // }

        // //reset previous 1 or 2 weights to 0
        // if (cam.rewindParams.history.length == 1){
        //     cam.rewindParams.history[0].weight = 0.0;
        // }else if (cam.rewindParams.history.length > 1){
        //     cam.rewindParams.history[cam.rewindParams.history.length -1].weight = 0.0;
        //     cam.rewindParams.history[cam.rewindParams.history.length -2].weight = 0.0;
        // }

        // var element = {};
        // element.thumbnail = document.getElementById("sceneContainer").toDataURL("image/png");
        // element.thumbnailBounds = new THREE.Box2(new THREE.Vector2(0,0),new THREE.Vector2(56,56));
        // element.camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 1, 10000 );
        // element.camera.position = camera.position.clone();
        // element.camera.up = camera.up.clone();
        // element.camera.rotation = camera.rotation.clone();
        // element.camera.leftFov = camera.leftFov;
        // element.camera.rightFov = camera.rightFov;
        // element.camera.topFov = camera.topFov;
        // element.camera.bottomFov = camera.bottomFov;
        // element.camera.center = cam.center.clone();
        // element.camera.pivot = cam.pivot.clone();
        // element.weight = 1.0;
        // element.isEmptyScene = false;

        // //IF SCENE OUTSIDE VIEW SET ISEMPTYSCENE TO TRUE

        // cam.rewindParams.history.push(element);
        // cam.rewindParams.snapped = true;
        // cam.rewindParams.slideOffset.x=0;
        // cam.rewindParams.timelineIndex = cam.rewindParams.history.length - 1;
        // cam.rewindParams.timelineIndexSlide = cam.rewindParams.timelineIndex;
    }

    /**
     * This handles any case where the user rewinds and then does any transformations, the history is sliced depending
     * on where the user rewinds to
     */
    this.addIntermediateHistoryElement = function(){

        if (this.rewindParams.snapped){
            this.rewindParams.history = this.rewindParams.history.slice(0,this.rewindParams.timelineIndex);
        }else{
            if (this.rewindParams.slideOffset.x > 0){
                this.rewindParams.history = this.rewindParams.history.slice(0,this.rewindParams.timelineIndex);
            } else{
                this.rewindParams.history = this.rewindParams.history.slice(0,this.rewindParams.timelineIndex+1);
            }
        }
        this.addHistoryElement();
    };

    this.clearHistory = function(){
        this.rewindParams.history.length = 0;
        this.rewindParams.timelineIndex = 0;
        this.rewindParams.timelineIndexSlide = 0;
        this.rewindParams.resetWeights = true;
    };

    this.openTimeline = function (location){
        this.rewindParams.timelineIndexSlide = this.rewindParams.timelineIndex;

        if (this.rewindParams.resetWeights){
            this.rewindParams.slideOffset.x = 0;
            this.rewindParams.snapped = this.rewindParams.snappingEnabled;
        }

        //if haven't applied any transformations before clicking rewind
        if (this.rewindParams.history.length === 0) this.addHistoryElement();

        for ( var i = 0; i < this.rewindParams.history.length; i++ )
        {
            var index = i - this.rewindParams.timelineIndex;
            var size = this.rewindParams.thumbnailGapSize + this.rewindParams.thumbnailSize;

            this.rewindParams.history[i].thumbnailBounds.setCenter(new THREE.Vector2(location.x + index * size, location.y).add(this.rewindParams.slideOffset));

            if (this.rewindParams.resetWeights)
            {
                this.rewindParams.history[i].weight = (i==this.rewindParams.timelineIndex)? 1.0:0.0;
            }
        }

        if ( this.rewindParams.resetWeights )
        {
            this.rewindParams.resetWeights = false;
        }

        var size = (this.rewindParams.thumbnailGapSize + this.rewindParams.thumbnailSize) *2.0;
        this.rewindParams.open = true;
        this.rewindParams.openLocation = location.clone();
        this.rewindParams.openLocationOrigin = location.clone();
        this.rewindParams.openBracket = location.clone();
        this.rewindParams.openBracketA = new THREE.Vector2(size, location.y);
        this.rewindParams.openBracketB = new THREE.Vector2(window.innerWidth - size, location.y);
        // make sure dead-zone is well formed ... i.e. A.x < B.x
        if (this.rewindParams.openBracketA.x > this.rewindParams.openBracketB.x) {
            var swap = this.rewindParams.openBracketA.x;
            this.rewindParams.openBracketA.x = this.rewindParams.openBracketB.x;
            this.rewindParams.openBracketB.x = swap;
        }
        this.rewindParams.locationOffset = new THREE.Vector2(0,0);
        this.rewindParams.snapOffset = new THREE.Vector2(0,0);
    };

    this.slideTimeline = function(location_){
        /*
         Basic Idea:
         Behaviour of the current rewind timeline is similar to a tracking menu. There is a "deadzone"
         region where cursor movement does not slide the thumbnails. As the cursor goes outside the
         region, thumbnails slide to align the closest edge of the timeline to the cursor ('extent'
         variable is this sliding amount). The edges of the deadzone region are stored in
         'm_openBracketA/B' variables, and slide around with the timeline. Draw some icons at bracket
         positions to visualize the process.
         */

        if ( !this.rewindParams.open || this.rewindParams.history.length === 0  ) { return; }

        var location =  location_.clone().add(this.rewindParams.locationOffset);

        var size = (this.rewindParams.thumbnailGapSize + this.rewindParams.thumbnailSize) * 2.0;
        var bracketA = size;
        var bracketB = window.innerWidth - size;

        var edgeA = this.rewindParams.history[0].thumbnailBounds.center().x;
        var edgeB = this.rewindParams.history[this.rewindParams.history.length - 1].thumbnailBounds.center().x;

        var extent = 0.0;

        if ( location.x < this.rewindParams.openBracketA.x )
        {
            extent = location.x - this.rewindParams.openBracketA.x;

            // don't slide thumbnails past the edge of the timeline
            var edgeAnew = edgeA - extent;

            if ( bracketA < edgeAnew )
            {
                // only want to limit the influence of extent, not overshoot the other way
                extent = Math.min( extent + (edgeAnew - bracketA), 0.0);
            }
        }
        if ( location.x > this.rewindParams.openBracketB.x )
        {
            extent = location.x - this.rewindParams.openBracketB.x;

            // don't slide thumbnails past the edge of the timeline
            var edgeBnew = edgeB - extent;

            if ( bracketB > edgeBnew )
            {
                // only want to limit the influence of extent, not overshoot the other way
                extent = Math.max( extent + (edgeBnew - bracketB), 0.0);
            }
        }

        this.rewindParams.openLocation.x += extent;
        this.rewindParams.openBracketA.x += extent;
        this.rewindParams.openBracketB.x += extent;

        this.rewindParams.openBracket.x = location.x - ( this.rewindParams.openLocation.x - this.rewindParams.openLocationOrigin.x );

        var iconOffset = new THREE.Vector2( -extent, 0.0 );

        var L = location.clone().sub( this.rewindParams.openLocation.clone().sub(this.rewindParams.openLocationOrigin));

        // snapping

        iconOffset.x += this.rewindParams.snapOffset.x;
        this.rewindParams.snapOffset.x = 0.0;

        var snapped = false;

        if ( this.rewindParams.snappingEnabled )
        {
            var kEnterSnapDistance = 4.0;
            var kLeaveSnapDistance = 16.0;

            for ( var i = 0; i < this.rewindParams.history.length; i++ )
            {
                var P = this.rewindParams.history[i].thumbnailBounds.center().add(iconOffset);
                if ( Math.abs(P.x - L.x) < kEnterSnapDistance || (this.rewindParams.snapped && Math.abs(P.x - L.x) < kLeaveSnapDistance) )
                {
                    snapped = true;
                    if (extent !== 0.0)
                    {
                        this.rewindParams.snapOffset.x = P.x - L.x;
                        iconOffset.x -= this.rewindParams.snapOffset.x;
                    }
                    else
                    {
                        this.rewindParams.openBracket.x += P.x - L.x;
                    }
                    L.x = P.x;
                    break;
                }
            }
        }

        this.rewindParams.snapped = snapped;

        var weightMax = -1.0;
        var weightTotal = 0.0;
        for ( var j = 0; j < this.rewindParams.history.length; j++ )
        {
            var tempBox = this.rewindParams.history[j].thumbnailBounds.clone();

            // slide the thumbnails
            this.rewindParams.history[j].thumbnailBounds.setCenter(this.rewindParams.history[j].thumbnailBounds.center().add(iconOffset));

            if (this.rewindParams.history[j].thumbnail )
            {
                var leftEdge = this.rewindParams.history[j].thumbnailBounds.center().x - this.rewindParams.thumbnailSize/2.0;
                $('#rewindFrame' + j).css('left', leftEdge);
                $('#rewindBorder' + j).css('left', (leftEdge-4));
            }

            // grow the copied Icon2D to touch the center of its neighbor
            //think about adding offset for frames here
            var newSize = new THREE.Vector2((this.rewindParams.thumbnailGapSize + this.rewindParams.thumbnailSize) * 2.0, (this.rewindParams.thumbnailGapSize + this.rewindParams.thumbnailSize) * 2.0 );
            tempBox.setFromCenterAndSize(tempBox.center(),newSize);

            var Icon2DCoords = new THREE.Vector2(0,0);
            tempBox.getIcon2DCoords(L,Icon2DCoords);

            var weight = 1.0 - Math.abs( Math.equalityClamp(Icon2DCoords.x, -1.0, 1.0 ));
            this.rewindParams.history[j].weight = weight;

            // check for out-of-range cases
            if ( j === 0 && L.x < tempBox.center().x)
            { this.rewindParams.history[j].weight = 1.0; }

            if ( j === this.rewindParams.history.length - 1 && L.x > tempBox.center().x)
            { this.rewindParams.history[j].weight = 1.0; }

            weightTotal = weightTotal + this.rewindParams.history[j].weight;

            // find dominant thumbnail
            if ( this.rewindParams.history[j].weight > weightMax )
            {
                weightMax = this.rewindParams.history[j].weight;
                if ( this.rewindParams.snappingEnabled && this.rewindParams.history[j].weight == 1.0 ){
                    // snap to this element
                    this.rewindParams.slideOffset.x = 0;
                    this.rewindParams.snapped = true;
                }else{
                    this.rewindParams.slideOffset.x = this.rewindParams.history[j].thumbnailBounds.center().x - L.x;
                }
                this.rewindParams.timelineIndexSlide = j;
            }
        }

        // normalize the weights just in case
        for ( var k = 0; k < this.rewindParams.history.length; k++ )
        {
            this.rewindParams.history[k].weight = this.rewindParams.history[k].weight / weightTotal;
        }

        // prevent the bracket from moving off the ends of the timeline
        var xBracketMin = this.rewindParams.history[0].thumbnailBounds.center().x;
        var xBracketMax = this.rewindParams.history[this.rewindParams.history.length - 1].thumbnailBounds.center().x;
        if ( this.rewindParams.openBracket.x < xBracketMin )
        {
            this.rewindParams.locationOffset.x += xBracketMin - this.rewindParams.openBracket.x;
            this.rewindParams.openBracket.x = xBracketMin;
        }
        else if ( this.rewindParams.openBracket.x > xBracketMax )
        {
            this.rewindParams.locationOffset.x += xBracketMax - this.rewindParams.openBracket.x;
            this.rewindParams.openBracket.x = xBracketMax;
        }
    };

    this.shiftBackOneElement = function(){
        if ( this.rewindParams.history.length !== 0 && (this.rewindParams.timelineIndex > 0 || this.rewindParams.slideOffset.x !== 0)){
            if (this.rewindParams.snapped || this.rewindParams.slideOffset.x > 0){
                this.rewindParams.timelineIndex--;
            }
            this.rewindParams.timelineIndexSlide = this.rewindParams.timelineIndex;
            this.rewindParams.resetWeights = true;
            cam.elapsedTime = 0;
            this.animateToRewindIndex();
        }
    };

    this.animateToRewindIndex = function ()    {
        var currentTimelineIndex = this.rewindParams.timelineIndex;
        var unitTime = 0.0;
        if ( cam.elapsedTime >= shotParams.duration ){
            unitTime = 1.0;
        } else {
            var tMax = shotParams.destinationPercent;
            unitTime =  Math.easeClamp( cam.elapsedTime / shotParams.duration, 0.0, tMax );
            cam.elapsedTime += deltaTime/500;
        }

        cam.center.copy((cam.center.clone().multiplyScalar( 1.0 - unitTime)).clone().add( this.rewindParams.history[currentTimelineIndex].camera.center.clone().multiplyScalar( unitTime )));
        camera.position.copy((camera.position.clone().multiplyScalar( 1.0 - unitTime)).clone().add(this.rewindParams.history[currentTimelineIndex].camera.position.clone().multiplyScalar( unitTime )));
        camera.up.copy(this.rewindParams.history[currentTimelineIndex].camera.up);
        cam.pivot.copy(cam.center);

        camera.lookAt(cam.center);
        changed(false);

        if( this.cube )
            requestAnimationFrame(this.cube.render);

        if( unitTime !== 1.0 )
            requestAnimationFrame(function() { cam.animateToRewindIndex();});
    };

    this.closeTimeline = function(){
        if (this.rewindParams.timelineIndex != this.rewindParams.timelineIndexSlide){
            this.rewindParams.timelineIndex = this.rewindParams.timelineIndexSlide;
        }
        this.rewindParams.open = false;
    };

    this.getInterpolatedCamera = function (){
        var interpolatedCam = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 1, 10000 );
        interpolatedCam.center = new THREE.Vector3(0,0,0);
        interpolatedCam.pivot = new THREE.Vector3(0,0,0);
        interpolatedCam.leftFov = 0;
        interpolatedCam.rightFov = 0;
        interpolatedCam.topFov = 0;
        interpolatedCam.bottomFov = 0;
        interpolatedCam.up.set(0,0,0);

        for (var i = 0; i < this.rewindParams.history.length; i++){
            var frameCam = this.rewindParams.history[i].camera;
            var wi = this.rewindParams.history[i].weight;

            interpolatedCam.center.add(frameCam.center.clone().multiplyScalar(wi));
            interpolatedCam.position.add(frameCam.position.clone().multiplyScalar(wi));
            interpolatedCam.up.add(frameCam.up.clone().multiplyScalar(wi));
            interpolatedCam.rotation.add(frameCam.rotation.clone().multiplyScalar(wi));
            interpolatedCam.pivot.add(frameCam.pivot.clone().multiplyScalar(wi));
            interpolatedCam.leftFov+=(frameCam.leftFov*wi);
            interpolatedCam.rightFov +=(frameCam.rightFov*wi);
            interpolatedCam.topFov+=(frameCam.topFov*wi);
            interpolatedCam.bottomFov+=(frameCam.bottomFov*wi);
        }

        camera.position.copy(interpolatedCam.position);
        camera.up.copy(interpolatedCam.up);
        camera.rotation = interpolatedCam.rotation;
        camera.leftFov = interpolatedCam.leftFov;
        camera.rightFov = interpolatedCam.rightFov;
        camera.topFov = interpolatedCam.topFov;
        camera.bottomFov = interpolatedCam.bottomFov;
        cam.center.copy(interpolatedCam.center);
        cam.pivot.copy(interpolatedCam.pivot);
        camera.lookAt(cam.center);
        camera.up.normalize();
        changed(false);
    };

};
