
import { theExtensionManager } from "./ExtensionManager";
import { LogLevels } from "../logger/Logger";

/**
 * Returns a new object that can be passed to a Viewer instance when created.
 *
 * Available since version 2.13
 *
 * @returns {ViewerConfig}
 *          Can be passed into a ViewingApplication's registerViewer() 3rd parameter,
 *          or directly when constructing a Viewer instance.
 *
 * @example
 * var myConfig = Autodesk.Viewing.createViewerConfig();
 * myConfig.extensions.push('MyAwesomeExtension');
 * //
 * // Direct usage...
 * var myViewer = new Autodesk.Viewing.Viewer3D( myDiv, myConfig );
 * //
 * // ...or through a ViewingApplication
 * viewerApp = new Autodesk.Viewing.ViewingApplication('MyViewerDiv');
 * viewerApp.registerViewer(viewerApp.k3D, Autodesk.Viewing.Private.GuiViewer3D, myConfig);
 *
 *
 * @memberof Autodesk.Viewing
 * @alias Autodesk.Viewing.createViewerConfig
 */
export function createViewerConfig() {

    // Global Viewer configuration values
    var config = {

        extensions: [],
        // useConsolidation: false, // 100 MB -- Needs work before exposing (here or elsewhere)
        // consolidationMemoryLimit: 100 * 1024 * 1024, // 100 MB -- Needs work before exposing (here or elsewhere)
        sharedPropertyDbPath: undefined,
        // bubbleNode: undefined, -- Needs work before exposing here.
        canvasConfig: undefined, // TODO: Needs documentation or something.
        startOnInitialize: true,

        // Enables experimental, non-supported features
        experimental: []
    };

    // Ask each extension to register their default options
    theExtensionManager.popuplateOptions(config);

    if (!config.hasOwnProperty('disabledExtensions'))
        config.disabledExtensions = {};

    // Supported features that can be disabled when using GuiViewer3D.
    [
        'measure',
        'hyperlink',
        'section',
    ].forEach(function(extProp){
        if (!config.disabledExtensions.hasOwnProperty(extProp))
            config.disabledExtensions[extProp] = false;
    });

    return config;
}

/**
 * Returns a new object that can be passed to Autodesk.Viewing.Initializer() for
 * initialization. Developers should consider customizing attributes in this object
 * before passing it to Autodesk.Viewing.Initializer().
 *
 * Available since version 2.13
 *
 * @returns {InitOptions} Can be passed into Autodesk.Viewing.Initializer()
 *
 * @example
 *  var options = Autodesk.Viewing.createInitializerOptions();
 *  options.getAccessToken = function(onSuccess) {
 *      var accessToken, expire;
 *      // Code to retrieve and assign token value to
 *      // accessToken and expire time in seconds.
 *      onSuccess(accessToken, expire);
 *  };
 *  Autodesk.Viewing.Initializer(options, function() {
 *      alert("initialization complete");
 *  });
 *
 * @memberof Autodesk.Viewing
 * @alias Autodesk.Viewing.createInitializerOptions
 */
export function createInitializerOptions() {

    // Attributes fully supported
    // See @typedef {Object} InitOptions above for details on each one of these...
    var opts = {
        env: 'AutodeskProduction',
        // TODO: Use commented api when we deprecate useDerivativeServiceV2 flag and old endpoints.js.
        api: undefined /*av.endpoint.ENDPOINT_API_DERIVATIVE_SERVICE_V2*/,
        getAccessToken: undefined,
        language: undefined,
        logLevel: LogLevels.ERROR,
        webGLHelpLink: null
    };

    // Attributes that exist, but we don't fully support (yet)
    // opts.offline: false,
    // opts.offlineResourcePrefix: 'data'

    return opts;
}
