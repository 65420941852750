//Simple shader for occlusion testing

import * as THREE from "three";
import occlusion_vert from './shaders/occlusion_vert.glsl';
import occlusion_frag from './shaders/occlusion_frag.glsl';

export let OcclusionShader = {

    uniforms: {
        "color": { type: "v3", value: new THREE.Vector3(0, 0, 0) },
        "opacity": { type: "f", value: 1.0 }
    },

    rawShader: true,
    
    vertexShader: occlusion_vert,
    fragmentShader: occlusion_frag
};
