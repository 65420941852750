

export let TOOLBAR_CREATED_EVENT          = 'toolbarCreated';
export let VIEW_CUBE_CREATED_EVENT        = 'viewCubeCreated';

/**
 * Viewer tools sets.
 *
 * These constants are used to define the standard set of tools.
 *
 * @enum {string}
 * @readonly
 * @memberof Autodesk.Viewing
 */
export let TOOLBAR = {
    NAVTOOLSID:      "navTools",
    MODELTOOLSID:    "modelTools",
    SETTINGSTOOLSID: "settingsTools",
    MEASURETOOLSID:      "measureTools",
};
