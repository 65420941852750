var NODE_FLAG_OFF        = 0x1000000,
    NODE_FLAG_HIDE       = 0x2000000,
    FRAG_COUNT_MASK      = 0x0ffffff;
var ROOT_NODE_ID         = -1 << 30;

/**
 * Map dbids to fragment dbids
 * 
 * When there isn't an instance tree this object can be used to get the fragment ids for
 * database ids.
 * 
 * @param {ArrayLike} fragToDbId - fragToDbId[i] is the dbid or an array of dbids for fragment with id i 
 */
export function DbidFragmentMap(fragToDbId) {
    this.numOffs = 0;
    this.numHidden = 0;

    // Build the map from dbid to fragids.
    // _dbidToIndex maps dbids to an index in _dbidFrags.
    var _dbidToIndex = {};
    // _dbidFrags contains the fragment ids for the all of the dbids. The index from _dbidToIndex
    // points to an integer that contains flags and a fragment id count for the dbid. The fragment
    // ids follow that.
    var _dbidFrags;
    function buildMap(fragToDbId) {
        var fragArrayCount = 0; // Count of elements in _dbidFrags;
        var fragCounts = [];    // Fragment id count for dbids
        var dbidArray = [0];    // Array to use for single fragment dbids

        function forEach(callback) {
            for (var i = 0, iEnd=fragToDbId.length; i<iEnd; i++) {

                var dbIds = fragToDbId[i];

                //In 2D drawings, a single fragment (consolidation mesh)
                //can contain multiple objects with different dbIds.
                if (!Array.isArray(dbIds)) {
                    dbidArray[0] = dbIds;
                    dbIds = dbidArray;
                }

                for (var j=0; j<dbIds.length; j++) {
                    var dbId = dbIds[j];
                    callback(i, dbId);
                }
            }
        }

        // First pass, count the fragment ids per dbid.
        forEach(function(fragId, dbId) {
            var index = _dbidToIndex[dbId];
            if (index === undefined) {
                //If it's the first fragments for this dbid,
                //store the index directly -- most common case.
                _dbidToIndex[dbId] = index = fragCounts.length;
                fragCounts.push(0);     // Push count
                fragCounts.push(0);     // Push index
            }
            // Count fragments for dbid
            ++fragCounts[index];
            ++fragArrayCount;       // Total count of fragments
        });

        // Allocate the array for the fragment ids
        _dbidFrags = new Uint32Array(fragArrayCount + fragCounts.length / 2);
        _dbidFrags.fill(0);

        // Now calculate the index for each dbid
        var dbId, index, curIndex = 0;
        for (dbId in _dbidToIndex) {
            index = _dbidToIndex[dbId];
            var count = fragCounts[index];
            var nextIndex = curIndex + count + 1;
            _dbidFrags[curIndex] = count;
            fragCounts[index] = fragCounts[index + 1] = curIndex;
            curIndex = nextIndex;            
        }

        // Put the fragment ids in the fragment id array
        var logged = false;
        forEach(function(fragId, dbId) {
            var index = _dbidToIndex[dbId];
            var fragIndex = fragCounts[index + 1] + 1;
            if (fragIndex > _dbidFrags.length || _dbidFrags[fragIndex]) {
                if (!logged) {
                    console.error("DbidFragmentMap.buildMap: Internal error fragment overlap");
                    logged = true;
                }
            } else {
                _dbidFrags[fragIndex] = fragId;
                fragCounts[index + 1] = fragIndex;
            }
        });

        // Make sure we filled each dbids fragments
        logged = false;
        for (dbId in _dbidToIndex) {
            index = _dbidToIndex[dbId];
            var fragIndex = fragCounts[index + 1] + 1;
            if (fragIndex < _dbidFrags.length && !_dbidFrags[fragIndex]) {
                if (!logged) {
                    console.error("DbidFragmentMap.buildMap: Internal error fragment underfilled");
                    logged = true;
                }
            }
        }

        // Finally put the indices into _dbidToIndex
        for (dbId in _dbidToIndex) {
            index = _dbidToIndex[dbId];
            _dbidToIndex[dbId] = fragCounts[index];
        }
    }
    buildMap(fragToDbId);

    this.setFlagNode = function(dbId, flag, value) {

        if (!_dbidToIndex.hasOwnProperty(dbId))
            return false;

        var index = _dbidToIndex[dbId];
        var old = _dbidFrags[index];

        // "!!" converts to bool
        if (!!(old & flag) == value)
            return false;

        if (value)
            _dbidFrags[index] |= flag;
        else
            _dbidFrags[index] &= ~flag;

        return true;
    };

    /**
     * When a node is OFF, it is completely skipped for display purposes
     */
    this.setNodeOff = function(dbId, value) {
        var res = this.setFlagNode(dbId, NODE_FLAG_OFF, value);
        if (res) {
            if (value)
                this.numOff++;
            else
                this.numOff--;
        }
        return res;
    };

    this.isNodeOff = function(dbId) {
        var index = _dbidToIndex[dbId];
        return !!(_dbidFrags[index] & NODE_FLAG_OFF);
    };


    /**
     * When a node is HIDDEN it will display in ghosted style
     * if display of hidden objects is on
     */
    this.setNodeHidden = function(dbId, value) {
        var res = this.setFlagNode(dbId, NODE_FLAG_HIDE, value);
        if (res) {
            if (value)
                this.numHidden++;
            else
                this.numHidden--;
        }
        return res;
    };

    this.isNodeHidden = function(dbId) {
        var index = _dbidToIndex[dbId];
        return !!(_dbidFrags[index] & NODE_FLAG_HIDE);
    };

    /**
     * Provide an id to standin for the root node. This id is used
     * in enumNodeChildren to enumerate all nodes in the scene.
     */
    this.getRootId = function() {
        return ROOT_NODE_ID;
    };

    /**
     * Enumerate the fragment ids for a database id
     */
    this.enumNodeFragments = function(node, callback/*, recursive*/) {
        //TODO: Temporary until we are consistently using dbId
        var dbId;
        if (typeof node == "number")
            dbId = node;
        else if (node)
            dbId = node.dbId;

        var index = _dbidToIndex[dbId];
        var end = (_dbidFrags[index] & FRAG_COUNT_MASK) + index + 1;
        while( ++index < end)
            callback(_dbidFrags[index], dbId);
    };

    /**
     * Convenience function for code that uses the recursive
     * flag to convert database ids to fragment ids.
     */
    this.enumNodeChildren = function(node, callback, recursive) {
        if (recursive)
            callback(node);
        if (node == ROOT_NODE_ID) {
            for (var dbId in _dbidToIndex) {
                callback(Number(dbId));
            }
        }
    };

}

